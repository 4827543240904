<template>
  <fragment>
    <Title title="全区SOS呼叫情况"></Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout quanqusoshujiaoqingkuang">
        <div class="description">
          {{ description }}
        </div>
        <div class="list" v-if="dataSource.length">
          <ul>
            <li v-for="(item, index) in dataSource" :key="index">
              <div class="picture">
                <img :src="headpic" />
              </div>
              <div class="info">
                <p>{{ item.staffName }}</p>
              </div>
            </li>
            <li></li>
          </ul>
        </div>
        <div class="infomation">
          <ul>
            <li>呼叫总数<br />{{ data.allPickup }}</li>
            <li>接听率<br />{{ data.isPickupProportion }}</li>
            <li>使用设备长者数<br />{{ data.deviceNum }}</li>
          </ul>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import { imgUrl } from "@/utils/config.js";

export default {
  components: { Title },
  mixins: [ComponentBase],
  data() {
    return {
      imgUrl,
      description:
        "通过小壹助手等智能设备或APP，在紧急时、孤单时、有需要时，老人可按键呼叫，客服24小时在线等待呼叫。",
      headpic: "/images/worker.jpg",
      dataSource: [],
      data: {
        allPickup: 0,
        isPickupProportion: 0,
        deviceNum: 0,
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getCallNhView`,
        success: (response) => {
          this.data = {
            allPickup: response.data.data.allPickup || 0,
            isPickupProportion: response.data.data.isPickupProportion || "0%",
            deviceNum: response.data.data.deviceNum || 0,
          };
        },
      });
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getCallStaffList`,
        success: (response) => {
          this.dataSource =
            response && response.data && response.data.data ? response.data.data : [];
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.quanqusoshujiaoqingkuang {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .description {
    line-height: 0.4rem;
    font-size: 0.28rem;
    color: #fff;
    width: 100%;
    text-align: justify;
  }
  .list {
    width: 100%;
    // 暂时隐藏其他的
    overflow: hidden;
    ul {
      display: flex;
      width: 100%;
      height: 100%;
      li {
        max-height: 2rem;
        height: 100%;
        width: 49%;
        position: relative;
        background-color: rgb(17, 46, 118);
        border-radius: 0.12rem;
        padding: 0.1rem;
        flex-shrink: 0;
        margin-right: 1%;
        display: flex;
        justify-content: space-between;
        .picture {
          width: 49%;
          img {
            .setImg(contain);
          }
        }
        .info {
          width: 49%;
          p {
            font-size: 0.28rem;
            color: #fff;
            font-weight: 700;
          }
        }
      }
    }
  }
  .infomation {
    width: 100%;
    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      li {
        width: 1.87rem;
        height: 1.35rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(/images/background_content.png) no-repeat center;
        background-size: contain;
        font-size: 0.28rem;
        color: #fff;
        text-align: center;
        padding-top: 0.1rem;
      }
    }
  }
}
</style>
