<template>
  <div :class="className ? `ny-nav ${className}` : 'ny-nav'">
    <div class="ny-nav-options">
      <div
        :class="index == currentIndex ? `ny-nav-option active` : 'ny-nav-option'"
        v-for="(item, index) in dataArray"
        :key="index"
        @click.stop="navClicked(item, index)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
    },
    dataArray: {
      type: Array,
    },
    callBack: {
      type: Function,
    },
    defaultFirst: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      componentKey: [],
      currentIndex: 0,
      stv: null,
    };
  },
  methods: {
    navClicked(item, index) {
      this.currentIndex = index;
      // 清空定时器
      if (this.stv) {
        this.stv && window.clearInterval(this.stv);
        // 再开始
        this.autoChange();
      }
      // 回调函数
      this.callBack && this.callBack(item);
    },
    autoChange() {
      this.stv = window.setInterval(() => {
        if (this.currentIndex + 1 >= this.dataArray.length) {
          this.currentIndex = 0;
        } else {
          this.currentIndex++;
        }
        this.callBack && this.callBack(this.dataArray[this.currentIndex]);
      }, 10000);
    },
  },
  beforeMount() {
    if (this.defaultFirst && this.dataArray && this.dataArray[0]) {
      this.currentIndex = 0;
    }
  },
  mounted() {
    if (this.dataArray.length > 1) {
      this.autoChange();

      let componentKey = [];

      for (let index in this.dataArray) {
        componentKey[this.dataArray[index].key] = index;
      }

      this.componentKey = componentKey;
    }
  },
  beforeDestroy() {
    // 清空定时器
    this.stv && window.clearInterval(this.stv);
  },
  watch: {
    "$store.state.webSocketInstructions": {
      handler: function (instructions) {
        switch (instructions.k) {
          case "setComponent":
            if (this.componentKey[instructions.v] !== undefined) {
              this.navClicked({ key: instructions.v }, this.componentKey[instructions.v]);
            }
            break;
          default:
            break;
        }
      },
    },
  },
};
</script>

<style lang="less">
.ny-nav {
  .ny-nav-options {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ny-nav-option {
      font-size: @fontSizeSmall;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: @mainButtonBorderRadius;
      padding: 5px 26px;
      position: relative;
      margin: 0px 30px;
      cursor: pointer;
      user-select: none;
      border: 8px solid @mainButtonBorderColor;
    }
    .ny-nav-option.active {
      background: @mainGradientColor;
      border: none;
      padding: 13px 34px;
    }
  }
}
</style>
