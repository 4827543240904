<template>
  <div class="empty-data">{{ message || "暂无数据" }}</div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less">
.empty-data {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 0.18rem;
  color: rgb(255, 255, 255);
  padding: 0.1rem;
  border-radius: 0.1rem;
  background-color: rgba(255, 255, 255, 0.2);
}
</style>
