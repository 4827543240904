<template>
  <PictureIntroduction :picture_list="picture_list"></PictureIntroduction>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import PictureIntroduction from "@/components/common/PictureIntroduction.vue";
export default {
  mixins: [ComponentBase],
  components: {
    PictureIntroduction,
  },
  data() {
    return {
      picture_list: [],
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgIntroduce?id_org=${this.HomeId}`,
        success: (response) => {
          this.picture_list = response.data.data.pictureList || [];
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
// .components-layout {
//   .fuwushangtupian {
//     display: flex;
//     height: 100%;
//     width: 100%;
//     position: relative;
//     img {
//       .setImg(contain);
//     }
//   }
// }
</style>
