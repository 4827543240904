<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getOrderByMonth`,
        success: (response) => {
          let arr = [];
          for (let i = 1; i <= 12; i++) {
            arr.push([`${i}月`, 0]);
          }
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              if (!isNaN(item.month) && item.month > 0 && item.month < 13) {
                arr[item.month - 1][1] = item.allNum;
              }
            });
          // 更新配置
          this.option = {
            grid: { containLabel: true },
            // grid: { left: 150 },
            series: [
              {
                type: "line",
                symbol: "emptyCircle",
                ecode: { x: "月", y: "订单数" },
                itemStyle: { normal: {} },
              },
            ],
            title: { text: "" },
            tooltip: {},
            xAxis: { type: "category" },
            yAxis: {},
            dataset: {
              source: [
                ["月份", "订单数"],
                // ["1月", 10100],
                // ["2月", 9500],
                // ["3月", 10200],
                // ["4月", 0],
                // ["5月", 0],
                // ["6月", 0],
                // ["7月", 10100],
                // ["8月", 10200],
                // ["9月", 10100],
                // ["10月", 10200],
                // ["11月", 10300],
                // ["12月", 10400],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
