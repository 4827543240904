<template>
  <div class="components-layout pd0">
    <div class="FoShanOverview">
      <div class="section zhkypt">
        <p>智慧养老平台</p>
      </div>
      <!-- 居家 -->
      <div class="section md jjfws">
        <p>入库供应商</p>
        <p>{{ data.homeOrgNum }}家</p>
      </div>
      <div class="section fwzgds">
        <p>服务总工单数</p>
        <p>{{ data.homeRecordNum }}</p>
      </div>
      <div class="section fwzzzs-jj">
        <p>服务长者总数</p>
        <p>{{ data.homeElderNum }}人</p>
      </div>
      <!-- 居家 -->

      <!-- 社区 -->
      <div class="section md fwzzzs-sq">
        <p>服务长者总数</p>
        <p>{{ data.comElderTotalNum }}人</p>
      </div>
      <div class="section sqylcs">
        <p>社区养老场所</p>
        <p>{{ data.comOrgNum }}家</p>
      </div>
      <!-- 社区 -->

      <!-- 机构 -->
      <div class="section md yljg">
        <p>养老机构</p>
        <p>{{ data.orgNum }}家</p>
      </div>
      <div class="section rzzzzs">
        <p>入住长者总数</p>
        <p>{{ data.orgResidentNum }}人</p>
      </div>
      <div class="section xfplxtwcbl">
        <p>消防喷淋系统完成比例</p>
        <p>{{ data.orgIsBuildFireSpraySys }}</p>
      </div>
      <div class="section ygxtwcbl">
        <p>烟感系统完成比例</p>
        <p>{{ data.orgIsBuildFireSpraySys }}</p>
      </div>
      <div class="section yyjh">
        <p>医养结合</p>
        <p>{{ data.orgMedicalCar }}家</p>
      </div>
      <!-- 机构 -->
    </div>
  </div>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
export default {
  mixins: [ComponentBase],
  data() {
    return {
      data: {
        // 居家服务商
        homeOrgNum: 0,
        // 服务总工单数
        homeRecordNum: 0,
        // 服务长者人数（居家）
        homeElderNum: 0,
        // 服务长者人数（社区）
        comElderTotalNum: 0,
        // 社区养老场所
        comOrgNum: 0,
        // 养老机构
        orgNum: 0,
        // 入住长者总数
        orgResidentNum: 0,
        // 消防喷淋系统完成比例
        orgIsBuildFireSpraySys: "0%",
        // 烟感系统完成比例
        ygxtwcbl: "0%",
        // 医养结合
        orgMedicalCar: 0,
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: "/nh/getNhOverView",
        success: (response) => {
          this.data = {
            homeOrgNum: response.data.data.homeOrgNum || 0,
            homeRecordNum: response.data.data.homeRecordNum || 0,
            homeElderNum: response.data.data.homeElderNum || 0,
            zzjycyrs: 0,
            ljfwcyrs: 0,
            comElderTotalNum: response.data.data.comElderTotalNum || 0,
            comOrgNum: response.data.data.comOrgNum || 0,
            orgNum: response.data.data.orgNum || 0,
            orgResidentNum: response.data.data.orgResidentNum || 0,
            orgIsBuildFireSpraySys: response.data.data.orgIsBuildFireSpraySys || "0%",
            orgMedicalCar: response.data.data.orgMedicalCar || 0,
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.FoShanOverview {
  position: relative;
  width: 100%;
  padding-top: 44%;
  background: @mainBackGroundColor url(/images/fsszl.png) no-repeat center;
  background-size: contain;
  .section {
    color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20%;
    width: 10.35%;
    height: 23.4%;
    p {
      &:first-child {
        padding: 0 5%;
      }
    }
  }
  .section.md {
    width: 14.6%;
    height: 33.2%;
  }
  .zhkypt {
    left: 36.55%;
    top: 2.4%;
    width: 26.5%;
    height: 62%;
    font-size: 38%;
    p {
      &:first-child {
        padding: 0 20%;
      }
    }
  }
  .jjfws {
    left: 18.75%;
    top: 16.5%;
  }
  .fwzgds {
    left: 6.4%;
    top: 3.1%;
  }
  .fwzzzs-jj {
    left: 5.2%;
    top: 45.2%;
  }
  .fwzzzs-sq {
    left: 28.3%;
    top: 59.8%;
    p {
      &:first-child {
        padding: 0 20%;
      }
    }
  }
  .sqylcs {
    left: 46.05%;
    top: 76.6%;
  }
  .yljg {
    left: 66.75%;
    top: 26.8%;
  }
  .rzzzzs {
    left: 77.5%;
    top: 0;
  }
  .xfplxtwcbl {
    left: 84.45%;
    top: 34.8%;
    p {
      &:first-child {
        padding: 0;
      }
    }
  }
  .ygxtwcbl {
    left: 77.25%;
    top: 67.9%;
  }
  .yyjh {
    left: 61.5%;
    top: 72%;
  }
}
</style>
