<template>
  <fragment>
    <Title title="按工作年限分析" className="ny-title-small"></Title>
    <div class="layout-view withoutTitle pb0">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/home/getStaffAnalysisList`,
        param: {
          dimension: "work_years",
        },
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.level, item.number]);
            });

          // 更新配置
          this.option = {
            series: [
              {
                type: "bar",
                name: "数量",
                barMaxWidth: "20%",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(0, 97, 206, 1)" },
                      { offset: 0, color: "rgba(45, 198, 248, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {
              axisLabel: { interval: 0 },
              axisLine: { lineStyle: { color: "white" } },
              type: "category",
            },
            yAxis: {
              axisLine: { lineStyle: { color: "white" } },
              boundaryGap: [0, 0.01],
              type: "value",
            },
            dataset: {
              source: [
                ["level", "number"],
                // ["0-1年", 1495],
                // ["10年以上", 2],
                // ["5-10年", 1],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
