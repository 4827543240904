<template>
  <fragment>
    <Title title="优秀从业人员展示"></Title>
    <div class="layout-view withoutTitle pb0">
      <div class="components-layout youxiucongyerenyuanzhanshi">
        <div :id="`events-layout-${scrollId}`">
          <ul class="cyry-ul" :id="scrollId">
            <li v-for="(item, index) in dataSource" :key="index">
              <div class="picture-info">
                <div class="picture">
                  <img
                    :src="`${
                      item.workerPhoto ? `${imgUrl}${item.workerPhoto}` : defaultPicture
                    }`"
                  />
                </div>
                <div class="info">
                  <p>{{ item.staffName }}</p>
                  <p>{{ item.gender }}</p>
                  <p>
                    <ScoreStars :score="item.score || 5"></ScoreStars>
                  </p>
                  <p>{{ item.score || 5 }}分</p>
                </div>
              </div>
              <div class="description">
                {{ item.description }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <EmptyData v-if="isEmpty" />
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";
import ScoreStars from "@/components/common/ScoreStars.vue";
import { imgUrl } from "@/utils/config.js";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    ScoreStars,
    EmptyData,
  },
  data() {
    return {
      imgUrl,
      defaultPicture: "/images/worker.jpg",
      scrollId: createUniqueKey(12),
      dataSource: [],
      autoScrollObject: null,
      scrollDirection: "scrollLeft",
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/home/getHomeExcellentList`,
        param: {
          idOrg: this.HomeId,
        },
        success: (response) => {
          this.dataSource =
            response && response.data && response.data.data ? response.data.data : [];
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.youxiucongyerenyuanzhanshi {
  display: flex;
  width: 100%;
  .cyry-ul {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    // pointer-events: none;
    height: 100%;
    li {
      width: 30%;
      margin-right: 20px;
      flex-shrink: 0;
      background: @mainSessionBackGroundColor;
      border-radius: @mainBorderRadius;
      padding: @mainPadding;
      overflow: hidden;
      .picture-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 30%;
        max-height: 50vh;
        .picture {
          width: calc(~"(100% - .1rem) / 2");
          height: 100%;
          position: relative;
          img {
            .setImg(cover);
          }
        }
        .info {
          width: calc(~"(100% - .1rem) / 2");
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          line-height: @mainLineHeight;
          p {
            font-weight: 700;
            color: #fff;
            text-align: justify;
            font-size: @fontSizeSmall;
            &:first-child {
              .suolve(2);
            }
          }
        }
      }
      .description {
        flex: 1 1 auto;
        margin-top: 0.1rem;
        font-weight: 700;
        color: #fff;
        font-size: @fontSizeSmall;
        .suolve(7);
      }
    }
  }
}
</style>
