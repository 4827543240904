<template>
  <fragment></fragment>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      currentComponent: null,
    };
  },
  methods: {
    callBack(item) {
      this.currentComponent = item.key;
      this.componentCallBack && this.componentCallBack(item);
    },
  },
  beforeMount() {
    if (this.components && this.components[0] && this.components[0]["key"]) {
      this.currentComponent = this.components[0]["key"];
    }
  },
};
</script>

<style lang="less" scoped></style>
