<template>
  <div
    id="ny-breathe"
    :class="this.$store.state.webSocketStatus ? 'ny-breathe active' : 'ny-breathe'"
  >
    <transition name="fade">
      <div
        class="ny-breathe-light"
        v-show="visible || !this.$store.state.webSocketStatus"
      ></div>
    </transition>
  </div>
</template>

<script>
// import { createUniqueKey } from "@/utils/index.js";
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    create() {
      // let span = document.createElement("span"),
      //   spanId = createUniqueKey(8);
      // span.id = spanId;
      // document.getElementById("ny-breathe").appendChild(span);
      // window.setTimeout(() => {
      //   let spanObject = document.getElementById(spanId);
      //   spanObject.parentNode.removeChild(spanObject);
      // }, 4000);

      this.sto && window.clearTimeout(this.sto);

      this.visible = true;
      this.sto = window.setTimeout(() => {
        this.visible = false;
      }, 1000);
    },
  },
  mounted() {},
  watch: {
    "$store.state.webSocketInstructions": {
      handler: function () {
        this.create();
      },
    },
  },
};
</script>

<style lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@-webkit-keyframes sploosh {
  0% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0px rgba(38, 191, 197, 0.5);
    background: rgba(38, 191, 197, 0.5);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(38, 191, 197, 0);
    background: rgba(38, 191, 197, 0);
  }
}

.ny-breathe {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  left: 1000px;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  .ny-breathe-light {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: #4d0606;
    position: relative;
    border-radius: 50%;
    z-index: 2;
    will-change: opacity;
  }
}
.ny-breathe.active {
  .ny-breathe-light {
    background: green;
    box-shadow: 0 0 50px 5px green;
    // &:after {
    //   content: "";
    //   position: absolute;
    //   border-right: 6px solid rgba(255, 255, 255, 0.2);
    //   border-radius: 100%;
    //   top: 5px;
    //   left: 0px;
    //   width: 70px;
    //   height: 70px;
    //   transform: rotate(30deg);
    //   will-change: rotate;
    // }
  }
  > span {
    // position: absolute;
    // display: block;
    // z-index: -10;
    // left: -50%;
    // top: -50%;
    // width: 200%;
    // height: 200%;
    // border-radius: 50%;
    // animation: sploosh 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    // background: transparent;
    // will-change: scale;
  }
}
</style>
