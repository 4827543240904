<template>
  <fragment>
    <Title title="机构介绍"></Title>
    <div class="layout-view withoutTitle">
      <OrganizationIntroduction :data="data"></OrganizationIntroduction>
      <!-- <div class="components-layout jigoujieshao">
        <div class="main">
          <div class="organization-info">
            <p>{{ orgData.orgName }}</p>
            <p>建设标准：{{ orgStarLevel }}</p>
            <p>成立时间：{{ orgData.build_date }}</p>
            <p>公办民办：{{ orgData.orgNature }}</p>
            <p>联系电话：{{ orgData.orgPhone }}</p>
            <p>详细地址：{{ orgData.orgAddress }}</p>
          </div>
          <div class="organization-description">
            <div :id="`events-layout-${scrollId}`">
              <div
                :id="this.scrollId"
                class="description html-css"
                v-html="orgData.orgIntroduce"
              ></div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import OrganizationIntroduction from "@/components/common/OrganizationIntroduction.vue";
export default {
  mixins: [ComponentBase],
  components: {
    Title,
    OrganizationIntroduction,
  },
  data() {
    return {
      autoScrollObject: null,
      data: {
        // orgName: "南海区社会福利中心",
        // starLevel: "5星级",
        // establishedDate: "2002年底",
        // service_type: "福利院",
        // orgNature: "公办",
        // orgPhone: "075786415551",
        // orgAddress: "狮山镇罗村状元路1号",
        // orgIntroduce:
        //   "<p>佛山市南海区社会福利中心由南海区政府投入6000余万元，于2002年底全面建成投入运作。2005年度被广东省民政厅评为广东省一级福利机构。中心环境优美，是佛山市园林式单位。现有床位750张，包括老人床位400张，儿童床位200张，康复医院床位150张。</p><p>颐养院负责入住长者的安置托养、生活起居、生活护理、医疗保健、心理辅导、社工服务、教育、娱乐、康复等。儿童福利院负责入住孤残弃婴（童）的安置抚养、生活起居、疾病防治、教育、康复及心理辅导。康复医院负责中心在院老人、儿童的疾病防治、保健康复，并承担优抚对象疗养以及为周边社区居民提供诊疗服务，指导各镇街敬老院开展老年人疾病预防、医疗护理及生活照料工作；培训养老护理员。居家养老与社会工作服务部为广大社区居家老人提供居家生活照料、家政服务、室外生活照料、医疗康复护理、心理咨询及其他居家养老服务。引用社会工作专业理念，为社会广泛提供专业化、人性化的服务。</p><p>2020年12月，被国家卫生健康委授予“2020年全国敬老文明号”。</p>",
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgIntroduce?id_org=${this.OrgId}`,
        success: (response) => {
          this.data = {
            orgName: response.data.data.orgName,
            orgNature: response.data.data.orgNature,
            orgPhone: response.data.data.orgPhone,
            orgAddress: response.data.data.orgAddress,
            starLevel: response.data.data.starLevel,
            orgIntroduce: response.data.data.orgIntroduce,
            establishedDate: response.data.data.establishedDate,
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
// .jigoujieshao {
//   width: 100%;
//   height: 100%;
//   .main {
//     display: flex;
//     flex-direction: column;
//     border-radius: @mainBorderRadius;
//     padding: 0.1rem 0.2rem;
//     background: @mainSessionBackGroundColor;
//     overflow: hidden;
//     width: 100%;
//     height: 100%;
//     .organization-info {
//       padding-bottom: 0.1rem;
//       p {
//         font-weight: 700;
//         font-size: 0.2rem;
//         color: #fff;
//         line-height: @mainLineHeight;
//         &:first-child {
//           font-size: @fontSizeLarge;
//         }
//       }
//     }
//     .organization-description {
//       flex-grow: 1;
//       height: 100%;
//       overflow: hidden;
//       .description {
//         overflow-y: scroll;
//         // pointer-events: none;
//         height: 100%;
//         font-size: 0.2rem;
//       }
//     }
//   }
// }
</style>
