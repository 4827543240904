<template>
  <div class="components-layout pd0">
    <div class="NanHaiOverview">
      <div class="section zhkypt">
        <p>智慧养老平台</p>
      </div>
      <!-- 居家 -->
      <div class="section md jjfws">
        <p>入库供应商</p>
        <p>{{ data.homeOrgNum }}家</p>
      </div>
      <div class="section fwzgds">
        <p>服务总工单数</p>
        <p>{{ data.homeRecordNum }}单</p>
      </div>
      <div class="section fwzzzs-jj">
        <p>服务长者总数</p>
        <p>{{ data.homeElderNum }}人</p>
      </div>
      <div class="section zzjycyrs">
        <p>长者教育参与人数</p>
        <p>{{ data.zzjycyrs }}人</p>
      </div>
      <div class="section ljfwcyrs">
        <p>旅居服务参与人数</p>
        <p>{{ data.ljfwcyrs }}人</p>
      </div>
      <!-- 居家 -->

      <!-- 社区 -->
      <div class="section fwzzzs-sq">
        <p>服务长者总数</p>
        <p>{{ data.comElderTotalNum }}人</p>
      </div>
      <div class="section md sqxfy">
        <p>社区幸福院</p>
        <p>{{ data.comOrgNum }}家</p>
      </div>
      <!-- 社区 -->

      <!-- 机构 -->
      <div class="section md yljg">
        <p>养老机构</p>
        <p>{{ data.orgNum }}家</p>
      </div>
      <div class="section rzzzzs">
        <p>入住长者总数</p>
        <p>{{ data.orgResidentNum }}人</p>
      </div>
      <div class="section xfplhygxtwcbl">
        <p>消防喷淋和烟感系统完成比例</p>
        <p>{{ data.orgIsBuildFireSpraySys }}</p>
      </div>
      <div class="section yyjh">
        <p>医养结合</p>
        <p>{{ data.orgMedicalCar }}家</p>
      </div>
      <!-- 机构 -->
    </div>
  </div>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
export default {
  mixins: [ComponentBase],
  data() {
    return {
      data: {
        // 居家服务商
        homeOrgNum: 0,
        // 服务总工单数
        homeRecordNum: 0,
        // 服务长者人数（居家）
        homeElderNum: 0,
        // 长者教育参与人数
        zzjycyrs: 0,
        // 旅居服务参与人数
        ljfwcyrs: 0,
        // 服务长者人数（社区）
        comElderTotalNum: 0,
        // 社区幸福院
        comOrgNum: 0,
        // 养老机构
        orgNum: 0,
        // 入住长者总数
        orgResidentNum: 0,
        // 消防喷淋和烟感系统完成比例
        orgIsBuildFireSpraySys: 0,
        // 医养结合
        orgMedicalCar: 0,
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: "/nh/getNhOverView",
        success: (response) => {
          this.data = {
            homeOrgNum: response.data.data.homeOrgNum || 0,
            homeRecordNum: response.data.data.homeRecordNum || 0,
            homeElderNum: response.data.data.homeElderNum || 0,
            zzjycyrs: 0,
            ljfwcyrs: 0,
            comElderTotalNum: response.data.data.comElderTotalNum || 0,
            comOrgNum: response.data.data.comOrgNum || 0,
            orgNum: response.data.data.orgNum || 0,
            orgResidentNum: response.data.data.orgResidentNum || 0,
            orgIsBuildFireSpraySys: response.data.data.orgIsBuildFireSpraySys || "0%",
            orgMedicalCar: response.data.data.orgMedicalCar || 0,
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.NanHaiOverview {
  position: relative;
  width: 100%;
  padding-top: 44%;
  background: #00083d url(/images/nhqzl.png) no-repeat center;
  background-size: contain;
  .section {
    color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20%;
    width: 10.4%;
    height: 23.8%;
    p {
      &:first-child {
        padding: 0 15%;
      }
    }
  }
  .zhkypt {
    left: 36.85%;
    top: 2.4%;
    width: 26.7%;
    height: 62%;
    font-size: 38%;
    p {
      &:first-child {
        padding: 0 20%;
      }
    }
  }
  .section.md {
    width: 14.8%;
    height: 33.7%;
  }
  .jjfws {
    left: 18.1%;
    top: 20.9%;
  }
  .fwzgds {
    left: 4.36%;
    top: 39.7%;
  }
  .fwzzzs-jj {
    left: 6.35%;
    top: 3.1%;
  }
  .zzjycyrs {
    left: 13.95%;
    top: 68.6%;
    p {
      &:first-child {
        padding: 0 10%;
      }
    }
  }
  .ljfwcyrs {
    left: 28.95%;
    top: 60.4%;
    p {
      &:first-child {
        padding: 0 10%;
      }
    }
  }
  .fwzzzs-sq {
    left: 41.15%;
    top: 76.35%;
  }
  .sqxfy {
    left: 55.15%;
    top: 65%;
  }
  .yljg {
    left: 67.35%;
    top: 27%;
  }
  .rzzzzs {
    left: 78.3%;
    top: 0;
  }
  .xfplhygxtwcbl {
    left: 85.3%;
    top: 35.2%;
    font-size: 14%;
    p {
      &:first-child {
        padding: 0.5%;
      }
    }
  }
  .ygxtwcbl {
    left: 7.89rem;
    top: 3.15rem;
  }
  .yyjh {
    left: 78%;
    top: 68.6%;
    p {
      &:first-child {
        padding: 0;
      }
    }
  }
}
</style>
