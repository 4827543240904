<template>
  <div class="components-layout lingdaofengcai">
    <div class="main">
      <!-- 图片 -->
      <div class="photo">
        <img :src="`${imgUrl}${person_data.photo}`" v-if="person_data.photo" />
      </div>
      <!-- 图片 -->
      <!-- 名字，职业，工作年限 -->
      <div class="name-post-years">
        <p class="name-post">
          <span v-if="person_data.name">{{ person_data.name }}</span>
          <span v-if="person_data.post">{{ person_data.post }}</span>
        </p>
        <p class="years" v-if="person_data.years">
          <span>工作年限：{{ person_data.years }}</span>
        </p>
      </div>
      <!-- 名字，职业，工作年限 -->
      <!-- 描述 -->
      <div class="description">
        <div :id="`events-layout-${scrollId}`">
          <div
            class="html-css ldfc"
            :id="scrollId"
            v-html="person_data.description"
          ></div>
        </div>
      </div>
      <!-- 描述 -->
    </div>
    <EmptyData v-if="isEmpty" />
  </div>
</template>

<script>
import { createUniqueKey } from "@/utils/index.js";
import EmptyData from "@/components/common/EmptyData.vue";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import { imgUrl } from "@/utils/config.js";
export default {
  mixins: [ComponentBase],
  components: {
    EmptyData,
  },
  props: {
    person_data: {
      type: Object,
    },
  },
  computed: {
    isEmpty() {
      return !this.person_data || Object.keys(this.person_data).length == 0;
    },
  },
  data() {
    return {
      imgUrl,
      autoScrollObject: null,
      scrollId: createUniqueKey(12),
    };
  },
};
</script>

<style lang="less" scoped>
.lingdaofengcai {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .main {
    border-radius: @mainBorderRadius;
    padding: 40px;
    background: #112e74;
    overflow: hidden;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .photo {
      height: calc(~"(100% - 40px) / 2");
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .name-post-years {
      height: calc(~"(100% - 40px) * 0.2");
      span {
        font-size: @fontSizeSmall;
        color: #fff;
      }
      .name-post {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        span {
          &:first-child {
            font-size: @fontSizeMiddle;
            margin-right: 5px;
          }
        }
      }
      .years {
        margin-top: 5px;
        font-size: @fontSizeSmall;
        color: #fff;
        text-align: center;
      }
    }
    .description {
      height: calc(~"(100% - 40px) * 0.4");
      overflow: hidden;
      width: 100%;
      display: flex;
      flex-direction: column;
      .ldfc {
        overflow-y: scroll;
        // pointer-events: none;
        height: 100%;
        font-size: @fontSizeSmall;
        color: #fff;
      }
    }
  }
}
</style>
