<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: "/org/getCheckProportionTop",
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.orgName, item.checkProportion]);
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            // grid: { left: 650 },
            series: [
              {
                type: "bar",
                stack: "人数",
                barMaxWidth: "40%",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(190, 227, 0, 1)" },
                      { offset: 0, color: "rgba(32, 179, 0, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
              },
            ],
            title: { text: "单位：人" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {},
            yAxis: { type: "category", inverse: true },
            dataset: {
              source: [
                ["name", "入住率"],
                ...arr,
                // ["南海区社会福利中心", 94.49],
                // ["黄岐沙溪信孚托老院", 91.26],
                // ["佛山市南海区黄岐梁泳钊颐养院", 90.8],
                // ["狮山镇狮山敬老院", 90],
                // ["佛山市南海区狮山镇罗村敬老院", 85.19],
                // ["佛山市南海区西樵百西仁光颐老康复保健中心", 82.5],
                // ["九如城（狮山）康养中心", 81.56],
                // ["桂城敬老院", 81],
                // ["狮山镇松岗敬老院", 79.41],
                // ["佛山市南海区狮山镇官窑敬老院", 78.52],
              ],
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
