<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/nh/getElderNumByYear`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.year, item.number]);
            });

          // 更新配置
          this.option = {
            // grid: { containLabel: true },
            legend: { show: false },
            series: [
              {
                type: "bar",
                label: { show: true, position: "inside" },
                barWidth: 100,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 0, color: "rgba(45, 198, 248, 1)" },
                      { offset: 1, color: "rgba(0, 97, 206, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
              {
                type: "line",
                smooth: false,
                itemStyle: { normal: { lineStyle: { type: "solid" } } },
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {
              axisLabel: { show: true, textStyle: { color: "#fff", fontSize: "32" } },
              axisLine: { lineStyle: { color: "rgba(128, 128, 128, 1)" } },
              type: "category",
            },
            yAxis: {
              axisLabel: { show: true, textStyle: { color: "#fff", fontSize: "32" } },
              axisLine: { lineStyle: { color: "rgba(128, 128, 128, 1)" } },
              boundaryGap: [0, 0.01],
              type: "value",
            },
            grid: { top: 150 },
            dataset: {
              source: [
                ["year", "长者数"],
                // [2017, 1730],
                // [2018, 2058],
                // [2019, 2094],
                // [2020, 2094],
                // [2021, 2094],
                // [2022, 2094],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
