<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/nh/getOrderNumByTown`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.divisionName, item.allNum, item.subsidyNum || 0]);
            });

          // 更新配置
          this.option = {
            // grid: { containLabel: true },
            series: [
              {
                name: "补贴",
                type: "bar",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 0, color: "RGBA(191, 226, 9, 1)" },
                      { offset: 1, color: "RGBA(42, 181, 0, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
              {
                name: "自费",
                type: "bar",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 1, color: "RGBA(255, 137, 4, 1)" },
                      { offset: 0, color: "RGBA(255, 208, 42, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
            ],
            title: { text: "居家养老服务长者数量（单位：人）" },
            tooltip: {},
            xAxis: { type: "category" },
            yAxis: {},
            legend: { data: [] },
            dataset: {
              source: [
                ["镇街", "补贴", "自费"],
                // ["桂城街道", 323, 0],
                // ["九江镇", 404, 0],
                // ["西樵镇", 559, 0],
                // ["丹灶镇", 555, 0],
                // ["狮山镇", 894, 0],
                // ["大沥镇", 433, 0],
                // ["里水镇", 465, 0],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
