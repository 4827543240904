<template>
  <fragment>
    <Title title="按老年人能力评估标准分析" className="ny-title-small"></Title>
    <div class="layout-view withoutTitle pb0 fdr">
      <div class="layout-view mr10 wd50">
        <!-- <Nav :dataArray="components" :callBack="callBack"></Nav>
        <div class="layout-view withoutNav">
          <component :is="currentComponent"></component>
        </div> -->
        <PlatformElderByOverall></PlatformElderByOverall>
      </div>
      <div class="layout-view l20 wd50">
        <PlatformElderByAssessmentAbilityAssessment></PlatformElderByAssessmentAbilityAssessment>
      </div>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
// import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import PlatformElderByOverall from "@/components/nh/PlatformElderByOverall.vue";
// import PlatformElderByAssessmentByAge from "@/components/nh/PlatformElderByAssessmentByAge.vue";
import PlatformElderByAssessmentAbilityAssessment from "@/components/nh/PlatformElderByAssessmentAbilityAssessment.vue";
export default {
  components: {
    Title,
    // Nav,
    PlatformElderByOverall,
    // PlatformElderByAssessmentByAge,
    PlatformElderByAssessmentAbilityAssessment,
  },
  mixins: [LayoutBase],
  data() {
    return {
      // components: [
      //   {
      //     key: "PlatformElderByOverall",
      //     title: "整体情况",
      //   },
      //   {
      //     key: "PlatformElderByAssessmentByAge",
      //     title: "按年龄段",
      //   },
      // ],
    };
  },
  methods: {},
  beforeMount() {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
