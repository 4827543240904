import { render, staticRenderFns } from "./EmployeesByWorkingYears.vue?vue&type=template&id=588ba334&scoped=true&"
import script from "./EmployeesByWorkingYears.vue?vue&type=script&lang=js&"
export * from "./EmployeesByWorkingYears.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588ba334",
  null
  
)

export default component.exports