<template>
  <fragment>
    <Title title="“治未病”"></Title>
    <div class="layout-view withoutTitle pb0">
      <div class="components-layout">
        <div class="zhiweibing">
          <div class="steps">
            <ul class="line">
              <li>
                <span>1</span>
                <span>健康巡检</span>
              </li>
              <li>
                <span>2</span>
                <span>巡检评估</span>
              </li>
              <li>
                <span>3</span>
                <span>巡检报告</span>
              </li>
              <li>
                <span>4</span>
                <span>健康绿皮书</span>
              </li>
              <li>
                <span>5</span>
                <span>健康随访</span>
              </li>
            </ul>
          </div>
          <div class="infos">
            <div class="counts">
              <div class="circle">
                <span class="jkxjzcs">健康巡检总次数<br />{{ jkxjzcs }}</span>
                <span class="jkpgzcs">健康评估总次数<br />{{ jkpgzcs }}</span>
                <span class="jkbgzcs">健康报告总次数<br />{{ jkbgzcs }}</span>
              </div>
            </div>
            <div class="lists">
              <div :id="`events-layout-${scrollId}`">
                <ul class="elder-ul" :id="scrollId">
                  <li v-for="(item, index) in dataSource" :key="index">
                    <p class="elder_info">
                      <span>{{ item.name }}</span>
                      <span>{{ item.sex }}</span>
                      <span>{{ item.age }}</span>
                    </p>
                    <p class="elder_data">
                      <img src="/images/heartbeat.svg" />
                      <span>{{ item.heart_rate }}</span>
                      <img src="/images/top.svg" />
                      <span>{{ item.max_blood }}</span>
                      <img src="/images/bottom.svg" />
                      <span>{{ item.min_blood }}</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></fragment
  >
</template>

<script>
import { createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";

export default {
  components: { Title },
  mixins: [ComponentBase],
  data() {
    return {
      scrollId: createUniqueKey(12),
      jkxjzcs: 100,
      jkpgzcs: 100,
      jkbgzcs: 100,
      dataSource: [],
      autoScrollObject: null,
    };
  },
  methods: {},
  mounted() {
    let dataSource = [];
    dataSource.push({
      name: "梁爱",
      sex: "女",
      age: 70,
      heart_rate: 70,
      max_blood: 110,
      min_blood: 70,
    });
    dataSource.push({
      name: "白杜荣",
      sex: "男",
      age: 81,
      heart_rate: 82,
      max_blood: 120,
      min_blood: 75,
    });
    dataSource.push({
      name: "杨家标",
      sex: "男",
      age: 81,
      heart_rate: 77,
      max_blood: 114,
      min_blood: 72,
    });
    dataSource.push({
      name: "谭棣华",
      sex: "男",
      age: 82,
      heart_rate: 82,
      max_blood: 103,
      min_blood: 68,
    });
    dataSource.push({
      name: "杨业佳",
      sex: "男",
      age: 66,
      heart_rate: 81,
      max_blood: 110,
      min_blood: 70,
    });
    dataSource.push({
      name: "陈祖联",
      sex: "男",
      age: 70,
      heart_rate: 70,
      max_blood: 110,
      min_blood: 70,
    });
    dataSource.push({
      name: "白球堂",
      sex: "男",
      age: 90,
      heart_rate: 90,
      max_blood: 122,
      min_blood: 88,
    });
    this.dataSource = dataSource;
  },
};
</script>

<style lang="less" scoped>
.components-layout {
  .zhiweibing {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .steps {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 60px;
      ul.line {
        background: url(/images/inspection_process.png) no-repeat top center;
        background-size: contain;
        width: 100%;
        height: 0;
        padding-top: 6.333333%;
        position: relative;
        display: flex;
        li {
          position: absolute;
          z-index: 2;
          height: 100%;
          width: 6.3%;
          top: 0;
          &:first-child {
            left: 9.1%;
          }
          &:nth-child(2) {
            left: 28%;
          }
          &:nth-child(3) {
            left: 46.9%;
          }
          &:nth-child(4) {
            left: 65.7%;
          }
          &:nth-child(5) {
            left: 84.55%;
          }
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: @fontSizeSmall;
            &:first-child {
              height: 100%;
            }
            &:last-child {
              white-space: nowrap;
            }
          }
        }
      }
    }
    .infos {
      height: 0;
      display: flex;
      width: 100%;
      flex-grow: 1;
      .counts {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .circle {
          background: url(/images/overview_backgroundImg1.png) no-repeat center;
          background-size: contain;
          width: 80%;
          height: 0;
          padding-top: calc(~"93.3121% * 0.8");
          position: relative;
          span {
            position: absolute;
            z-index: 2;
            display: flex;
            width: 46%;
            height: 49%;
            color: #fff;
            font-size: @fontSizeSmall;
            justify-content: center;
            text-align: center;
            align-items: center;
            line-height: 1.6;
          }
          .jkxjzcs {
            left: 27%;
            top: 0;
          }
          .jkpgzcs {
            left: 0;
            bottom: 0;
          }
          .jkbgzcs {
            right: 0;
            bottom: 0;
          }
        }
      }
      .lists {
        height: 100%;
        width: 50%;
        ul.elder-ul {
          overflow-y: scroll;
          // pointer-events: none;
          height: 100%;
          li {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.098);
            border-radius: 20px;
            margin-bottom: 20px;
            padding: 20px 30px;
            &:last-child {
              margin-bottom: 0;
            }
            p {
              font-weight: 700;
              color: #fff;
              font-size: @fontSizeMiddle;
              line-height: 1.6;
              span {
                margin-right: 30px;
              }
              img {
                margin-right: 15px;
                width: 50px;
                height: 50px;
              }
            }
            p.elder_data {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
</style>
