import {
    request
} from "./request";
import md5 from 'js-md5';

// 根据窗体缩放设置html的fontSize
export function setRem() {
    const htmlDom = document.getElementsByTagName('html')[0];
    // let htmlWidth = document.body.scrollWidth;
    // 缩放比例
    // let ratio = getRatio();
    // let fontSize = ratio == 100 ? 1920 : 
    htmlDom.style.fontSize = `${192}px`;
}

// echarts的option里的symbolSize方法
export function symbolSize(rawValue, params) {
    let index = 24;
    return (
        (params.value.toString().length < 3 ?
            3 * index :
            params.value.toString().length * index) + 60
    );
}

export function createUniqueKey(len) {
    len = len || 32;
    let $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz",
        maxPos = $chars.length,
        str = "";
    for (let i = 0; i < len; i++) {
        str += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return str;
}

export function getRatio() {
    let ratio = 0,
        screen = window.screen,
        ua = navigator.userAgent.toLowerCase();

    if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
    } else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
    } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth;
    }

    if (ratio) {
        ratio = Math.round(ratio * 100);
    }
    return ratio;
}

// 获取url上的参数
export function getQueryVariable(variable) {
    let query = window.location.hash.substring(1).replace(/\/OverView\?/, '').replace(/\/LoginView\?/, '');
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return '';
}

// 岁数的排序
export function sortAge(a, b) {
    let parseIntA = parseInt(a[0]),
        parseIntB = parseInt(b[0]);

    if (/以下/.test(a[0])) {
        parseIntA--;
    }
    if (/以上/.test(a[0])) {
        parseIntA++;
    }
    if (/以下/.test(b[0])) {
        parseIntB--;
    }
    if (/以上/.test(b[0])) {
        parseIntB++;
    }
    return parseIntA - parseIntB;
}

// 判断从接口获取或者是从缓存获取
export function getCacheOrRequest(config) {
    // 获取储存的key值
    const storeKey = config.method == 'get' ? md5(config.url) : md5(`${config.url}/${JSON.stringify(config.param)}`);

    // 判断缓存有没有
    const storeData = this.$store.state.requestData[storeKey];
    if (storeData) {
        // 有
        this.$nextTick(() => {
            // console.log('【从缓存获取数据】', storeData);
            config.success && config.success(storeData);
        });
    } else {
        // 木有，重写回调方法
        if (config.success) {
            // 原回调方法
            const old_success = config.success;
            const new_success = (response) => {
                // console.log('【从接口获取数据】', response);
                // 执行回调方法
                old_success(response);
                // 储存到缓存
                this.$store.commit("setRequestData", {
                    k: storeKey,
                    v: JSON.parse(JSON.stringify(response))
                });
            }
            // 覆盖回调方法
            config.success = new_success;
        }
        // 发起请求
        request(config);
    }
}

/**
 * 平台大屏：9600px * 3240px
 * 现场会大屏：7680px * 3240px
 */
export const bsType = '平台大屏';