<template>
  <PictureIntroduction :picture_list="picture_list"></PictureIntroduction>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import PictureIntroduction from "@/components/common/PictureIntroduction.vue";
export default {
  mixins: [ComponentBase],
  components: {
    PictureIntroduction,
  },
  data() {
    return {
      picture_list: [],
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgIntroduce?id_org=${this.ComId}`,
        success: (response) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.pictureList &&
            response.data.data.pictureList.length
          ) {
            this.picture_list = response.data.data.pictureList;
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
