<template>
  <fragment>
    <Title title="养老机构运营情况排名前10家"></Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav">
      <component :is="currentComponent"></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import WelfareCentreOperationElderResidentsNumber from "@/components/org/WelfareCentreOperationElderResidentsNumber.vue";
import WelfareCentreOperationElderResidentsRate from "@/components/org/WelfareCentreOperationElderResidentsRate.vue";
import WelfareCentreOperationEmployeesNumber from "@/components/org/WelfareCentreOperationEmployeesNumber.vue";
export default {
  components: {
    Title,
    Nav,
    WelfareCentreOperationElderResidentsNumber,
    WelfareCentreOperationElderResidentsRate,
    WelfareCentreOperationEmployeesNumber,
  },
  mixins: [LayoutBase],
  data() {
    return {
      components: [
        {
          key: "WelfareCentreOperationElderResidentsNumber",
          title: "入住长者总数",
        },
        {
          key: "WelfareCentreOperationElderResidentsRate",
          title: "机构长者入住率",
        },
        {
          key: "WelfareCentreOperationEmployeesNumber",
          title: "从业人员总数",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
