<template>
  <div class="video-layout">
    <video controls :id="id" style="width: 100%; height: 100%"></video>
  </div>
</template>

<script>
import { createUniqueKey } from "@/utils/index.js";
import flvjs from "flv.js";
import videojs from "video.js";
import "videojs-contrib-hls";
import "video.js/dist/video-js.css";

export default {
  data() {
    return {
      videoPlayer: null,
      flvPlayer: null,
      id: createUniqueKey(12),
    };
  },
  methods: {
    destroyPlayer() {
      return new Promise((resolve) => {
        try {
          if (this.flvPlayer) {
            this.flvPlayer.pause();
            this.flvPlayer.unload();
            this.flvPlayer.detachMediaElement();
            this.flvPlayer.destroy();
            this.flvPlayer = null;
          } else if (this.videoPlayer) {
            this.videoPlayer.dispose();
            this.videoPlayer = null;
          }
        } catch (error) {
          console.error(error);
        }
        resolve();
      });
    },
    createVideoPlayer() {
      this.videoPlayer = videojs(
        document.getElementById(this.id),
        {
          controls: true,
          preload: "auto",
          autoplay: false,
          muted: true,
          language: "zh-CN",
          inactivityTimeout: false,
          controlBar: {},
          sources: [
            {
              src: this.$store.state.currentVideoUrl,
              type: "application/x-mpegURL",
            },
          ],
        },
        () => {
          this.videoPlayer.play();
        }
      );
    },
    createPlayer() {
      if (flvjs.isSupported()) {
        let flvPlayer = flvjs.createPlayer({
          type: "flv",
          isLive: true,
          hasAudio: false,
          hasVideo: true,
          url: this.$store.state.currentVideoUrl,
        });
        flvPlayer.attachMediaElement(document.getElementById(this.id));
        flvPlayer.load();
        flvPlayer.play();
        this.flvPlayer = flvPlayer;
      }
    },
  },
  mounted() {
    if (this.$store.state.currentVideoUrl) {
      this.$nextTick(() => {
        if (/\.m3u8/.test(this.$store.state.currentVideoUrl)) {
          this.createVideoPlayer();
        } else {
          this.createPlayer();
        }
      });
    }
  },
  beforeDestroy() {
    this.destroyPlayer();
  },
  watch: {
    "$store.state.currentVideoUrl": {
      deep: true,
      handler: function (url) {
        if (url != this.url) {
          if (this.flvPlayer) {
            this.destroyPlayer().then(() => {
              this.createPlayer();
            });
          } else {
            this.createPlayer();
          }
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.video-layout {
  width: 100%;
  height: 100%;
  .video-js {
    width: 100%;
    height: 100%;
  }
}
</style>
