<template>
  <div class="components-layout pd0">
    <div class="monitor">
      <div class="main">
        <div class="nav">
          <span>{{ type }}</span>
          <span>{{ type }}监控接入量 | {{ dataSource.length }}</span>
        </div>
        <div class="monitor-content">
          <div :id="`events-layout-${scrollId}`">
            <ul class="monitor-ul" :id="scrollId">
              <li
                v-for="(item, index) in dataSource"
                :key="index"
                @click="setVideo(item)"
              >
                <div class="picture">
                  <img :src="`${imgUrl}${item.monitorImg}`" />
                </div>
                <p v-if="item.monitorName">{{ item.monitorName }}</p>
              </li>
            </ul>
          </div>
          <EmptyData v-if="isEmpty" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EmptyData from "@/components/common/EmptyData.vue";
import { imgUrl } from "@/utils/config.js";
export default {
  components: {
    EmptyData,
  },
  mixins: [ComponentBase],
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      imgUrl,
      scrollId: createUniqueKey(12),
      dataSource: [],
      scrollDirection: "scrollLeft",
      autoScrollObject: null,
    };
  },
  methods: {
    setVideo(item) {
      this.$store.commit("setCommonValue", [
        {
          k: "currentMiddleModule",
          v: "Video",
        },
        {
          k: "currentVideoUrl",
          v: item.monitorUrl,
        },
      ]);
    },

    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgMonitorList?id_org=${this.OrgId}`,
        success: (response) => {
          this.dataSource =
            this.type == "消防安全" ? response.data.data.fire : response.data.data.food;
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.monitor {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .main {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .nav {
      display: flex;
      justify-content: space-between;
      height: 80px;
      align-items: center;
      span {
        line-height: 73px;
        height: 73px;
        padding: 0 20px;
        font-size: @fontSizeSmall;
        color: #fff;
        &:first-child {
          color: @mainBlue;
          padding-left: 0;
        }
        &:last-child {
          background: linear-gradient(90deg, rgb(0, 78, 255), rgb(41, 171, 254));
          border-radius: 60px;
          color: #fff;
        }
      }
    }
    .monitor-content {
      flex-grow: 1;
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      [id^="events-layout-"] {
        width: 100%;
        height: unset;
        display: flex;
        align-items: flex-end;
      }
      .monitor-ul {
        display: flex;
        overflow-x: scroll;
        width: 100%;
        // pointer-events: none;
        li {
          width: 20%;
          margin-right: 2%;
          flex-shrink: 0;
          .picture {
            width: 100%;
            padding-top: 62%;
            position: relative;
            img {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              display: block;
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
          p {
            border-radius: 60px;
            border: 6px solid #219afe;
            font-weight: 700;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: @fontSizeSmall;
            margin-top: 20px;
            padding: 10px;
          }
        }
      }
    }
  }
}
</style>
