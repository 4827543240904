var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('Title',{attrs:{"title":"基础设施展示"},scopedSlots:_vm._u([{key:"righter",fn:function(){return [_c('div',{staticClass:"jcss-right"},[_c('div',{class:{
            'nav-intruduce-span': true,
            lh60: true,
            unactive: _vm.current_type != '功能室',
          },on:{"click":function($event){return _vm.set_current_room('功能室')}}},[_vm._v(" 功能室 | "+_vm._s(_vm.function_room_list.length)+" ")]),_c('div',{class:{
            'nav-intruduce-span': true,
            lh60: true,
            mr0: true,
            unactive: _vm.current_type != '消防设施',
          },on:{"click":function($event){return _vm.set_current_room('消防设施')}}},[_vm._v(" 消防设施 | "+_vm._s(_vm.fire_room_list.length)+" ")])])]},proxy:true}])}),_c('div',{staticClass:"layout-view withoutTitle"},[_c('div',{staticClass:"components-layout"},[_c('div',{staticClass:"jichusheshizhanshi"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"jcss-content"},[_c('div',{attrs:{"id":("events-layout-" + _vm.scrollId)}},[_c('ul',{staticClass:"jcss-ul",attrs:{"id":_vm.scrollId}},_vm._l((_vm.dataSource),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"picture"},[_c('img',{attrs:{"src":("" + _vm.imgUrl + (item.roomPhoto || item.fireEquipmentPhoto))}})]),_c('p',[_vm._v(_vm._s(item.activityRoomName || item.fireEquipmentName))]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(item.roomName || item.fireEquipmentIntroduction)+" ")])])}),0)])])])]),(_vm.isEmpty)?_c('EmptyData'):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }