<template>
  <!-- 主窗体 -->
  <div class="main-form-layout">
    <!-- 主窗体顶部 -->
    <Top :currentOverView="currentOverView"></Top>
    <!-- 主窗体顶部 -->

    <!-- 主窗体内容 -->
    <component :is="currentOverView.component"></component>
    <!-- 主窗体内容 -->

    <Cover v-if="coverStatus"></Cover>
    <!-- <transition> -->
    <LoginCode v-if="loginCodeStatus" @setLoginCodeHide="setLoginCodeHide"></LoginCode>
    <!-- </transition> -->
    <Loading v-if="loadingStatus"></Loading>
  </div>
  <!-- 主窗体 -->
</template>

<script>
import { baseUrl } from "@/utils/config.js";
import Loading from "@/components/common/Loading.vue";
import Cover from "@/components/common/Cover.vue";
import LoginCode from "@/components/common/LoginCode.vue";

import FSOverview from "@/views/FSOverview.vue";
import NHOverview from "@/views/NHOverview.vue";
import OrgOverview from "@/views/OrgOverview.vue";
import ComOverview from "@/views/ComOverview.vue";
import HomeOverview from "@/views/HomeOverview.vue";

import Top from "@/views/common/Top.vue";

import { getQueryVariable } from "@/utils/index.js";
export default {
  components: {
    Loading,
    Cover,
    LoginCode,
    FSOverview,
    NHOverview,
    OrgOverview,
    ComOverview,
    HomeOverview,
    Top,
  },
  data() {
    return {
      loadingStatus: false,
      coverStatus: false,
      loginCodeStatus: false,
      currentOverView: {},
      // websocket地址
      kztWsAddress: `wss://${baseUrl}/webSocket/`,
      mapWsAddress: `wss://${baseUrl}/webSocket/msg_remind_1`,
      overViewData: {
        FS: {
          title: "佛山市总览",
          component: "FSOverview",
        },
        NH: {
          title: "南海区总览",
          component: "NHOverview",
          pages: 7,
        },
        Org: {
          title: "机构养老",
          component: "OrgOverview",
          pages: 3,
        },
        Com: {
          title: "社区幸福院",
          component: "ComOverview",
          pages: 2,
        },
        Home: {
          title: "入库供应商",
          component: "HomeOverview",
          pages: 3,
        },
      },
    };
  },
  methods: {
    setLoginCodeHide() {
      this.coverStatus = false;
      this.loginCodeStatus = false;
    },
    watchKeyBoard() {
      let that = this;
      let thatTime = 0;
      document.onkeydown = function (e) {
        let ev = e || event;
        if (ev.keyCode == 87) {
          if (getQueryVariable("token") == false) {
            this.$router.push("/LoginView");
            return false;
          }
          thatTime++;
          if (thatTime == 5) {
            that.coverStatus = true;
            that.loginCodeStatus = true;
            thatTime = 0;
          }
          return false;
        }
      };
    },
    wsInit: function () {
      if (typeof WebSocket === "undefined") {
        console.log("【浏览器不支持WebSocket】");
      } else {
        // 实例化socket
        let socket = new WebSocket(
          `${this.kztWsAddress}${this.$store.state.webSocketToken}`
        );
        // 监听socket连接
        socket.onopen = () => {
          console.log("【WebSocketKzt连接成功】");
          // 每10秒发一个心跳
          let kztSocketHeart = window.setInterval(() => {
            socket.send("心跳");
          }, 10000);
          this.$store.commit("setCommonValue", {
            k: "kztSocketHeart",
            v: kztSocketHeart,
          });
          this.$store.commit("setCommonValue", { k: "webSocketStatus", v: true });
        };
        // 监听socket连接
        socket.onclose = () => {
          console.log("【WebSocketKzt连接关闭】");
          // 如果意外关闭，就把心跳清掉
          if (this.$store.state.kztSocketHeart) {
            console.log("【WebSocketKzt心跳清除】");
            window.clearInterval(this.$store.state.kztSocketHeart);
          }
          window.setTimeout(() => {
            console.log("【WebSocketKzt连接关闭，重连中...】");
            this.wsInit();
          }, 100);
          this.$store.commit("setCommonValue", { k: "webSocketStatus", v: false });
        };
        // 监听socket错误信息
        socket.onerror = () => {
          console.log("【WebSocketKzt连接错误】");
        };
        // 监听socket消息
        socket.onmessage = (msg) => {
          if (/^(欢迎|客户端|心跳)/.test(msg.data)) {
            return;
          }
          this.$store.commit("setCommonValue", {
            k: "webSocketInstructions",
            v: JSON.parse(msg.data),
          });
        };
        // 储存到vuex
        this.$store.commit("setCommonValue", { k: "webSocket", v: socket });
        // this.$store.commit("setWebSocket", this.socket);
      }
    },
    wsMapInit: function () {
      if (typeof WebSocket === "undefined") {
        console.log("【浏览器不支持WebSocket】");
      } else {
        // 实例化socket
        let socket = new WebSocket(`${this.mapWsAddress}`);
        // 监听socket连接
        socket.onopen = () => {
          console.log("【WebSocketMap连接成功】");
          // 每10秒发一个心跳
          let mapSocketHeart = window.setInterval(() => {
            socket.send("心跳");
          }, 10000);
          this.$store.commit("setCommonValue", {
            k: "mapSocketHeart",
            v: mapSocketHeart,
          });
        };
        // 监听socket连接
        socket.onclose = () => {
          console.log("【WebSocketMap连接关闭】");
          // 如果意外关闭，就把心跳清掉
          if (this.$store.state.mapSocketHeart) {
            console.log("【WebSocketMap心跳清除】");
            window.clearInterval(this.$store.state.mapSocketHeart);
          }
          window.setTimeout(() => {
            console.log("【WebSocketMap连接关闭，重连中...】");
            this.wsMapInit();
          }, 100);
        };
        // 监听socket错误信息
        socket.onerror = () => {
          console.log("【WebSocketMap连接错误】");
        };
        // 监听socket消息
        socket.onmessage = (msg) => {
          if (/^(欢迎|客户端|心跳)/.test(msg.data)) {
            return;
          }
          this.$store.commit("setCommonValue", {
            k: "currentEmergencyInfo",
            v: JSON.parse(msg.data),
          });
        };
        this.$store.commit("setCommonValue", { k: "mapWebSocket", v: socket });
      }
    },
  },
  beforeMount() {
    let token = getQueryVariable("token"),
      lsToken = window.localStorage.getItem("token");

    // 验证token
    if (!/^dp-[0-9a-zA-Z]{32}$|ny/.test(token)) {
      // if (!/^dp-[0-9a-zA-Z]{32}$/.test(token)) {
      this.$router.push("/LoginView");
      return false;
    }

    let currentLayout = window.localStorage.getItem("currentLayout"),
      currentRightPage = window.localStorage.getItem("currentRightPage");

    // 获取的token和保存的token不一致就清空，让页面返回佛山市总览和第一页
    if (lsToken && lsToken != token) {
      currentRightPage = 1;
      currentLayout = null;
    }
    // 不固定第一页，echart图表会变形
    currentRightPage = 1;

    // 重新存一下token
    window.localStorage.setItem("token", token);

    // 恢复页码
    if (currentRightPage) {
      this.$store.commit("setCommonValue", {
        k: "currentRightPage",
        v: currentRightPage,
      });
    }

    // 恢复恢复页面
    if (currentLayout && this.overViewData[currentLayout]) {
      this.currentOverView = this.overViewData[currentLayout];
    } else {
      this.currentOverView = this.overViewData["FS"];
    }
  },
  mounted() {
    this.watchKeyBoard();
    this.wsMapInit();
  },
  watch: {
    "$store.state.webSocketInstructions": {
      handler: function (instructions) {
        switch (instructions.k) {
          case "setOverview":
            this.$store.commit("setCommonValue", { k: "currentRightPage", v: 1 });
            this.currentOverView = this.overViewData[instructions.v.split("to")[1]];
            window.localStorage.setItem("currentRightPage", 1);
            window.localStorage.setItem("currentLayout", instructions.v.split("to")[1]);
            break;
          // 设置页码
          case "currentRightPage":
            this.$store.commit("setCommonValue", {
              k: instructions.k,
              v: instructions.v,
            });
            window.localStorage.setItem("currentRightPage", instructions.v);
            break;
          // 默认直接赋值的
          // 展示模块到大屏中间
          // 切换机构
          // 切换镇街
          case "currentMiddleModule":
          case "currentOrganization":
          case "currentTown":
            this.$store.commit("setCommonValue", {
              k: instructions.k,
              v: instructions.v,
            });
            break;
          // 切换监控
          case "currentVideo":
            this.$store.commit("setCommonValue", [
              {
                k: "currentMiddleModule",
                v: "Video",
              },
              {
                k: "currentVideoUrl",
                v: instructions.v,
              },
            ]);
            break;
          // 切换图片
          case "currentImage":
            this.$store.commit("setCommonValue", [
              {
                k: "currentMiddleModule",
                v: "Picture",
              },
              {
                k: "currentPictureUrl",
                v: instructions.v,
              },
            ]);
            break;
          // 其他事件
          case "setEvent":
            // 平板登录完成
            if (instructions.v == "loginEd") {
              // 隐藏控制二维码
              this.setLoginCodeHide();
              // 把中间展示的设为无
              this.$store.commit("setCommonValue", {
                k: "currentMiddleModule",
                v: "",
              });
              // 切换回佛山页面
              this.currentOverView = this.overViewData["FS"];
              // 把页码设为第一页
              window.localStorage.setItem("currentRightPage", 1);
            }
            break;
          default:
            break;
        }
      },
    },
    "$store.state.webSocketToken": {
      deep: true,
      handler: function () {
        this.wsInit();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.v-enter {
  opacity: 0;
  transform: translate3d(-50%, 100%, 0);
}
.v-leave-to {
  opacity: 0;
  transform: translate3d(-50%, -100%, 0);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}
</style>
