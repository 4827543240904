import { render, staticRenderFns } from "./CommunityHappinessOverview.vue?vue&type=template&id=71443442&scoped=true&"
import script from "./CommunityHappinessOverview.vue?vue&type=script&lang=js&"
export * from "./CommunityHappinessOverview.vue?vue&type=script&lang=js&"
import style0 from "./CommunityHappinessOverview.vue?vue&type=style&index=0&id=71443442&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71443442",
  null
  
)

export default component.exports