<template>
  <fragment>
    <Title title="最新下单情况"></Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav">
      <div class="components-layout zuixinxiadanqingkuang">
        <!-- 状态 -->
        <!-- <ul class="nav">
          <li
            @click="setStatus('未服务')"
            :class="currentStatus == '未服务' ? 'active' : ''"
          >
            待服务订单
          </li>
          <li
            @click="setStatus('已完成')"
            :class="currentStatus == '已完成' ? 'active' : ''"
          >
            已完成订单
          </li>
        </ul> -->
        <!-- 状态 -->
        <!-- 订单信息 -->
        <div class="tables">
          <div :id="`events-layout-${scrollId}`">
            <div class="line">
              <span>姓名</span>
              <span>地址</span>
              <span>类别</span>
              <span>订单编号</span>
              <span>预约时间</span>
              <span>服务产品</span>
              <span>金额</span>
            </div>
            <ul class="orders-ul" :id="scrollId">
              <li
                :class="{ line: true }"
                v-for="(item, index) in dataSource"
                :key="index"
              >
                <span
                  ><i>{{ item.elderName }}</i></span
                >
                <span
                  ><i>{{ item.serviceAddress }}</i></span
                >
                <span
                  ><i>{{ item.classType }}</i></span
                >
                <span
                  ><i>{{ item.orderCode }}</i></span
                >
                <span
                  ><i>{{ item.assignDate }}</i></span
                >
                <span
                  ><i>{{ item.porductName }}</i></span
                >
                <span
                  ><i>{{ item.amount || "" }}</i></span
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- 订单信息 -->
        <EmptyData v-if="isEmpty" />
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import EmptyData from "@/components/common/EmptyData.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    Nav,
    EmptyData,
  },
  data() {
    return {
      scrollId: createUniqueKey(12),
      autoScrollObject: null,
      dataSource: [],
      currentStatus: "未服务",
      components: [
        {
          key: "未服务",
          title: "待服务订单",
        },
        {
          key: "已完成",
          title: "已完成订单",
        },
      ],
    };
  },
  methods: {
    callBack(data) {
      this.currentStatus = data.key;
      this.$nextTick(() => {
        this.get_component_data().then(() => {
          this.autoScrollObject && this.autoScrollObject.setScrollBegin().resetScroll();
        });
      });
    },
    get_component_data() {
      return new Promise((resolve) => {
        getCacheOrRequest.call(this, {
          method: "post",
          url: `/home/getNewOrderByTypeTop`,
          param: {
            serviceStatus: this.currentStatus,
          },
          success: (response) => {
            if (
              response &&
              response.data &&
              response.data.data &&
              Array.isArray(response.data.data)
            ) {
              if (response.data.data.length > 5) {
                this.dataSource = response.data.data.slice(0, 5);
              } else {
                this.dataSource = response.data.data;
              }
            } else {
              this.dataSource = [];
            }
            resolve();
          },
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.zuixinxiadanqingkuang {
  height: 100vh;
  display: flex;
  flex-direction: column;
  ul.nav {
    width: 100%;
    height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      font-size: 0.18rem;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 0.3rem;
      padding: 0.1rem;
      position: relative;
      min-width: 1.7rem;
      margin: 0 0.1rem;
      cursor: pointer;
      user-select: none;
      transition: 0.5s;
    }
    li.active {
      background: linear-gradient(90deg, rgb(0, 78, 255), rgb(41, 171, 254));
    }
  }
  .tables {
    flex: 1 1 auto;
    display: flex;
    color: #fff;
    overflow: hidden;
    border: 1px solid @mainBlue;
    width: 100%;
    .line {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: 14.2857%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: justify;
        font-size: 0.18rem;
        padding: 0.1rem 0.05rem;
        border-right: 1px solid @mainBlue;
        border-bottom: 1px solid @mainBlue;
        &:last-child {
          border-right: none;
          padding-right: 0.5px;
        }
      }
    }
    // .line.finished {
    //   span {
    //     width: 12.5%;
    //   }
    // }
    #events-layout {
      > .line {
        height: 0.6rem;
      }
    }
    ul.orders-ul {
      overflow-y: scroll;
      // pointer-events: none;
      height: 100%;
      // 这里要给回第一行line的高度，否则滚不下最低部
      padding-bottom: 0.6rem;
      > li {
        display: flex;
        height: 1rem;
        span {
          padding: 0;
          i {
            margin: 0.1rem 0.05rem;
            display: -webkit-box !important;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            line-height: 0.2rem;
            max-width: 100%;
            max-height: 100%;
            width: fit-content;
          }
        }
      }
    }
  }
}
</style>
