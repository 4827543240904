<template>
  <div class="components-layout pd0">
    <div class="shequxingfuyuanzonglan">
      <!-- 社区幸福院 -->
      <div class="section sqxfy">
        <p>社区幸福院</p>
        <p>{{ data.orgNum }}家</p>
      </div>
      <div class="section ljhdcs">
        <p>累计活动次数</p>
        <p>{{ data.activityNum }}次</p>
      </div>
      <div class="section jsyyztr md">
        <p>建设运营总投入</p>
        <p>{{ data.allIncome }}万元</p>
      </div>
      <div class="section qjtr">
        <p>区级投入</p>
        <p>{{ data.quIncome }}万元</p>
      </div>
      <div class="section zjjtr">
        <p>镇街级投入</p>
        <p>{{ data.zhenIncome }}万元</p>
      </div>
      <div class="section cjjtr">
        <p>村居级投入</p>
        <p>{{ data.cunIncome }}万元</p>
      </div>
      <div class="section pbyll">
        <p>评比优良率</p>
        <p>{{ data.assessment }}</p>
      </div>
      <div class="section zzzs">
        <p>长者总数</p>
        <p>{{ data.elderTotalNum }}人</p>
      </div>
      <div class="section cyryzs md">
        <p>从业人员总数</p>
        <p>{{ data.staffNum }}人</p>
      </div>
      <div class="section sg">
        <p>社工</p>
        <p>{{ data.socialStaffNum }}人</p>
      </div>
      <div class="section qt">
        <p>其他</p>
        <p>{{ data.otherStaffNum }}人</p>
      </div>
      <!-- 社区幸福院 -->
    </div>
  </div>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
export default {
  mixins: [ComponentBase],
  data() {
    return {
      data: {
        // 社区幸福院
        orgNum: 0,
        // 累计活动次数
        activityNum: 0,
        // 建设运营总投入
        allIncome: 0,
        // 区级投入
        quIncome: 0,
        // 镇街级投入
        zhenIncome: 0,
        // 村居级投入
        cunIncome: 0,
        // 评比优良率
        assessment: 0,
        // 长者总数
        elderTotalNum: 0,
        // 从业人员总数
        staffNum: 0,
        // 社工
        socialStaffNum: 0,
        // 其他
        otherStaffNum: 0,
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/com/getEntity",
        success: (response) => {
          this.data = {
            activityNum: response.data.data.activityNum || 0,
            socialStaffNum: response.data.data.socialStaffNum || 0,
            staffNum: response.data.data.staffNum || 0,
            otherStaffNum: response.data.data.otherStaffNum || 0,
            elderTotalNum: response.data.data.elderTotalNum || 0,
            quIncome: response.data.data.quIncome || 0,
            zhenIncome: response.data.data.zhenIncome || 0,
            cunIncome: response.data.data.cunIncome || 0,
            allIncome: response.data.data.allIncome || 0,
            assessment: response.data.data.assessment || 0,
            orgNum: response.data.data.orgNum || 0,
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.shequxingfuyuanzonglan {
  position: relative;
  width: 100%;
  padding-top: 44%;
  background: #00083d url(/images/sqxfyzl.png) no-repeat center;
  background-size: contain;
  .section {
    color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20%;
    width: 10.35%;
    height: 23.4%;
  }
  .section.md {
    width: 14.6%;
    height: 33.2%;
  }
  .sqxfy {
    width: 26.2%;
    height: 62%;
    left: 37.1%;
    top: 3.6%;
    font-size: @fontSizeLarge;
  }
  .ljhdcs {
    left: 12.9%;
    top: 5%;
    p {
      &:first-child {
        padding: 0 15%;
      }
    }
  }
  .jsyyztr {
    left: 19.45%;
    top: 34.8%;
  }
  .qjtr {
    left: 4.9%;
    top: 49.2%;
  }
  .zjjtr {
    left: 17.9%;
    top: 75.7%;
  }
  .cjjtr {
    left: 32.15%;
    top: 66.7%;
  }
  .pbyll {
    left: 71.2%;
    top: 0;
  }
  .zzzs {
    left: 53.1%;
    top: 71.9%;
  }
  .cyryzs {
    left: 66.6%;
    top: 33.1%;
  }
  .sg {
    left: 84.65%;
    top: 38.5%;
  }
  .qt {
    left: 77.5%;
    top: 71.3%;
  }
}
</style>
