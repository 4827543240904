import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false

// 公共函数
import { setRem } from "@/utils/index.js";

// 自定义主题来源
import echartTheme from "@/utils/echarts.js";

// 路由
import VueRouter from "vue-router";
Vue.use(VueRouter);

// 空标签
import Fragment from 'vue-fragment';
Vue.use(Fragment.Plugin)

// Echarts
var echarts = require('echarts');
// 注册自定义主题
echarts.registerTheme("echartTheme", echartTheme);
// 挂载
Vue.prototype.$echarts = echarts;

// Axios
import VueAxios from 'vue-axios';
import axios from "@/utils/request.js";
Vue.use(VueAxios, axios);

// 百度地图
// import BaiduMap from "vue-baidu-map";
// Vue.use(BaiduMap, {
//   ak: "jEmXEmo4L2GkmC1Ops1gvFGm75ALGDji",
// });

import 'video.js/dist/video-js.css';

// 样式
import "@/assets/less/reset.less";
import "@/assets/less/app.less";

// 路由配置
import router from "@/router.js";
// Vuex配置
import store from "@/store.js";

// 绑定窗体变动
window.onresize = setRem;
window.onload = setRem;
setRem();

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
