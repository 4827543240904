<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/nh/getElderNumList?type=sex`,
        success: (response) => {
          let allManNum = response.data.data.allManNum || 0,
            allWomanNum = response.data.data.allWomanNum || 0;

          // 更新配置
          this.option = {
            //   grid: {
            //     left: 150,
            //   },
            series: [
              {
                showItemStyle: true,
                barWidth: 50,
                itemStyle: {
                  color: {
                    colorStops: [
                      {
                        color: "RGBA(191, 226, 9, 1)",
                        offset: 0,
                      },
                      {
                        color: "RGBA(42, 181, 0, 1)",
                        offset: 1,
                      },
                    ],
                    globalCoord: false,
                    type: "linear",
                    x: 0,
                    x2: 0,
                    y: 0,
                    y2: 1,
                  },
                  borderRadius: 50,
                },
                name: "长者数",
                type: "bar",
                tooltip: {
                  textStyle: {
                    fontWeight: "bolder",
                    fontSize: 50,
                  },
                },
                markPoint: {
                  data: [
                    {
                      type: "max",
                      name: "最大值",
                    },
                    {
                      type: "min",
                      name: "最小值",
                    },
                  ],
                },
              },
            ],
            title: {
              text: "单位：人",
            },
            tooltip: {},
            xAxis: {
              type: "category",
            },
            yAxis: {},
            dataset: {
              source: [
                ["区", "南海区"],
                ["男", allManNum],
                ["女", allWomanNum],
                // [],
                // [],
                // [],
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
