<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {},
  },
  mounted() {
    this.option = {
      grid: { left: 150 },
      series: [
        { type: "line", ecode: { x: "月份", y: "公益性捐款" }, name: "公益性捐款" },
        { type: "line", ecode: { x: "月份", y: "捐助活动募集" }, name: "捐助活动募集" },
        { type: "line", ecode: { x: "月份", y: "慈善受捐合计" }, name: "慈善受捐合计" },
      ],
      title: { text: "补贴长者人数 单位：人" },
      tooltip: { textStyle: { fontSize: 30 } },
      xAxis: { type: "category" },
      yAxis: {},
      legend: { show: true, height: "40%", width: "100%", itemWidth: 50, itemHeight: 50 },
      dataset: { source: [] },
    };
  },
};
</script>

<style lang="less" scoped></style>
