<template>
  <fragment>
    <Title title="领导风采"></Title>
    <div class="layout-view withoutTitle">
      <PersonnelIntroduction :person_data="person_data"></PersonnelIntroduction>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import PersonnelIntroduction from "@/components/common/PersonnelIntroduction.vue";
export default {
  mixins: [ComponentBase],
  components: {
    Title,
    PersonnelIntroduction,
  },
  data() {
    return {
      person_data: {},
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgIntroduce?id_org=${this.OrgId}`,
        success: (response) => {
          let response_data_staff =
            response && response.data && response.data.data && response.data.data.staff
              ? response.data.data.staff
              : {};

          this.person_data =
            Object.keys(response_data_staff).length == 0
              ? {}
              : {
                  name: response_data_staff.staffName,
                  post: response_data_staff.post,
                  photo: response_data_staff.workerPhoto,
                  years: response_data_staff.workYears,
                  description: response_data_staff.personnelIntroduce,
                };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
