<template>
  <fragment>
    <Title title="幸福小站">
      <template v-slot:righter>
        <div class="nav-intruduce-span lh60 mr0">
          产品总数 | {{ dataSource.length || 0 }}
        </div>
      </template>
    </Title>
    <div class="layout-view withoutTitle pb0">
      <div class="components-layout">
        <div class="xingfuxiaozhan">
          <div class="main">
            <div class="xfxz-content">
              <div :id="`events-layout-${scrollId}`">
                <ul class="xfxz-ul" :id="scrollId">
                  <li v-for="(item, index) in dataSource" :key="index">
                    <div class="picture-info">
                      <div class="picture">
                        <img :src="`${imgUrl}${item.stationProductPicture}`" />
                      </div>
                      <div class="info">
                        <p>{{ item.stationProductName }}</p>
                        <p>
                          <span>{{ item.stationProductPrice || 0 }}</span
                          >元
                        </p>
                        <p>月销：{{ item.salesNum || 0 }}</p>
                      </div>
                    </div>
                    <div class="description">
                      {{ item.stationProductDescription }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmptyData v-if="isEmpty" />
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";
import { imgUrl } from "@/utils/config.js";
export default {
  components: { Title, EmptyData },
  mixins: [ComponentBase],
  data() {
    return {
      imgUrl,
      scrollId: createUniqueKey(12),
      autoScrollObject: null,
      scrollDirection: "scrollLeft",
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getHappinessProductList`,
        param: {
          idOrg: this.ComId,
        },
        success: (response) => {
          this.dataSource =
            response && response.data && response.data.data ? response.data.data : [];
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xingfuxiaozhan {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .main {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .xfxz-content {
      flex: 1 1 auto;
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      #events-layout {
        width: 100%;
        height: unset;
        max-height: 100%;
      }
      .xfxz-ul {
        display: flex;
        overflow-x: scroll;
        width: 100%;
        height: 100%;
        li {
          width: 23%;
          margin-right: 2%;
          flex-shrink: 0;
          display: flex;
          max-height: 100%;
          flex-direction: column;
          background: rgba(255, 255, 255, 0.098);
          border-radius: 0.1rem;
          padding: 0.1rem;
          .picture-info {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 30%;
            .picture {
              width: 100%;
              height: 100%;
              position: relative;
              overflow: hidden;
              img {
                .setImg(contain);
                // height: unset;
              }
            }
            .info {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              padding-left: 20px;
              p {
                font-weight: 700;
                color: #fff;
                text-align: justify;
                font-size: 30px;
                margin-bottom: 10px;
                &:first-child {
                  .suolve(2);
                }
                span {
                  font-size: 40px;
                }
              }
            }
          }
          .description {
            flex-grow: 1;
            margin-top: 0.1rem;
            font-weight: 700;
            color: #fff;
            text-align: justify;
            font-size: 0.18rem;
            .suolve(4);
          }
        }
      }
    }
  }
}
</style>
