<template>
  <fragment>
    <Title title="各镇街建设情况"></Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav">
      <component :is="currentComponent"></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import EachTownStreetConstructionByWelfareCentreNumber from "@/components/nh/EachTownStreetConstructionByWelfareCentreNumber.vue";
import EachTownStreetConstructionByLocalElderNumber from "@/components/nh/EachTownStreetConstructionByLocalElderNumber.vue";
export default {
  components: {
    Title,
    Nav,
    EachTownStreetConstructionByWelfareCentreNumber,
    EachTownStreetConstructionByLocalElderNumber,
  },
  mixins: [LayoutBase],
  data() {
    return {
      components: [
        {
          key: "EachTownStreetConstructionByWelfareCentreNumber",
          title: "养老服务机构数量",
        },
        {
          key: "EachTownStreetConstructionByLocalElderNumber",
          title: "本地户籍长者数量",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
