<template>
  <div class="components-layout nhylfwtx pd0">
    <div class="picture">
      <img :src="picture" />
    </div>
    <div class="pdf">
      <Pdf :url="url"></Pdf>
    </div>
  </div>
</template>

<script>
import Pdf from "@/components/common/Pdf.vue";
export default {
  components: { Pdf },
  data() {
    return {
      picture: "/images/tixi.png",
      url: "/pdf/nhtx.pdf",
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less">
.nhylfwtx {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .picture {
    height: 855px;
    img {
      .setImg(contain);
    }
  }
  .pdf {
    width: 100%;
    height: 2150px;
  }
}
</style>
