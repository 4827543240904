<template>
  <fragment>
    <Title title="服务收费标准"></Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav">
      <div class="components-layout pd0">
        <div class="service-standard-price">
          <div class="price" v-if="priceData.title && priceData.price">
            {{ priceData.title }}
          </div>
          <div class="description">
            <div :id="`events-layout-${scrollId}`">
              <div class="html-css" :id="scrollId" v-html="priceData.description"></div>
            </div>
          </div>
        </div>
        <EmptyData v-if="isEmpty" />
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import LayoutBase from "@/views/mixin/LayoutBase.vue";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import EmptyData from "@/components/common/EmptyData.vue";

export default {
  components: {
    Title,
    Nav,
    EmptyData,
  },
  mixins: [LayoutBase, ComponentBase],
  data() {
    return {
      scrollId: createUniqueKey(12),
      priceDataKey: {},
      priceData: {},
      components: [
        {
          key: "bedFeeIntroduce",
          title: "床位费",
        },
        {
          key: "nurseIntroduce",
          title: "护理费",
        },
        {
          key: "mealsPriceDescribe",
          title: "伙食费",
        },
      ],
    };
  },
  computed: {
    isEmpty() {
      return this.priceData.description ? false : true;
    },
  },
  methods: {
    componentCallBack(item) {
      this.priceDataKey = item;
      this.$nextTick(() => {
        this.get_component_data();
      });
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgIntroduce?id_org=${this.OrgId}`,
        success: (response) => {
          this.priceData = {
            title: this.priceDataKey["title"],
            price: response.data.data["price"] || 1,
            description: response.data.data[this.priceDataKey["key"]],
          };
        },
      });
    },
  },
  beforeMount() {
    this.priceDataKey = this.components[0];
  },
};
</script>

<style lang="less" scoped></style>
