<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  props: {
    current_year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getHomeStaffNumTop?year=${this.current_year}`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push({ 从业人员总数: item.staffNum, 机构名: item.orgName });
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            // grid: { left: 550 },
            series: [
              {
                encode: { x: "从业人员总数", y: "机构名" },
                name: "从业人员总数",
                showItemStyle: true,
                type: "bar",
                stack: "区域",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(253, 81, 76, 1)" },
                      { offset: 0, color: "rgba(253, 68, 168, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                barWidth: 40,
                barMinHeight: 50,
                zlevel: 5,
              },
            ],
            title: { text: "单位：人" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {},
            yAxis: { type: "category", inverse: true },
            dataset: {
              source: [
                // { 从业人员总数: 204, 机构名: "佛山市南海区春晖养老服务中心" },
                // { 从业人员总数: 22, 机构名: "广东谷丰健康管理有限公司" },
                // { 从业人员总数: 11, 机构名: "佛山市南海区启正社会工作服务中心" },
                // { 从业人员总数: 10, 机构名: "广东善缘社会工作服务中心" },
                // { 从业人员总数: 10, 机构名: "佛山市红星社会工作服务中心" },
                // { 从业人员总数: 9, 机构名: "佛山市禅城区馨和社会工作服务中心" },
                // { 从业人员总数: 9, 机构名: "佛山市南海区文华颐养公寓" },
                // { 从业人员总数: 7, 机构名: "佛山市南海区佰乐社会工作服务中心" },
                // { 从业人员总数: 7, 机构名: "广东沛乐健康管理有限公司" },
                // { 从业人员总数: 5, 机构名: "佛山市仁德社会工作服务中心" },
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
