<template>
  <OrganizationIntroduction :data="data"> </OrganizationIntroduction>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import OrganizationIntroduction from "@/components/common/OrganizationIntroduction.vue";
export default {
  mixins: [ComponentBase],
  components: {
    OrganizationIntroduction,
  },
  data() {
    return {
      data: {
        // name: "佛山市红星社会工作服务中心",
        // star: "暂无",
        // build_date: "",
        // phone: "0757-83261490",
        // address: "佛山市禅城区文华中路湖明街20号文华广场四楼410号",
        // description:
        //   '<p style="text-align: justify;">佛山市红星社会服务中心经佛山市民政局批准，于2013年12月6日正式注册成立，属于民办非企性质。主要业务范围是依法为青少年、老年人、残疾人等提供心理疏导、困难救助等社会工作服务。</p><p style="text-align: justify;">中心VMV(Vision Mission Values )：愿景(Vision )：为大众的健康与快乐，用专业手法引领社会大众进入身心健康生活模式；使命(Mission )：以健康与快乐为目标，以医疗专业知识作为工作特色，精神分析作为专业优势，养生保健作为知识基础，社工专业队伍作为保障，传播身心健康生活模式，靠近我温暖你，为有需要的人群提供全方位的专业化的社工服务；价值(Values) ：健康、快乐，专业为本，群策群力，助人自助。服务宗旨（Service Principle）：红星社工希望通过社工的3个H（心Heart、脑Head、手Hand）成就社会的2个H（健康Healthy、快乐Happy）！</p><p><br></p>',
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgIntroduce?id_org=${this.HomeId}`,
        success: (response) => {
          this.data = {
            orgName: response.data.data.orgName,
            orgNature: response.data.data.orgNature,
            orgPhone: response.data.data.orgPhone,
            orgAddress: response.data.data.orgAddress,
            starLevel: response.data.data.starLevel,
            orgIntroduce: response.data.data.orgIntroduce,
            establishedDate: response.data.data.establishedDate,
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
// .jigoujieshao
</style>
