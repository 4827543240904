<template>
  <fragment>
    <Title title="从业人员补贴情况分析"></Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout">
        <div class="congyerenyuanbutieqingkuangfenxi">
          <!-- 补贴人员 -->
          <div class="btry-layout">
            <div class="section btry">
              <p>补贴人员</p>
              <p>{{ counts }}人</p>
            </div>
            <div class="section jybtry">
              <p>就业补贴人员</p>
              <p>{{ data.employmentCount }}人</p>
            </div>
            <div class="section gwbtry">
              <p>岗位补贴人员</p>
              <p>{{ data.subsidyCount }}人</p>
            </div>
          </div>
          <!-- 补贴人员 -->
          <!-- 补贴金额 -->
          <div class="btje-layout">
            <div class="section btry">
              <p>补贴金额</p>
              <p>{{ amounts }}万元</p>
            </div>
            <div class="section jybtry">
              <p>就业补贴金额</p>
              <p>{{ data.employmentSubsidyAmount }}万元</p>
            </div>
            <div class="section gwbtry">
              <p>岗位补贴金额</p>
              <p>{{ data.jobSubsidyAmount }}万元</p>
            </div>
          </div>
          <!-- 补贴金额 -->
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
  },
  data() {
    return {
      data: {
        // 就业补贴人员
        employmentCount: 0,
        // 岗位补贴人员
        subsidyCount: 0,
        // 就业补贴金额
        employmentSubsidyAmount: 0,
        // 岗位补贴金额
        jobSubsidyAmount: 0,
      },
    };
  },
  computed: {
    counts() {
      return this.data.employmentCount + this.data.subsidyCount;
    },
    amounts() {
      return this.data.employmentSubsidyAmount + this.data.jobSubsidyAmount;
    },
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getSubsidyApplication?id_org=${this.OrgId}`,
        success: (response) => {
          this.data = {
            employmentCount: response.data.data.employmentCount || 0,
            subsidyCount: response.data.data.subsidyCount || 0,
            employmentSubsidyAmount: response.data.data.employmentSubsidyAmount || 0,
            jobSubsidyAmount: response.data.data.jobSubsidyAmount || 0,
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.congyerenyuanbutieqingkuangfenxi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  .btry-layout,
  .btje-layout {
    width: 49%;
    height: 0;
    padding-top: 41.3%;
    background: url(/images/frame_background_img.png) no-repeat center;
    background-size: contain;
    position: relative;
    .section {
      position: absolute;
      z-index: 2;
      width: 46.5%;
      height: 37%;
      color: #fff;
      font-size: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      line-height: 2;
    }
    .btje,
    .btry {
      left: 0;
      top: 33%;
    }
    .jybtje,
    .jybtry {
      left: 53.6%;
      top: 3%;
    }
    .gwbtje,
    .gwbtry {
      left: 53.6%;
      bottom: 0;
    }
  }
}
</style>
