<template>
  <fragment>
    <Title title="活动信息展示">
      <template v-slot:righter>
        <div class="nav-intruduce-span lh60 mr0">
          年度活动总数 | {{ activityNum || 0 }}
        </div>
      </template>
    </Title>
    <div class="layout-view withoutTitle pb0">
      <div class="components-layout">
        <div class="huodongxinxizhanshi">
          <div :id="`events-layout-${scrollId}`">
            <ul class="content-box" :id="scrollId">
              <li v-for="(item, index) in dataSource" :key="index">
                <div class="content-picture-video">
                  <div class="picture">
                    <img :src="`${imgUrl}${item.url}`" :alt="item.activity_name" />
                  </div>
                  <!-- <div class="video">
                    <div class="video-play">
                      <span>播放</span><span>视频</span>
                    </div>
                  </div> -->
                </div>
                <div class="content-info">
                  <p>{{ item.activityName }}</p>
                  <p>{{ item.beginDate }}</p>
                  <p>{{ item.contacts }} {{ item.contactsPhone }}</p>
                  <p>{{ item.participateNum || 0 }}人已报名</p>
                  <p>{{ item.activityAddress }}</p>
                  <span v-if="item.activityStatus" class="activity-status">{{
                    item.activityStatus
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <EmptyData v-if="isEmpty" />
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";
import { imgUrl } from "@/utils/config.js";
export default {
  components: { Title, EmptyData },
  mixins: [ComponentBase],
  data() {
    return {
      imgUrl,
      scrollId: createUniqueKey(12),
      autoScrollObject: null,
      activityNum: 0,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getActivityAllNum`,
        param: {
          idOrg: this.ComId,
        },
        success: (response) => {
          this.activityNum =
            response && response.data && response.data.data && response.data.data.number
              ? response.data.data.number
              : 0;
        },
      });
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getActivityInfo`,
        param: {
          idOrg: this.ComId,
        },
        success: (response) => {
          this.dataSource =
            response && response.data && response.data.data ? response.data.data : [];
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/less/content_box.less";
.huodongxinxizhanshi {
  width: 100%;
  height: 100%;
  .content-info {
    padding-right: 1rem;
    position: relative;
    .activity-status {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      padding: 0.05rem;
      font-size: 0.18rem;
      color: #fff;
      border: 2px solid #01e0f7;
      border-radius: 0.04rem;
      font-weight: 700;
    }
  }
}
</style>
