<template>
  <fragment>
    <div class="left-fixed">
      <div class="left-hover-btn">男性 | {{ data.manTotal || 0 }}</div>
      <div class="left-hover-btn">女性 | {{ data.womanTotal || 0 }}</div>
    </div>
    <EchartsLayout :option="option"></EchartsLayout>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
      data: {
        manTotal: 0,
        womanTotal: 0,
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/home/getElderAnalysisListBySex`,
        param: {
          idOrg: this.HomeId,
        },
        success: (response) => {
          let max = 0,
            elderAgeObj = {
              "60-70岁": 0,
              "70-80岁": 1,
              "80-90岁": 2,
              "90-100岁": 3,
              "100岁以上": 4,
            },
            indicatorArr = [
              { name: "60-70岁" },
              { name: "70-80岁" },
              { name: "80-90岁" },
              { name: "90-100岁" },
              { name: "100岁以上" },
            ],
            manArr = [0, 0, 0, 0, 0],
            womanArr = [0, 0, 0, 0, 0];

          response.data &&
            response.data.data &&
            response.data.data.manList &&
            response.data.data.manList.map((item) => {
              if (elderAgeObj[item.age] !== undefined) {
                if (item.number > max) {
                  max = item.number;
                }
                manArr[elderAgeObj[item.age]] = item.number;
              }
            });

          response.data &&
            response.data.data &&
            response.data.data.womanList &&
            response.data.data.womanList.map((item) => {
              if (elderAgeObj[item.age] !== undefined) {
                if (item.number > max) {
                  max = item.number;
                }
                womanArr[elderAgeObj[item.age]] = item.number;
              }
            });

          this.data = {
            // 男性总数
            manTotal: response.data.data.manTotal || 0,
            // 女性总数
            womanTotal: response.data.data.womanTotal || 0,
          };

          // 设置边界值
          indicatorArr = this.setAndicatorArrMax(indicatorArr, max);

          this.option = {
            legend: {
              show: true,
              height: "40%",
              width: "100%",
              itemWidth: 50,
              itemHeight: 50,
            },
            radar: {
              axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
              radius: "70%",
              shape: "polygon",
              splitArea: { areaStyle: { color: "transparent" } },
              splitLine: {
                lineStyle: {
                  color: [
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                  ],
                  width: 1,
                },
              },
              name: { textStyle: { color: "#ffffff" } },
              indicator: [
                // { name: "60-70岁", max: 150 },
                // { name: "70-80岁", max: 150 },
                // { name: "80-89岁", max: 150 },
                // { name: "90-100岁", max: 150 },
                // { name: "100岁以上", max: 150 },
                ...indicatorArr,
              ],
            },
            series: [
              // {
              //   type: "custom",
              //   name: "男",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              // {
              //   type: "custom",
              //   name: "女",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              {
                areaStyle: { normal: { color: "transparent" } },
                itemStyle: {
                  normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 },
                },
                lineStyle: { normal: { width: 5 } },
                symbol: "circle",
                type: "radar",
                data: [
                  // { value: [47, 40, 0, 36, 12], name: "男" },
                  // { value: [149, 4, 10, 24, 0], name: "女" },
                  { value: [...manArr], name: "男" },
                  { value: [...womanArr], name: "女" },
                ],
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            color: ["#16B9FF", "#FD415F"],
            polar: { radius: "70%", center: ["50%", "50%"] },
            angleAxis: {
              type: "category",
              clockwise: false,
              boundaryGap: false,
              splitLine: { show: false },
              axisLine: { show: false },
            },
            radiusAxis: {
              type: "value",
              max: 100,
              splitLine: { show: false },
              axisTick: { show: false },
              axisLabel: { show: false },
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
