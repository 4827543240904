<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  props: {
    current_year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getOrderTypeStaffTop?year=${this.current_year}`,
        success: (response) => {
          let typeArr = [],
            valueArr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              typeArr.push(item.name);
              valueArr.push(item.number || 0);
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            // grid: { left: 150 },
            series: [
              {
                type: "bar",
                stack: "区域",
                seriesLayoutBy: "row",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{ offset: 1, color: "#16B9FF" }],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                barWidth: 80,
              },
            ],
            title: { text: "单位：人" },
            tooltip: { trigger: "axis" },
            xAxis: { type: "category" },
            yAxis: {},
            dataset: {
              source: [
                [
                  "name",
                  // "陪同就医",
                  // "普通谈心",
                  // "日托服务",
                  // "居家清洁",
                  // "陪同购物",
                  // "养生保健",
                  ...typeArr,
                ],
                // ["从业人员总数", 2, 3, 1, 4, 1, 3],
                ["从业人员总数", ...valueArr],
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
