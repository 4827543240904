<template>
  <div class="map-layout">
    <!-- 长者紧急呼叫 -->
    <div class="map-emergency-info" v-if="emergencyInfo.dsn">
      <div class="header">
        智能设备 - {{ emergencyInfo.dsn == "device" ? "设备警报" : "紧急呼叫" }}
      </div>
      <div class="main">
        <div>
          <img src="/images/service.jpg" />
          <div class="elder-info">
            <span v-if="emergencyInfo.elderName">{{
              emergencyInfo.elderName || ""
            }}</span>
            <span v-if="emergencyInfo.sex">{{ emergencyInfo.sex || "" }}</span>
            <span v-if="emergencyInfo.age">{{ emergencyInfo.age || "" }}</span>
          </div>
          <div class="create-time" v-if="emergencyInfo.createTime">
            {{ emergencyInfo.createTime }}
          </div>
        </div>
        <div v-if="emergencyInfo.dsn == 'device'">
          <div class="device">{{ emergencyInfo.type }}</div>
          <div class="cellphone">{{ emergencyInfo.phoneNum || "" }}</div>
          <div class="home">{{ emergencyInfo.address || "" }}</div>
        </div>
        <div v-else>
          <div class="device">{{ emergencyInfo.deviceName || "咪狗" }}</div>
          <div class="cellphone">{{ emergencyInfo.phoneNum || "" }}</div>
          <div class="home">{{ emergencyInfo.address || "" }}</div>
          <div class="location">{{ emergencyInfo.position || "未能获取设备定位" }}</div>
        </div>
      </div>
    </div>
    <!-- 长者紧急呼叫 -->
    <!-- 地图主体 -->
    <div id="baidu-map" :class="mapClassName"></div>
    <!-- 地图主体 -->
    <!-- 地图左侧工具栏 -->
    <div class="map-tools" v-if="mapDivisionData.length > 0 || markData.length > 0">
      <!-- N级列表 -->
      <div class="section">
        <ul class="map-selecter" v-show="mapDivisionData.length > 0">
          <li
            v-for="(item, index) in mapDivisionData"
            :key="index"
            @click="setCurrentTown(item)"
            :class="item.id == currentTown.id ? 'active' : ''"
          >
            {{ item.divisionName }}
          </li>
        </ul>
      </div>
      <!-- N级列表 -->
      <!-- 三层机构 -->
      <div class="section" v-if="mapMarkerType.length > 0">
        <ul class="map-marker">
          <li
            v-for="(item, index) in mapMarkerType"
            :key="index"
            @click="setHiddenType(item)"
            :class="
              hiddenTypeArr.includes(item.title) ? item.type : `${item.type} active`
            "
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
      <!-- 三层机构 -->

      <!-- 机构 -->
      <div class="section" v-if="mapOrganizationList && mapOrganizationList.length">
        <ul
          id="map-organization"
          class="map-organization"
          :style="{ height: `${54 - mapMarkerType.length * 2}vh` }"
        >
          <li
            v-for="(item, index) in mapOrganizationList"
            :key="index"
            :id="`organizationId-${item.id}`"
            :class="{ active: currentOrganization.id == item.id }"
            @click="set_current_organization(item)"
          >
            {{ item.orgName }}
          </li>
        </ul>
      </div>
      <!-- 机构 -->
    </div>
    <!-- 地图左侧工具栏 -->
  </div>
</template>

<script>
import { bsType } from "@/utils/index.js";
import {
  nanhai,
  chancheng,
  shunde,
  gaoming,
  sanshui,
  guicheng,
  guichengLOC,
  jiujiang,
  jiujiangLOC,
  xiqiao,
  xiqiaoLOC,
  danzao,
  danzaoLOC,
  shishan,
  shishanLOC,
  dali,
  daliLOC,
  lishui,
  lishuiLOC,
} from "@/utils/coordinates.js";

const organizationType = {
  养老机构: "福利院",
  入库供应商: "服务商",
  社区养老场所: "幸福院",
};
const organizationTypeIcon = {
  福利院: "fuliyuan",
  服务商: "jujia",
  幸福院: "shequ",
};
import { getCacheOrRequest } from "@/utils/index.js";
export default {
  props: {
    // 自定义地图类名
    className: {
      type: String,
    },
    // 地图配置
    config: {
      type: Object,
    },
    // 地图类型
    mapType: {
      type: String,
    },
    // 渲染镇街数据框的函数
    renderAreaData: {
      type: Function,
    },
    // 区域数据
    areaData: {
      type: Array,
    },
    // 区划数据
    divisionData: {
      type: Array,
    },
  },
  data() {
    return {
      markData: [],
      // 默认配置
      defaultConfig: {
        // 中心点
        centerLng: 113.08377,
        centerLat: 23.036711,
        // 缩放比例
        zoom: 13,
      },
      // 地图类名
      mapClassName: "baidu-map",
      // 地图对象
      map: null,
      // 左侧工具栏上部分的数据
      // 左侧工具栏上部分当前选择的数据
      currentTown: { divisionName: "全区", id: "all" },
      // 左侧工具栏下部分【不】选择的数据
      hiddenTypeArr: [],
      // 行政区划高亮颜色
      activeColor: "rgba(253, 221, 49)",
      // 镇街数据
      townData: [],
      // 机构数据
      orgList: [],
      // 当前选择的机构
      currentOrganization: {},
      // 长者紧急呼叫信息
      emergencyInfo: {},
      // 延时器标识
      emergencyInfoAutoCloseIdent: false,
    };
  },
  computed: {
    // 地图的区划数据
    mapDivisionData() {
      return this.divisionData || this.townData;
    },
    // 地图的机构类型
    mapMarkerType() {
      return this.markData.filter((item) => !this.mapType || item.title == this.mapType);
    },
    // 地图显示的机构列表
    mapOrganizationList() {
      let currentOrganizationType = organizationType[this.mapType];
      return this.orgList.filter(
        (item) =>
          (this.currentTown.id == "all" || this.currentTown.id == item.streetTownId) &&
          (currentOrganizationType === undefined ||
            currentOrganizationType == item.personnelCategory)
      );
    },
  },
  methods: {
    // 设置镇街
    setCurrentTown(item) {
      if (item.id == this.currentTown.id) {
        return;
      }
      this.currentTown = item;
      this.removeMapOverlay().then(() => {
        this.renderOrganizationMarkData();
        this.renderAdministrativeAreaData();
      });
      // 默认显示第一个机构
      let organizationList = this.mapOrganizationList;
      if (organizationList.length > 0) {
        this.set_current_organization(organizationList[0]);
      }
    },
    setHiddenType(item) {
      if (this.hiddenTypeArr.includes(item.title)) {
        let newHiddenTypeArr = [];
        if (this.hiddenTypeArr.length > 1) {
          for (let idx of this.hiddenTypeArr) {
            if (idx != item.title) {
              newHiddenTypeArr.push(idx);
            }
          }
        }
        // 控制显示
        this.renderOrganizationMarkData();
        this.hiddenTypeArr = newHiddenTypeArr;
      } else {
        let currentOrganizationType = organizationType[item.title];
        this.removeMapOverlay((itm) => {
          return itm.imei == currentOrganizationType;
        });
        this.hiddenTypeArr.push(item.title);
      }
    },
    // 清空地图上的点
    removeMapOverlay(func = false) {
      return new Promise((resolve) => {
        // 控制隐藏
        let allOverlay = this.map.getOverlays();
        // 循坏所有点
        for (let i = 0; i < allOverlay.length; i++) {
          if (func !== false) {
            if (func(allOverlay[i])) {
              this.map.removeOverlay(allOverlay[i]);
            }
          } else {
            this.map.removeOverlay(allOverlay[i]);
          }
        }
        resolve();
      });
    },
    // 加载地图的promise对象
    loadBMap() {
      return new Promise((resolve) => {
        let stv = setInterval(() => {
          if (window.BMap && window.BMap.Map) {
            window.clearInterval(stv);
            resolve();
          }
        }, 200);
      });
    },
    // 渲染机构的地图标点
    renderOrganizationMarkData() {
      // 重新渲染出机构的点
      this.$nextTick(() => {
        this.markData.map &&
          this.markData.map((item) => {
            // 隐藏的机构类型
            if (
              this.hiddenTypeArr.includes(item.title) ||
              (this.mapType && item.title != this.mapType)
            ) {
              return;
            }
            item.data &&
              item.data.map &&
              item.data.length &&
              item.data.map((itm) => {
                // 只显示有镇街的和三个机构类型的
                if (
                  (this.currentTown.id == "all" ||
                    this.currentTown.id == "nh" ||
                    this.currentTown.id == itm.streetTownId) &&
                  ["福利院", "服务商", "幸福院"].includes(itm.personnelCategory)
                ) {
                  // 坐标点的背景图
                  let marker = new window.BMap.Marker(
                    new window.BMap.Point(itm.lng, itm.lat),
                    {
                      icon: new window.BMap.Icon(
                        `/images/${organizationTypeIcon[itm.personnelCategory]}.png`,
                        new window.BMap.Size(80, 80)
                      ),
                    }
                  );
                  // 给一个标识
                  marker.imei = itm.personnelCategory;
                  marker.addEventListener("click", () => {
                    this.set_current_organization(itm);
                  });
                  this.map.addOverlay(marker);
                }
              });
          });
      });
    },
    // 设置全区中心点
    setOriginCenter(map) {
      const currentConfig = Object.assign(this.defaultConfig, this.config);
      map.centerAndZoom(
        new window.BMap.Point(currentConfig.centerLng, currentConfig.centerLat),
        currentConfig.zoom
      );
    },
    // 渲染行政区划的多边形
    renderAdministrativeAreaData() {
      const that = this;
      let BMap = window.BMap;
      // 渲染大框
      this.areaData &&
        this.areaData.map &&
        this.areaData.map((item, index) => {
          let coordinateArr = [];

          if (
            this.currentTown.id != "all" &&
            this.currentTown.divisionName != item.divisionName
          ) {
            return;
          }
          switch (item.divisionName) {
            case "桂城街道":
              if (bsType == "现场会大屏") {
                guichengLOC.offset[0] = 220;
              }
              coordinateArr = guicheng;
              item = { ...item, ...guichengLOC };
              break;
            case "九江镇":
              if (bsType == "现场会大屏") {
                jiujiangLOC.offset[0] = 500;
              }
              coordinateArr = jiujiang;
              item = { ...item, ...jiujiangLOC };
              break;
            case "西樵镇":
              if (bsType == "现场会大屏") {
                xiqiaoLOC.offset[0] = -560;
              }
              coordinateArr = xiqiao;
              item = { ...item, ...xiqiaoLOC };
              break;
            case "丹灶镇":
              coordinateArr = danzao;
              if (bsType == "现场会大屏") {
                danzaoLOC.offset[0] = -580;
              }
              item = { ...item, ...danzaoLOC };
              break;
            case "狮山镇":
              coordinateArr = shishan;
              if (bsType == "现场会大屏") {
                shishanLOC.offset[0] = -920;
              }
              item = { ...item, ...shishanLOC };
              break;
            case "大沥镇":
              coordinateArr = dali;
              if (bsType == "现场会大屏") {
                daliLOC.offset[0] = 150;
              }
              item = { ...item, ...daliLOC };
              break;
            case "里水镇":
              if (bsType == "现场会大屏") {
                lishuiLOC.offset[0] = -180;
              }
              coordinateArr = lishui;
              item = { ...item, ...lishuiLOC };
              break;
            case "禅城区":
              coordinateArr = chancheng;
              break;
            case "南海区":
              coordinateArr = nanhai;
              break;
            case "顺德区":
              coordinateArr = shunde;
              break;
            case "高明区":
              coordinateArr = gaoming;
              break;
            case "三水区":
              coordinateArr = sanshui;
              break;
            default:
              break;
          }
          if (coordinateArr.length < 1) {
            return;
          }

          if (item.singleOffset && bsType == "现场会大屏") {
            item.singleOffset[0] = item.singleOffset[0] - 100;
          }
          this.renderAreaData &&
            this.map.addOverlay(
              new BMap.Label(this.renderAreaData(item, index), {
                position: new BMap.Point(
                  parseFloat(coordinateArr[0][0]),
                  parseFloat(coordinateArr[0][1])
                ),
                offset: new BMap.Size(
                  ...(this.currentTown.id == "all"
                    ? item.offset
                    : item.singleOffset || item.offset)
                ),
              })
            );

          let newCoordinateArr = [];
          for (let i = 0; i < coordinateArr.length; i++) {
            newCoordinateArr.push(
              new BMap.Point(
                parseFloat(Number(coordinateArr[i][0]).toFixed(6)),
                parseFloat(Number(coordinateArr[i][1]).toFixed(6))
              )
            );
          }

          // 创建行政区划多边形
          let ply = new BMap.Polygon(newCoordinateArr, {
            strokeWeight: 2,
            strokeOpacity: 1,
            strokeColor: "#555",
            // 控制覆盖物的颜色
            fillColor: item.color,
            // 控制覆盖物的透明度
            fillOpacity: 0.3,
          });

          // 添加覆盖物
          this.map.addOverlay(ply);
          // 建立多边形覆盖物
          ply.addEventListener("mouseover", function () {
            document.querySelector(
              `.area-info-${index}`
            ).className = `area-info area-info-${index} active`;
            if (this.getFillColor() != that.activeColor) {
              ply.setFillColor(that.activeColor);
              ply.setFillOpacity(0.8);
            }
          });
          ply.addEventListener("mouseout", function () {
            document.querySelector(
              `.area-info-${index}`
            ).className = `area-info area-info-${index}`;
            ply.setFillColor(item.color);
            ply.setFillOpacity(0.3);
          });

          // 设置中心点
          if (this.currentTown.id == "all") {
            this.setOriginCenter(this.map);
          } else {
            item.center &&
              this.map.centerAndZoom(
                new BMap.Point(item.center.lng, item.center.lat),
                item.zoom
              );
          }
        });
    },
    // 获取镇街列表
    getAdministrationDivision() {
      return new Promise((resolve) => {
        getCacheOrRequest.call(this, {
          method: "get",
          url: `/org/getAdministrationDivision`,
          success: (response) => {
            let townData = [{ divisionName: "全区", id: "all" }];
            response &&
              response.data &&
              response.data.data.map &&
              response.data.data.map((item) => {
                townData.push({ divisionName: item.divisionName, id: item.id });
              });
            this.townData = townData;
            resolve();
          },
        });
      });
    },
    // 获取机构列表
    getOrgList() {
      return new Promise((resolve) => {
        getCacheOrRequest.call(this, {
          method: "post",
          url: `/org/getOrgList`,
          param: {},
          success: (response) => {
            let markData = [
                {
                  type: "fuliyuan",
                  title: "养老机构",
                  data: [],
                },
                {
                  type: "shequ",
                  title: "社区养老场所",
                  data: [],
                },
                {
                  type: "jujia",
                  title: "入库供应商",
                  data: [],
                },
                {
                  type: "zhangzhe",
                  title: "居家养老服务长者",
                  data: [],
                },
              ],
              keyIndex = {
                福利院: 0,
                幸福院: 1,
                服务商: 2,
              };
            response &&
              response.data &&
              response.data.data.map &&
              response.data.data.map((item) => {
                if (keyIndex[item.personnelCategory] !== undefined) {
                  markData[keyIndex[item.personnelCategory]]["data"].push({
                    orgName: item.orgName,
                    id: item.id,
                    adminAreaId: item.adminAreaId,
                    streetTownId: item.streetTownId,
                    lat: item.lat,
                    lng: item.lon,
                    personnelCategory: item.personnelCategory,
                  });
                }
              });
            // 地图点坐标集合
            this.markData = markData;

            // 机构列表
            this.orgList =
              response && response.data && response.data.data ? response.data.data : [];
            resolve();
          },
        });
      });
    },
    // 设置当前选择的机构并储存到VUEX
    set_current_organization(orgInfo, autoScroll = false) {
      // 相同的点击和过滤没有地图类型的
      if (orgInfo.id == this.currentOrganization.id || !this.mapType) {
        return;
      }
      this.currentOrganization = orgInfo;
      if (orgInfo.personnelCategory == "服务商") {
        console.log("【服务商】", orgInfo);
        this.$store.commit("setCommonValue", [
          {
            k: "HomeId",
            v: orgInfo.id,
          },
          {
            k: "HomeName",
            v: orgInfo.orgName,
          },
        ]);
      } else if (orgInfo.personnelCategory == "福利院") {
        console.log("【福利院】", orgInfo);
        this.$store.commit("setCommonValue", [
          {
            k: "OrgId",
            v: orgInfo.id,
          },
          {
            k: "OrgName",
            v: orgInfo.orgName,
          },
        ]);
      } else if (orgInfo.personnelCategory == "幸福院") {
        console.log("【幸福院】", orgInfo);
        this.$store.commit("setCommonValue", [
          {
            k: "ComId",
            v: orgInfo.id,
          },
          {
            k: "ComName",
            v: orgInfo.orgName,
          },
        ]);
      }
      this.$nextTick(() => {
        if (autoScroll) {
          document.getElementById("map-organization").scrollTop =
            document.getElementById(`organizationId-${orgInfo.id}`).offsetTop -
            document.getElementById("map-organization").offsetTop -
            20;
        }
      });
    },
    createEmergencyInfo(emergencyInfo) {
      if (emergencyInfo.position) {
        // 有位置数据，直接显示
        this.emergencyInfo = emergencyInfo;
      } else {
        if (emergencyInfo.lng && emergencyInfo.lat) {
          // 有经纬度，尝试前端去获取
          try {
            let gc = new window.BMap.Geocoder();
            gc.getLocation(
              new window.BMap.Point(emergencyInfo.lng, emergencyInfo.lat),
              (rs) => {
                // 解析完成，有可能是空，直接赋值
                emergencyInfo.position = rs.address;
                this.emergencyInfo = emergencyInfo;
              }
            );
          } catch (error) {
            // 获取报错，直接赋值
            this.emergencyInfo = emergencyInfo;
          }
        } else {
          // 没有经纬度，无法获取，直接赋值
          this.emergencyInfo = emergencyInfo;
        }
      }
      // const marker = new window.BMap.Label(
      //   `<div class="map-emergency-info">
      //       <p class="t">${emergencyInfo.elderName}</p>
      //       <p>${emergencyInfo.sex}<span class="space">${emergencyInfo.age}</span></p>
      //       <p>${emergencyInfo.phoneNum}</p>
      //       <p>${emergencyInfo.idCard}</p>
      //       <p>${emergencyInfo.address}</p>
      //     </div>`,
      //   {
      //     position: new window.BMap.Point("113.08377", "23.036711"),
      //   }
      // );
      // marker.imei = emergencyInfo.dsn;
      // this.map.addOverlay(marker);

      // 每次创建的时候，都要刷新自动销毁延时器
      // 先清空原有的
      window.clearTimeout(this.emergencyInfoAutoCloseIdent);
      this.emergencyInfoAutoCloseIdent = false;

      // 再创建新的
      this.$nextTick(() => {
        this.emergencyInfoAutoCloseIdent = window.setTimeout(() => {
          console.log("【地图弹屏自动隐藏】");
          this.destroyEmergencyInfo();
        }, 30000);
      });
    },
    destroyEmergencyInfo() {
      // // 控制隐藏
      // let allOverlay = this.map.getOverlays();
      // // 循坏所有点
      // for (let i = 0; i < allOverlay.length; i++) {
      //   if (allOverlay[i].imei === emergencyInfo.dsn) {
      //     this.map.removeOverlay(allOverlay[i]);
      //   }
      // }
      this.emergencyInfo = {};
      window.clearTimeout(this.emergencyInfoAutoCloseIdent);
      this.emergencyInfoAutoCloseIdent = false;
    },
    getAge(identityCard) {
      if (identityCard === "") {
        return identityCard;
      }
      identityCard = identityCard + "";
      var len = (identityCard + "").length;
      if (len === 0) {
        return 0;
      } else {
        // 身份证号码只能为15位或18位其它不合法
        if (len !== 15 && len !== 18) {
          return 0;
        }
      }
      var strBirthday = "";
      // 处理18位的身份证号码从号码中得到生日和性别代码
      if (len === 18) {
        strBirthday =
          identityCard.substr(6, 4) +
          "/" +
          identityCard.substr(10, 2) +
          "/" +
          identityCard.substr(12, 2);
      }
      if (len === 15) {
        strBirthday =
          "19" +
          identityCard.substr(6, 2) +
          "/" +
          identityCard.substr(8, 2) +
          "/" +
          identityCard.substr(10, 2);
      }
      // 时间字符串里，必须是“/”
      var birthDate = new Date(strBirthday);
      var nowDateTime = new Date();
      var age = nowDateTime.getFullYear() - birthDate.getFullYear();
      // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() === birthDate.getMonth() &&
          nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
  },
  mounted() {
    this.getAdministrationDivision().then(() => {
      this.getOrgList().then(() => {
        this.loadBMap().then(() => {
          // 创建地图
          let map = new window.BMap.Map("baidu-map", {
            enableMapClick: false,
          });

          // 设置中心点并缩放
          this.setOriginCenter(map);

          // 禁止地图拖拽
          map.disableDragging();
          map.disableScrollWheelZoom();
          map.disableDoubleClickZoom();

          // 开启鼠标滚轮
          // map.enableScrollWheelZoom();

          // map.addEventListener("click", function (e) {
          //   console.log(e);
          // });

          this.map = map;

          this.renderOrganizationMarkData();
          this.renderAdministrativeAreaData();

          // this.createEmergencyInfo({
          //   dsn: "device",
          //   elderName: "张三",
          //   phoneNum: "1343433444",
          //   idCard: 440602199012241513,
          //   sex: "男",
          //   address: "121212",
          //   level: "2",
          //   sn: "000E481B2401_0B120077",
          //   type: "呼救报警 有发生个人危险",
          //   status: "1",
          // });

          // window.setTimeout(() => {
          //   this.destroyEmergencyInfo();
          // }, 6000);
        });
      });
    });

    // 地图类
    this.mapClassName = "baidu-map " + (this.className || "");
  },
  beforeDestroy() {
    this.map = null;
    this.orgList = [];
    this.markData = [];
    this.townData = [];
  },
  watch: {
    "$store.state.currentEmergencyInfo": {
      handler: function (emergencyInfo) {
        console.log("emergencyInfo>>", emergencyInfo);
        if (emergencyInfo.eventId === 300) {
          let age = this.getAge(emergencyInfo.idCard);
          emergencyInfo.age = age ? `${age}岁` : "";
          this.createEmergencyInfo(emergencyInfo);
        } else if (emergencyInfo.eventId === 301) {
          this.destroyEmergencyInfo(emergencyInfo);
        } else {
          // 20211129新增设备报警弹窗
          let deviceAlert = {
            dsn: "device",
            elderName: emergencyInfo.elderName,
            phoneNum: emergencyInfo.phoneNum,
            sex: emergencyInfo.sex,
            address: emergencyInfo.address,
            idCard: emergencyInfo.idCard,
            createTime: emergencyInfo.createTime,
            ...JSON.parse(emergencyInfo.msg),
          };
          if (emergencyInfo.idCard) {
            try {
              let age = this.getAge(emergencyInfo.idCard);
              if (age) {
                deviceAlert.age = `${age}岁`;
              }
            } catch (error) {
              console.error(error);
            }
          }
          this.createEmergencyInfo(deviceAlert);
        }
      },
    },
    "$store.state.currentTown": {
      handler: function (currentTown) {
        this.setCurrentTown(currentTown);
      },
    },
    "$store.state.currentOrganization": {
      handler: function (currentOrganization) {
        this.set_current_organization(currentOrganization, true);
      },
    },
  },
};
</script>
<style lang="less">
.map-layout {
  width: 100%;
  height: 100%;
  position: relative;
  .map-tools {
    position: absolute;
    left: 15px;
    top: 15px;
    .section {
      margin-bottom: 36px;
      background: rgba(16, 0, 93, 0.8);
      padding: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      ul {
        li {
          background: linear-gradient(
            90deg,
            rgba(0, 78, 255, 0.3),
            rgba(41, 171, 254, 0.3)
          );
          color: #fff;
          font-size: 38px;
          border-radius: 60px;
          margin: 20px 0;
          padding: 10px 0;
          cursor: pointer;
          transition: 0.5s;
          width: 450px;
        }
        li.active {
          background: @mainGradientColor;
        }
      }
    }
    ul.map-selecter {
      li {
        text-align: center;
      }
    }
    ul.map-selecter-2 {
      margin-top: 10px;
      li {
        text-align: center;
        background: transparent;
      }
      li.active {
        color: rgb(1, 224, 247);
        background: transparent !important;
      }
    }
    ul.map-marker {
      li {
        position: relative;
        padding: 10px 16px 10px 48px;
        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 6px;
          top: 50%;
          width: 36px;
          height: 36px;
          z-index: 2;
          transform: translateY(-50%);
        }
      }
      li.fuliyuan {
        &:before {
          background: url(/images/fuliyuan.png) no-repeat center center;
          background-size: contain;
        }
      }
      li.shequ {
        &:before {
          background: url(/images/shequ.png) no-repeat center center;
          background-size: contain;
        }
      }
      li.jujia {
        &:before {
          background: url(/images/jujia.png) no-repeat center center;
          background-size: contain;
        }
      }
      li.zhangzhe {
        &:before {
          background: url(/images/zhangzhe.png) no-repeat center center;
          background-size: contain;
        }
      }
    }
    ul.map-organization {
      overflow-y: scroll;
      li {
        text-align: center;
      }
    }
  }
  .baidu-map {
    width: 100%;
    height: 100%;

    .BMapLabel {
      padding: 0 !important;
      border: none !important;
      background-color: transparent !important;
    }
    .BMap_Marker {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .BMapLabel {
      .area-info {
        align-self: stretch;
        display: block;
        flex: 1 1 auto;
        flex-flow: row wrap;
        padding: 60px;
        transform: scale(1);
        transition: 0.3s;
        width: auto;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        will-change: transform;
        transform-origin: center center;
        p {
          display: block;
          font-weight: bold;
          border: 0px;
          margin: 0px;
          flex: 1 1 auto;
          flex-direction: initial;
          overflow: hidden;
          color: rgb(255, 255, 255);
          line-height: 1.6;
        }
        .t {
          font-size: 54px;
        }
        .d {
          font-size: 32px;
        }
      }
      .area-info.active {
        transform: scale(1.1);
      }
    }
    .anchorBL {
      display: none !important;
    }
  }
}
.fs-baidu-map {
  .area-info {
    background-image: url(/images/mapmoduleimg5.png) !important;
  }
}
.nh-baidu-map {
  .area-info {
    background-image: url(/images/mapmoduleimg.png) !important;
  }
}
.org-baidu-map {
  .area-info {
    background-image: url(/images/mapmoduleimg4.png) !important;
  }
}
.fws-baidu-map {
  .area-info {
    background-image: url(/images/mapmoduleimg2.png) !important;
  }
}
.com-baidu-map {
  .area-info {
    background-image: url(/images/mapmoduleimg3.png) !important;
  }
}
.map-emergency-info {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 99 !important;
  border-radius: 30px;
  box-shadow: 0 0 20px rgb(0 0 0 / 30%);
  background: #fff;
  font-size: 36px;
  width: 1200px;
  .header {
    color: orange;
    font-size: 36px;
    background: #f2f2f2;
    padding: 36px;
  }
  .main {
    display: flex;
    justify-content: space-between;
    div {
      &:first-child {
        padding: 36px 0 36px 36px;
        width: 40%;
        img {
          width: 70%;
          display: block;
          margin: 0 auto 0;
        }
        .elder-info {
          background: #f2f2f2;
          display: flex;
          justify-content: space-around;
          padding: 18px;
          margin-top: 18px;
          width: 100%;
        }
        .create-time {
          margin-top: 18px;
          color: #777;
          text-align: center;
          width: 100%;
        }
      }
      &:last-child {
        width: 55%;
        padding: 36px 36px 36px 0;
        @bsize: 36px;
        div {
          border-bottom: 2px solid #f2f2f2;
          border-top: 2px solid transparent;
          padding: 18px 0 18px 66px;
          margin-bottom: 18px;
          width: 100%;
          line-height: 2;
          font-size: @bsize;
          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
          }
        }
        div.device {
          background: url(/images/device.png) no-repeat 6px @bsize;
          background-size: @bsize;
        }
        div.cellphone {
          background: url(/images/cellphone.png) no-repeat 6px @bsize;
          background-size: @bsize;
        }
        div.home {
          background: url(/images/home.png) no-repeat 6px @bsize;
          background-size: @bsize;
        }
        div.location {
          background: url(/images/location.png) no-repeat 6px @bsize;
          background-size: @bsize;
        }
      }
    }
  }
}
</style>
