<template>
  <div class="prAndFlexGrow">
    <EchartsLayout :option="option"></EchartsLayout>
    <div class="infomation">
      <span>能力完好 | 0</span>
      <span>轻度受损 | 0</span>
      <span>中度受损 | 0</span>
      <span>重度受损 | 0</span>
    </div>
  </div>
</template>

<script>
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  mounted() {
    // this.option = {
    //   legend: {},
    //   radar: {
    //     axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
    //     radius: "70%",
    //     shape: "polygon",
    //     splitArea: { areaStyle: { color: "transparent" } },
    //     splitLine: {
    //       lineStyle: {
    //         color: ["#6b6b6b", "#6b6b6b", "#6b6b6b", "#6b6b6b", "#6b6b6b", "#6b6b6b"],
    //         width: 1,
    //       },
    //     },
    //     name: { textStyle: { color: "#ffffff" } },
    //   },
    //   series: [
    //     {
    //       areaStyle: { normal: { color: "transparent" } },
    //       itemStyle: { normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 } },
    //       lineStyle: { normal: { width: 5 } },
    //       symbol: "circle",
    //       type: "radar",
    //     },
    //   ],
    //   tooltip: { textStyle: { fontSize: 30 } },
    //   polar: { radius: "70%", center: ["50%", "50%"] },
    //   angleAxis: {
    //     type: "category",
    //     clockwise: false,
    //     boundaryGap: false,
    //     splitLine: { show: false },
    //     axisLine: { show: false },
    //   },
    //   radiusAxis: {
    //     type: "value",
    //     max: 100,
    //     splitLine: { show: false },
    //     axisTick: { show: false },
    //     axisLabel: { show: false },
    //   },
    // };
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/less/infomation.less";
</style>
