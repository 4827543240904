<template>
  <fragment>
    <Title title="服务订单最多前5类" className="ny-title-small mt"></Title>
    <div class="layout-view fdr withoutTitle">
      <div class="layout-view wd50 mr10">
        <ServiceOrderTop5Month></ServiceOrderTop5Month>
      </div>
      <div class="layout-view wd50 ml10">
        <ServiceOrderTop5Category></ServiceOrderTop5Category>
      </div>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import ServiceOrderTop5Month from "@/components/home/ServiceOrderTop5Month.vue";
import ServiceOrderTop5Category from "@/components/home/ServiceOrderTop5Category.vue";
export default {
  components: {
    Title,
    ServiceOrderTop5Month,
    ServiceOrderTop5Category,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
