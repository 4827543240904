<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/nh/getParticipateList`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.divisionName, item.number]);
            });

          // 更新配置
          this.option = {
            // grid: { containLabel: true },
            series: [
              {
                name: "长者数",
                type: "bar",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 1, color: "RGBA(255, 137, 4, 1)" },
                      { offset: 0, color: "RGBA(255, 208, 42, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 200,
                },
                barWidth: "40",
                // barMaxWidth: "6%",
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
            ],
            title: { text: "累计参与活动长者数（单位：人）" },
            tooltip: {},
            xAxis: { type: "category" },
            yAxis: {},
            dataset: {
              source: [
                ["镇街", "累计参与活动长者数"],
                // ["桂城街道", 119320],
                // ["九江镇", 119320],
                // ["西樵镇", 119320],
                // ["丹灶镇", 119320],
                // ["狮山镇", 119320],
                // ["大沥镇", 119320],
                // ["里水镇", 119320],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
