import { render, staticRenderFns } from "./EmployeesSituationSubsidies.vue?vue&type=template&id=5bec8eac&scoped=true&"
import script from "./EmployeesSituationSubsidies.vue?vue&type=script&lang=js&"
export * from "./EmployeesSituationSubsidies.vue?vue&type=script&lang=js&"
import style0 from "./EmployeesSituationSubsidies.vue?vue&type=style&index=0&id=5bec8eac&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bec8eac",
  null
  
)

export default component.exports