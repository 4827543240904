<template>
  <div class="components-layout nanhaiyanglaotixineirong pd0">
    <Nav :dataArray="dataArray" :callBack="set_current_picture"></Nav>
    <div class="picture">
      <img :src="dataSource[currentPage]" />
    </div>
  </div>
</template>

<script>
import Nav from "@/components/common/Nav.vue";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      currentPage: 0,
      dataArray: [],
      dataSource: [],
    };
  },
  beforeMount() {
    let dataSource = [];
    for (let i = 1; i <= 15; i++) {
      dataSource.push(`/images/loop/${i}.jpg`);
    }
    this.dataSource = dataSource;

    this.dataArray = dataSource.map((item, index) => {
      return { title: index + 1 };
    });
  },
  methods: {
    set_current_picture(item) {
      this.currentPage = parseInt(item.title) - 1;
    },
  },
};
</script>

<style lang="less">
.nanhaiyanglaotixineirong {
  display: flex;
  justify-content: space-between !important;
  flex-direction: column;
  .picture {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    img {
      .setImg(contain);
    }
  }
}
</style>
