<template>
  <fragment>
    <div class="left-fixed">
      <div class="left-hover-btn">补贴长者 | {{ data.subsidyTotal }}</div>
      <div class="left-hover-btn">自费长者 | {{ data.ownTotal }}</div>
      <div class="left-hover-btn">慈善受捐长者 | {{ data.charitableTotal }}</div>
    </div>
    <EchartsLayout :option="option"></EchartsLayout>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
      data: {
        subsidyTotal: 0,
        ownTotal: 0,
        charitableTotal: 0,
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/home/getElderAnalysisListByPayType`,
        param: {
          idOrg: this.HomeId,
        },
        success: (response) => {
          let max = 0,
            elderAgeObj = {
              "60-70岁": 0,
              "70-80岁": 1,
              "80-90岁": 2,
              "90-100岁": 3,
              "100岁以上": 4,
            },
            indicatorArr = [
              { name: "60-70岁" },
              { name: "70-80岁" },
              { name: "80-90岁" },
              { name: "90-100岁" },
              { name: "100岁以上" },
            ],
            ownArr = [0, 0, 0, 0, 0],
            subsidyArr = [0, 0, 0, 0, 0],
            charitableArr = [0, 0, 0, 0, 0];

          response.data &&
            response.data.data &&
            response.data.data.ownList &&
            response.data.data.ownList.map((item) => {
              if (elderAgeObj[item.age] !== undefined) {
                if (item.number > max) {
                  max = item.number;
                }
                ownArr[elderAgeObj[item.age]] = item.number;
              }
            });

          response.data &&
            response.data.data &&
            response.data.data.subsidyList &&
            response.data.data.subsidyList.map((item) => {
              if (elderAgeObj[item.age] !== undefined) {
                if (item.number > max) {
                  max = item.number;
                }
                subsidyArr[elderAgeObj[item.age]] = item.number;
              }
            });

          response.data &&
            response.data.data &&
            response.data.data.charitableList &&
            response.data.data.charitableList.map((item) => {
              if (elderAgeObj[item.age] !== undefined) {
                if (item.number > max) {
                  max = item.number;
                }
                charitableArr[elderAgeObj[item.age]] = item.number;
              }
            });

          this.data = {
            // 补贴长者
            subsidyTotal: response.data.data.subsidyTotal || 0,
            // 自费长者
            ownTotal: response.data.data.ownTotal || 0,
            // 慈善受捐长者
            charitableTotal: response.data.data.charitableTotal || 0,
          };

          // 设置边界值
          indicatorArr = this.setAndicatorArrMax(indicatorArr, max);

          this.option = {
            legend: {
              show: true,
              height: "40%",
              width: "100%",
              itemWidth: 50,
              itemHeight: 50,
            },
            radar: {
              axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
              radius: "70%",
              shape: "polygon",
              splitArea: { areaStyle: { color: "transparent" } },
              splitLine: {
                lineStyle: {
                  color: [
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                  ],
                  width: 1,
                },
              },
              name: { textStyle: { color: "#ffffff" } },
              indicator: [
                // { max: 200, name: "60-69岁" },
                // { max: 200, name: "100岁以上" },
                // { max: 200, name: "90-99岁" },
                // { max: 200, name: "80-89岁" },
                // { max: 200, name: "70-79岁" },
                ...indicatorArr,
              ],
            },
            series: [
              // {
              //   type: "custom",
              //   name: "补贴账户",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              {
                areaStyle: { normal: { color: "transparent" } },
                itemStyle: {
                  normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 },
                },
                lineStyle: { normal: { width: 5 } },
                symbol: "circle",
                type: "radar",
                data: [
                  { value: [...subsidyArr], name: "补贴长者" },
                  { value: [...ownArr], name: "自费长者" },
                  { value: [...charitableArr], name: "慈善受捐长者" },
                ],
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            polar: { radius: "70%", center: ["50%", "50%"] },
            angleAxis: {
              type: "category",
              clockwise: false,
              boundaryGap: false,
              splitLine: { show: false },
              axisLine: { show: false },
            },
            radiusAxis: {
              type: "value",
              max: 100,
              splitLine: { show: false },
              axisTick: { show: false },
              axisLabel: { show: false },
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
