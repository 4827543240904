<template>
  <fragment>
    <Title title="社区幸福院运营情况排名前10家">
      <template v-slot:righter>
        <Select :callBack="set_current_year" :dataArray="yearSelecter"></Select>
      </template>
    </Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav">
      <component
        ref="child_component"
        :is="currentComponent"
        :current_year="current_year"
      ></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Select from "@/components/common/Select.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import CommunityHappinessOperationRankingCommunityElderNumber from "@/components/com/CommunityHappinessOperationRankingCommunityElderNumber.vue";
import CommunityHappinessOperationRankingReleaseActivityNumber from "@/components/com/CommunityHappinessOperationRankingReleaseActivityNumber.vue";
import CommunityHappinessOperationRankingActivityParticipantsNumber from "@/components/com/CommunityHappinessOperationRankingActivityParticipantsNumber.vue";
import CommunityHappinessOperationRankingEmployeesNumber from "@/components/com/CommunityHappinessOperationRankingEmployeesNumber.vue";
export default {
  components: {
    Title,
    Select,
    Nav,
    CommunityHappinessOperationRankingCommunityElderNumber,
    CommunityHappinessOperationRankingReleaseActivityNumber,
    CommunityHappinessOperationRankingActivityParticipantsNumber,
    CommunityHappinessOperationRankingEmployeesNumber,
  },
  mixins: [LayoutBase],
  data() {
    return {
      current_year: new Date().getFullYear(),
      components: [
        {
          key: "CommunityHappinessOperationRankingCommunityElderNumber",
          title: "社区长者总数",
        },
        {
          key: "CommunityHappinessOperationRankingReleaseActivityNumber",
          title: "发布活动总数",
        },
        {
          key: "CommunityHappinessOperationRankingActivityParticipantsNumber",
          title: "活动参加人数",
        },
        {
          key: "CommunityHappinessOperationRankingEmployeesNumber",
          title: "从业人员总数",
        },
      ],
    };
  },
  computed: {
    yearSelecter() {
      return this.$store.state.lastFourYears;
    },
  },
  methods: {
    // 设置当前年份
    set_current_year(current_year) {
      this.current_year = current_year;
      this.$nextTick(() => {
        this.$refs.child_component.get_component_data();
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
