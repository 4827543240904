<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
