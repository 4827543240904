<template>
  <fragment>
    <Title title="各镇街月度服务长者情况分析">
      <template v-slot:righter>
        <Select :callBack="set_current_town" :dataArray="townList"></Select>
      </template>
    </Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav pb0">
      <component
        :is="currentComponent"
        :current_town="current_town"
        ref="child_component"
      ></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Select from "@/components/common/Select.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import TownsStreetsMonthServiceElderSubsidy from "@/components/home/TownsStreetsMonthServiceElderSubsidy.vue";
import TownsStreetsMonthServiceElderSelfExpense from "@/components/home/TownsStreetsMonthServiceElderSelfExpense.vue";
import TownsStreetsMonthServiceElderCharityDonation from "@/components/home/TownsStreetsMonthServiceElderCharityDonation.vue";
export default {
  components: {
    Title,
    Select,
    Nav,
    TownsStreetsMonthServiceElderSubsidy,
    TownsStreetsMonthServiceElderSelfExpense,
    TownsStreetsMonthServiceElderCharityDonation,
  },
  mixins: [LayoutBase],
  data() {
    return {
      components: [
        {
          key: "TownsStreetsMonthServiceElderSubsidy",
          title: "按补贴情况",
        },
        {
          key: "TownsStreetsMonthServiceElderSelfExpense",
          title: "按自费情况",
        },
        {
          key: "TownsStreetsMonthServiceElderCharityDonation",
          title: "按慈善受捐情况",
        },
      ],
      current_town: this.$store.state.townList[0],
    };
  },
  computed: {
    townList() {
      return this.$store.state.townList;
    },
  },
  methods: {
    // 设置当前镇街
    set_current_town(current_town) {
      this.current_town = current_town;
      this.$nextTick(() => {
        this.$refs.child_component.get_component_data();
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
