<template>
  <div class="layout-view withoutNav pb0">
    <div class="layout-view hg50">
      <CumulativeActivityNumber></CumulativeActivityNumber>
    </div>
    <div class="layout-view hg50">
      <CumulativeActivityElderNumber></CumulativeActivityElderNumber>
    </div>
  </div>
</template>

<script>
import CumulativeActivityNumber from "@/components/nh/CumulativeActivityNumber.vue";
import CumulativeActivityElderNumber from "@/components/nh/CumulativeActivityElderNumber.vue";
export default {
  components: {
    CumulativeActivityNumber,
    CumulativeActivityElderNumber,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped></style>
