<template>
  <div class="components-layout niandupingbiqingkuangpaiming">
    <div class="table">
      <div class="tr">
        <span>排名</span>
        <span>机构名称</span>
        <span>镇街</span>
        <span>总分</span>
      </div>
      <div :id="`events-layout-${scrollId}`">
        <div class="content" :id="scrollId">
          <div
            v-for="(item, index) in dataSource"
            :key="index"
            :class="{ tr: true, active: currentCom.idOrg == item.idOrg }"
            @click.stop="set_current_com(item)"
          >
            <span>{{ index + 1 }}</span>
            <span>{{ item.orgName }}</span>
            <span>{{ item.divisionName }}</span>
            <span>{{ item.score }}</span>
          </div>
        </div>
      </div>
      <EmptyData v-if="isEmpty" />
    </div>
  </div>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EmptyData from "@/components/common/EmptyData.vue";

export default {
  components: { EmptyData },
  mixins: [ComponentBase],
  props: {
    set_current_xfy: {
      type: Function,
    },
    current_year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data() {
    return {
      scrollId: createUniqueKey(12),
      autoScrollObject: null,
      currentCom: {},
    };
  },
  methods: {
    set_current_com(currentCom) {
      this.currentCom = currentCom;
      this.set_current_xfy && this.set_current_xfy(currentCom);
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/com/getAssessmentTop?year=${this.current_year}`,
        success: (response) => {
          this.dataSource =
            response && response.data && response.data.data ? response.data.data : [];
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.niandupingbiqingkuangpaiming {
  align-items: flex-start;
  .table {
    color: rgb(255, 255, 255);
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
    .tr {
      display: flex;
      cursor: pointer;
      // &:first-child {
      //   border-top: 1px solid rgb(33, 154, 254);
      //   border-bottom: 1px solid rgb(33, 154, 254);
      // }
      span {
        font-size: 0.18rem;
        color: #fff;
        padding: 0.1rem 0;
        &:first-child {
          flex: 0 1 10%;
          width: 10%;
          text-align: center;
        }
        &:nth-child(2) {
          flex: 0 1 60%;
          width: 60%;
        }
        &:nth-child(3),
        &:nth-child(4) {
          flex: 0 1 15%;
          width: 15%;
        }
      }
    }
    .events-layout {
      flex-grow: 1;
      overflow: hidden;
    }
    .content {
      overflow-y: scroll;
      height: 100%;
      .tr {
        margin-bottom: 20px;
        border-radius: 20px;
        background: rgb(13, 32, 98);
      }
      .tr.active {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        background: transparent;
        // margin-top: 20px;
        span {
          color: rgb(0, 106, 255);
        }
      }
    }
  }
}
</style>
