<template>
  <fragment>
    <Title title="活动小组展示">
      <template v-slot:righter>
        <div class="nav-intruduce-span lh60 mr0">
          活动小组总数 | {{ dataSource.length }}
        </div>
      </template>
    </Title>
    <div class="layout-view withoutTitle pb0">
      <div class="components-layout">
        <div class="huodongxiaozuzhanshi">
          <div :id="`events-layout-${scrollId}`">
            <ul class="content-box" :id="scrollId">
              <li v-for="(item, index) in dataSource" :key="index">
                <div class="content-picture-video">
                  <div class="picture">
                    <img :src="`${imgUrl}${item.groupsPhoto}`" />
                  </div>
                  <!-- <div class="video">
                    <div class="video-play">
                      <span>播放</span><span>视频</span>
                    </div>
                  </div> -->
                </div>
                <div class="content-info">
                  <p>{{ item.groupsName }}</p>
                  <p>成立时间：{{ item.foundedDate }}</p>
                  <p>小组人数：{{ item.memberTotalNum }}</p>
                  <p>小组队长：{{ item.leaderName }}</p>
                  <p>小组介绍：{{ item.groupsDescription }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <EmptyData v-if="isEmpty" />
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";
import { imgUrl } from "@/utils/config.js";

export default {
  components: { Title, EmptyData },
  mixins: [ComponentBase],
  data() {
    return {
      imgUrl,
      scrollId: createUniqueKey(12),
      autoScrollObject: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getGroupsList?id_org=${this.ComId}`,
        param: {
          idOrg: this.ComId,
          groupsCategory: "其他",
        },
        success: (response) => {
          this.dataSource =
            response && response.data && response.data.data ? response.data.data : [];
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/less/content_box.less";
.huodongxiaozuzhanshi {
  width: 100%;
  height: 100%;
}
</style>
