<template>
  <!-- 居家服务商主窗体内容 -->
  <div class="layout-main home-service">
    <!-- 主窗体左侧 -->
    <div class="layout-main-left">
      <div class="layout-view" @dblclick="setMiddleCoverComponent('HomeServiceOverview')">
        <HomeServiceOverview></HomeServiceOverview>
      </div>
      <div
        class="layout-view"
        @dblclick="setMiddleCoverComponent('HomeServiceOperationRanking')"
      >
        <HomeServiceOperationRanking></HomeServiceOperationRanking>
      </div>
      <div class="layout-view" @dblclick="setMiddleCoverComponent('HomeServiceType')">
        <HomeServiceType></HomeServiceType>
      </div>
    </div>
    <!-- 主窗体左侧 -->

    <!-- 主窗体中部 -->
    <div class="layout-main-middle">
      <HomeServiceMap></HomeServiceMap>
      <div class="layout-view abs" v-if="middleCoverComponent">
        <span class="close" @click="closeCoverComponent"></span>
        <component :is="middleCoverComponent"></component>
      </div>
    </div>
    <!-- 主窗体中部 -->

    <!-- 主窗体右侧第一屏 -->
    <div v-if="currentRightPage == 1" class="layout-main-right">
      <!-- 第四列 -->
      <div class="layout-view wd50 mr10">
        <!-- 4-1 -->
        <div class="layout-view">
          <div class="layout-view">
            <div
              class="layout-view hg35"
              @dblclick="setMiddleCoverComponent('HomeServiceProcess')"
            >
              <HomeServiceProcess></HomeServiceProcess>
            </div>
            <div
              class="layout-view hg65"
              @dblclick="setMiddleCoverComponent('ServiceOrderTop5')"
            >
              <ServiceOrderTop5></ServiceOrderTop5>
            </div>
          </div>
        </div>
        <!-- 4-1 -->
        <!-- 4-2 -->
        <div class="layout-view" @dblclick="setMiddleCoverComponent('LatestOrders')">
          <LatestOrders></LatestOrders>
        </div>
        <!-- 4-2 -->
        <!-- 4-3 -->
        <div class="layout-view" @dblclick="setMiddleCoverComponent('ServiceStatistics')">
          <ServiceStatistics></ServiceStatistics>
        </div>
        <!-- 4-3 -->
      </div>
      <!-- 第四列 -->
      <!-- 第五列 -->
      <div class="layout-view wd50 ml10">
        <!-- 5-1 + 5-2 -->
        <div class="layout-view bd br20 pd20 fwlx">
          <div class="layout-view">
            <div @dblclick="setMiddleCoverComponent('ServiceTypePDF')">
              <Title title="服务类型"></Title>
            </div>
            <div class="layout-view withoutTitle">
              <ServiceTypePDF></ServiceTypePDF>
            </div>
          </div>
          <div
            class="layout-view"
            @dblclick="setMiddleCoverComponent('SecondaryServiceType')"
          >
            <SecondaryServiceType></SecondaryServiceType>
          </div>
        </div>
        <!-- 5-1 + 5-2 -->

        <!-- 5-3 -->
        <div
          class="layout-view gzjfwddbl"
          @dblclick="setMiddleCoverComponent('TownsStreetsServiceOrderProportion')"
        >
          <TownsStreetsServiceOrderProportion></TownsStreetsServiceOrderProportion>
        </div>
        <!-- 5-3 -->
      </div>
      <!-- 第五列 -->
    </div>
    <!-- 主窗体右侧第一屏 -->

    <!-- 主窗体右侧第二屏 -->
    <div v-if="currentRightPage == 2" class="layout-main-right">
      <!-- 第四列 -->
      <div class="layout-view wd50 mr10">
        <!-- 4-1 -->
        <div class="layout-view">
          <Title title="特色服务"></Title>
          <div class="layout-view withoutTitle">
            <CharacteristicService></CharacteristicService>
          </div>
        </div>
        <!-- 4-1 -->
        <!-- 4-2 -->
        <div
          class="layout-view"
          @dblclick="setMiddleCoverComponent('ServiceOrderingChannels')"
        >
          <ServiceOrderingChannels></ServiceOrderingChannels>
        </div>
        <!-- 4-2 -->
        <!-- 4-3 -->
        <div
          class="layout-view"
          @dblclick="setMiddleCoverComponent('TownsStreetsMonthServiceElder')"
        >
          <TownsStreetsMonthServiceElder></TownsStreetsMonthServiceElder>
        </div>
        <!-- 4-3 -->
      </div>
      <!-- 第四列 -->
      <!-- 第五列 -->
      <div class="layout-view wd50 ml10">
        <!-- 5-1 + 5-2 -->
        <div class="layout-view bd br20 pd20 sos">
          <div class="layout-view" @dblclick="setMiddleCoverComponent('RegionalSOS')">
            <RegionalSOS></RegionalSOS>
          </div>
          <div
            class="layout-view"
            @dblclick="setMiddleCoverComponent('CallStatisticsByTimePeriod')"
          >
            <CallStatisticsByTimePeriod></CallStatisticsByTimePeriod>
          </div>
        </div>
        <!-- 5-1 + 5-2 -->

        <!-- 5-3 -->
        <div
          class="layout-view csxm"
          @dblclick="setMiddleCoverComponent('CharityProjects')"
        >
          <CharityProjects></CharityProjects>
        </div>
        <!-- 5-3 -->
      </div>
      <!-- 第五列 -->
    </div>
    <!-- 主窗体右侧第二屏 -->

    <!-- 主窗体右侧第三屏 -->
    <div v-if="currentRightPage == 3" class="layout-main-right">
      <!-- 第四列 -->
      <div class="layout-view wd50 mr10">
        <!-- 4-1 -->
        <div
          class="layout-view pb20"
          @dblclick="setMiddleCoverComponent('HomeServicePicture')"
        >
          <HomeServicePicture></HomeServicePicture>
        </div>
        <!-- 4-1 -->
        <!-- 4-2 -->
        <div class="layout-view" @dblclick="setMiddleCoverComponent('ServiceElder')">
          <ServiceElder></ServiceElder>
        </div>
        <!-- 4-2 -->
        <!-- 4-3 -->
        <div
          class="layout-view"
          @dblclick="setMiddleCoverComponent('ServiceProductList')"
        >
          <ServiceProductList></ServiceProductList>
        </div>
        <!-- 4-3 -->
      </div>
      <!-- 第四列 -->
      <!-- 第五列 -->
      <div class="layout-view wd50 ml10">
        <!-- 5-1 -->
        <div class="layout-view">
          <div class="layout-view fdr">
            <div
              class="layout-view wd50 pb20 mr10"
              @dblclick="setMiddleCoverComponent('HomeServiceIntroduce')"
            >
              <HomeServiceIntroduce></HomeServiceIntroduce>
            </div>
            <div
              class="layout-view wd50 ml10"
              @dblclick="setMiddleCoverComponent('ServiceType')"
            >
              <ServiceType></ServiceType>
            </div>
          </div>
        </div>
        <!-- 5-1 -->
        <!-- 5-2 -->
        <div class="layout-view" @dblclick="setMiddleCoverComponent('Employees')">
          <Employees></Employees>
        </div>
        <!-- 5-2 -->
        <!-- 5-3 -->
        <div
          class="layout-view"
          @dblclick="setMiddleCoverComponent('ExcellentEmployeesDisplay')"
        >
          <ExcellentEmployeesDisplay></ExcellentEmployeesDisplay>
        </div>
        <!-- 5-3 -->
      </div>
      <!-- 第五列 -->
    </div>
    <!-- 主窗体右侧第三屏 -->
  </div>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import HomeServiceMap from "@/components/home/HomeServiceMap.vue";
import HomeServiceOverview from "@/components/home/HomeServiceOverview.vue";

import HomeServiceOperationRanking from "@/components/home/HomeServiceOperationRanking.vue";
import HomeServiceOperationRankingServiceElderNumber from "@/components/home/HomeServiceOperationRankingServiceElderNumber.vue";
import HomeServiceOperationRankingServiceOrderNumber from "@/components/home/HomeServiceOperationRankingServiceOrderNumber.vue";
import HomeServiceOperationRankingEmployeesNumber from "@/components/home/HomeServiceOperationRankingEmployeesNumber.vue";
import HomeServiceOperationRankingServiceTotalFee from "@/components/home/HomeServiceOperationRankingServiceTotalFee.vue";

import HomeServiceType from "@/components/home/HomeServiceType.vue";
import HomeServiceTypeServiceElderNumber from "@/components/home/HomeServiceTypeServiceElderNumber.vue";
import HomeServiceTypeServiceOrderNumber from "@/components/home/HomeServiceTypeServiceOrderNumber.vue";
import HomeServiceTypeEmployeesNumber from "@/components/home/HomeServiceTypeEmployeesNumber.vue";
import HomeServiceTypeServiceTotalFee from "@/components/home/HomeServiceTypeServiceTotalFee.vue";

import HomeServiceProcess from "@/components/home/HomeServiceProcess.vue";

import ServiceOrderTop5 from "@/components/home/ServiceOrderTop5.vue";
import ServiceOrderTop5Month from "@/components/home/ServiceOrderTop5Month.vue";
import ServiceOrderTop5Category from "@/components/home/ServiceOrderTop5Category.vue";

import LatestOrders from "@/components/home/LatestOrders.vue";
import ServiceStatistics from "@/components/home/ServiceStatistics.vue";
import ServiceTypePDF from "@/components/home/ServiceTypePDF.vue";
import SecondaryServiceType from "@/components/home/SecondaryServiceType.vue";
import TownsStreetsServiceOrderProportion from "@/components/home/TownsStreetsServiceOrderProportion.vue";
import CharacteristicService from "@/components/home/CharacteristicService.vue";
import ServiceOrderingChannels from "@/components/home/ServiceOrderingChannels.vue";

import TownsStreetsMonthServiceElder from "@/components/home/TownsStreetsMonthServiceElder.vue";
import TownsStreetsMonthServiceElderSubsidy from "@/components/home/TownsStreetsMonthServiceElderSubsidy.vue";
import TownsStreetsMonthServiceElderSelfExpense from "@/components/home/TownsStreetsMonthServiceElderSelfExpense.vue";
import TownsStreetsMonthServiceElderCharityDonation from "@/components/home/TownsStreetsMonthServiceElderCharityDonation.vue";

import RegionalSOS from "@/components/home/RegionalSOS.vue";

import CallStatisticsByTimePeriod from "@/components/home/CallStatisticsByTimePeriod.vue";
import CharityProjects from "@/components/home/CharityProjects.vue";
import HomeServicePicture from "@/components/home/HomeServicePicture.vue";

import ServiceElder from "@/components/home/ServiceElder.vue";
import ServiceElderByAge from "@/components/home/ServiceElderByAge.vue";
import ServiceElderByGender from "@/components/home/ServiceElderByGender.vue";
import ServiceElderByAbility from "@/components/home/ServiceElderByAbility.vue";
import ServiceElderByPayType from "@/components/home/ServiceElderByPayType.vue";

import ServiceProductList from "@/components/home/ServiceProductList.vue";
import HomeServiceIntroduce from "@/components/home/HomeServiceIntroduce.vue";
import ServiceType from "@/components/home/ServiceType.vue";

import Employees from "@/components/home/Employees.vue";
import EmployeesByAge from "@/components/home/EmployeesByAge.vue";
import EmployeesByGender from "@/components/home/EmployeesByGender.vue";
import EmployeesByPost from "@/components/home/EmployeesByPost.vue";
import EmployeesByEducation from "@/components/home/EmployeesByEducation.vue";

import ExcellentEmployeesDisplay from "@/components/home/ExcellentEmployeesDisplay.vue";

import OverviewBase from "@/views/mixin/OverviewBase.vue";
import BSIframe from "@/components/home/BSIframe.vue";

export default {
  components: {
    Title,
    Nav,
    HomeServiceMap,

    HomeServiceOverview,

    HomeServiceOperationRanking,
    HomeServiceOperationRankingServiceElderNumber,
    HomeServiceOperationRankingServiceOrderNumber,
    HomeServiceOperationRankingEmployeesNumber,
    HomeServiceOperationRankingServiceTotalFee,

    HomeServiceType,
    HomeServiceTypeServiceElderNumber,
    HomeServiceTypeServiceOrderNumber,
    HomeServiceTypeEmployeesNumber,
    HomeServiceTypeServiceTotalFee,

    HomeServiceProcess,

    ServiceOrderTop5,
    ServiceOrderTop5Month,
    ServiceOrderTop5Category,

    LatestOrders,
    ServiceStatistics,
    ServiceTypePDF,
    SecondaryServiceType,
    TownsStreetsServiceOrderProportion,
    CharacteristicService,
    ServiceOrderingChannels,

    TownsStreetsMonthServiceElder,
    TownsStreetsMonthServiceElderSubsidy,
    TownsStreetsMonthServiceElderSelfExpense,
    TownsStreetsMonthServiceElderCharityDonation,

    RegionalSOS,
    CallStatisticsByTimePeriod,
    CharityProjects,
    HomeServicePicture,

    ServiceElder,
    ServiceElderByAge,
    ServiceElderByGender,
    ServiceElderByAbility,
    ServiceElderByPayType,

    ServiceProductList,
    HomeServiceIntroduce,
    ServiceType,

    Employees,
    EmployeesByAge,
    EmployeesByGender,
    EmployeesByPost,
    EmployeesByEducation,
    ExcellentEmployeesDisplay,

    BSIframe,
  },
  mixins: [OverviewBase],
};
</script>

<style lang="less" scoped>
.layout-main.home-service {
  .layout-view.fwlx,
  .layout-view.sos {
    height: 1950px !important;
    // 现场会修改2会影响原来的
    // height: 66.6666% !important;
    margin-bottom: 20px;
    > .layout-view {
      &:first-child {
        height: 847px !important;
        // 现场会修改2会影响原来的
        // height: 50% !important;
      }
      &:last-child {
        flex-grow: 1;
        height: 0;
      }
    }
  }
  .layout-view.gzjfwddbl,
  .layout-view.csxm {
    height: 1050px !important;
    // 现场会修改2会影响原来的
    // height: 33.3333% !important;
  }
}
</style>
