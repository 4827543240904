<template>
  <fragment>
    <Title title="长者膳食介绍">
      <!-- <template v-slot:righter>
        <div style="display: flex; justify-content: flex-end">
          <div class="nav-intruduce-span lh60">上周膳食服务长者数 | 0</div>
          <div class="nav-intruduce-span lh60 mr0">上周盈利额 | 0</div>
        </div>
      </template> -->
    </Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout">
        <div class="zhangzheshanshijieshao">
          <table border="1" cellspacing="0">
            <tr>
              <th>食谱</th>
              <th>周一</th>
              <th>周二</th>
              <th>周三</th>
              <th>周四</th>
              <th>周五</th>
              <th>周六</th>
              <th>周日</th>
            </tr>
            <tr v-for="(item, index) in dataSource" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.monday }}</td>
              <td>{{ item.tuesday }}</td>
              <td>{{ item.wednesday }}</td>
              <td>{{ item.thursday }}</td>
              <td>{{ item.friday }}</td>
              <td>{{ item.saturday }}</td>
              <td>{{ item.sunday }}</td>
            </tr>
            <tr v-if="isEmpty">
              <td colspan="8" :style="{ height: '85%', position: 'relative' }">
                <EmptyData />
              </td>
            </tr>
          </table>
          <div class="zzss-content">
            <div :id="`events-layout-${scrollId}`">
              <ul class="zzss-ul" :id="scrollId">
                <li v-for="(item, index) in foodPictureList" :key="index">
                  <div class="picture">
                    <img :src="item" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";

export default {
  components: { Title, EmptyData },
  mixins: [ComponentBase],
  data() {
    return {
      scrollId: createUniqueKey(12),
      scrollDirection: "scrollLeft",
      dataSource: [],
      foodPictureList: [],
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getElderMealsList`,
        param: {
          idOrg: this.ComId,
        },
        success: (response) => {
          this.dataSource =
            response && response.data && response.data.data ? response.data.data : [];
        },
      });
    },
  },
  mounted() {
    let foodPictureList = [];
    for (let i = 1; i < 7; i++) {
      foodPictureList.push(`/images/food/${i}.jpg`);
    }
    this.foodPictureList = foodPictureList;
  },
};
</script>

<style lang="less" scoped>
.zhangzheshanshijieshao {
  width: 100%;
  height: 100%;
  flex-direction: column;
  table {
    width: 100%;
    height: 70%;
    tr {
      th,
      td {
        border: 1px solid @mainBlue;
        color: #fff;
        font-size: 0.18rem;
        text-align: center;
        width: 12.5%;
      }
    }
  }
  .zzss-content {
    flex-grow: 1;
    height: 30%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    [id^="events-layout-"] {
      width: 100%;
      height: unset;
      display: flex;
      align-items: flex-end;
    }
    .zzss-ul {
      display: flex;
      overflow-x: scroll;
      width: 100%;
      // pointer-events: none;
      li {
        width: 20%;
        margin-right: 2%;
        flex-shrink: 0;
        .picture {
          width: 100%;
          padding-top: 62%;
          position: relative;
          img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        p {
          border-radius: 0.3rem;
          border: 0.03rem solid #219afe;
          font-weight: 700;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.18rem;
          margin-top: 0.1rem;
          padding: 0.05rem;
        }
      }
    }
  }
}
</style>
