<template>
  <Map
    v-if="areaData.length > 0"
    :config="config"
    :areaData="areaData"
    :renderAreaData="renderAreaData"
    mapType="养老机构"
    className="org-baidu-map"
  ></Map>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import Map from "@/components/common/Map.vue";
import MapBase from "@/components/mixin/MapBase.vue";
import ComponentBase from "@/components/mixin/ComponentBase.vue";

export default {
  mixins: [MapBase, ComponentBase],
  components: { Map },
  data() {
    return {
      areaData: [],
    };
  },
  methods: {
    renderAreaData(item, index) {
      return `
        <div class="area-info area-info-${index}">
            <p class="t">${item.divisionName}</p>
            <p class="d">养老机构：${item.allOrgCount}（${item.orgProportion}）</p>
            <p class="d">床位数：${item.bedNum}（${item.bedProportion}）</p>
            <p class="d">入住长者数：${item.residentNum}（${item.checkProportion}）</p>
        </div>
      `;
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `org/getOrgNumByTown`,
        success: (response) => {
          let areaData = [];
          response &&
            response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              areaData.push({
                divisionName: item.divisionName,
                allOrgCount: item.allOrgCount || 0,
                orgProportion: item.orgProportion || "0%",
                bedNum: item.bedNum || 0,
                bedProportion: item.bedProportion || "0%",
                residentNum: item.residentNum || 0,
                checkProportion: item.checkProportion || "0%",
              });
            });
          this.areaData = areaData;
        },
      });
    },
  },
};
</script>
<style lang="less"></style>
