import axios from 'axios';
// 公共配置
import { baseUrl } from "@/utils/config.js";
import { getQueryVariable } from "@/utils/index.js";
// import qs from 'qs';

// 根域名
axios.defaults.baseURL = `https://${baseUrl}`;
// 拦截器
axios.interceptors.request.use(
    config => {
        config.headers.Authorization = `Bearer ${getQueryVariable('token')}`;
        // if (config.method === 'post') {
        //     config.headers['Content-Type'] = `application/x-www-form-urlencoded`;
        //     config.data = qs.stringify(config.data);
        // }
        return config;
    },
    function (err) {
        console.error(`【Axios失败信息】【${err}】`);
    }
);

export default axios;

// 封装的axios
export function request(config = {}) {
    axios[config.method](config.url || '', config.param || {})
        .then((response) => {
            // 完成时执行函数
            config.done && config.done();
            if (response) {
                const response_data = response.data;
                if (response_data && response_data.isOk == 'Y') {
                    // 后端成功时执行函数
                    config.success && config.success(response_data);
                } else {
                    // 后端失败时执行函数
                    config.fail && config.fail(response_data);
                }
            } else {
                // 后端失败时执行函数
                config.fail && config.fail();
            }
        })
        .catch(function (error) {
            console.error(error);
            // 后端报错时执行函数
            config.error && config.error(error);
        });
}