<template>
  <div class="fuwuleixing-pdf">
    <div class="fuwuleixing-pdf-main">
      <Pdf :url="url"></Pdf>
    </div>
  </div>
</template>

<script>
import Pdf from "@/components/common/Pdf.vue";
export default {
  components: { Pdf },
  data() {
    return {
      url: "/pdf/fwlx.pdf",
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.fuwuleixing-pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.layout-main-middle {
  .fuwuleixing-pdf-main {
    width: 3700px;
  }
}
.layout-main-right {
  .fuwuleixing-pdf-main {
    width: 1300px;
  }
}
</style>
