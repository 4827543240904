<template>
  <div class="components-layout nh-right-pure-pic pd0">
    <div class="title"></div>
    <div class="picture">
      <img :src="picture" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picture: "/images/nanhaifuwushujuguanliju.jpg",
    };
  },
  methods: {},
  mounted() {},
};
</script>
