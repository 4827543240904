<template>
  <fragment>
    <Title title="从业人员情况分析">
      <template v-slot:righter>
        <div class="nav-intruduce-span lh60 mr0">
          从业人员总数 | {{ employeesNum || 0 }}
        </div>
      </template>
    </Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav fdr">
      <div class="layout-view wd50 mr10">
        <component
          :set_employees_num="set_employees_num"
          :is="currentComponentLeft"
        ></component>
      </div>
      <div class="layout-view wd50 ml10">
        <component :is="currentComponentRight"></component>
      </div>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import EmployeesByAge from "@/components/home/EmployeesByAge.vue";
import EmployeesByGender from "@/components/home/EmployeesByGender.vue";
import EmployeesByPost from "@/components/home/EmployeesByPost.vue";
import EmployeesByEducation from "@/components/home/EmployeesByEducation.vue";
export default {
  components: {
    Title,
    Nav,
    EmployeesByAge,
    EmployeesByGender,
    EmployeesByPost,
    EmployeesByEducation,
  },
  mixins: [LayoutBase],
  data() {
    return {
      employeesNum: 0,
      currentComponentLeft: "",
      currentComponentRight: "",
      components: [
        {
          key: "基本情况",
          title: "基本情况",
        },
        {
          key: "学历经历",
          title: "学历经历",
        },
      ],
    };
  },
  methods: {
    callBack(data) {
      if (data.key == "基本情况") {
        this.currentComponentLeft = "EmployeesByAge";
        this.currentComponentRight = "EmployeesByGender";
      } else if (data.key == "学历经历") {
        this.currentComponentLeft = "EmployeesByPost";
        this.currentComponentRight = "EmployeesByEducation";
      }
    },
    set_employees_num(employeesNum) {
      this.employeesNum = employeesNum;
    },
  },
  beforeMount() {
    this.currentComponentLeft = "EmployeesByAge";
    this.currentComponentRight = "EmployeesByGender";
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
