<template>
  <fragment>
    <Title title="入住长者情况分析">
      <template v-slot:righter>
        <div class="nav-intruduce-span lh60 mr0">总长者数 | {{ elderNum || 0 }}</div>
      </template>
    </Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav">
      <component :is="currentComponent" :set_elder_num="set_elder_num"></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import ElderResidentsByAge from "@/components/org/ElderResidentsByAge.vue";
import ElderResidentsByGender from "@/components/org/ElderResidentsByGender.vue";
import ElderResidentsByHouseholdRegister from "@/components/org/ElderResidentsByHouseholdRegister.vue";
export default {
  components: {
    Title,
    Nav,
    ElderResidentsByAge,
    ElderResidentsByGender,
    ElderResidentsByHouseholdRegister,
  },
  mixins: [LayoutBase],
  data() {
    return {
      elderNum: 0,
      components: [
        {
          key: "ElderResidentsByAge",
          type: "age",
          title: "按年龄段",
        },
        {
          key: "ElderResidentsByGender",
          type: "sex",
          title: "按性别",
        },
        {
          key: "ElderResidentsByHouseholdRegister",
          type: "household",
          title: "按户籍",
        },
      ],
    };
  },
  methods: {
    set_elder_num(elderNum) {
      this.elderNum = elderNum;
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
