<template>
  <fragment>
    <Title title="长者健康指数分析"></Title>
    <div class="layout-view withoutTitle pb0">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {},
  mounted() {
    this.option = {
      grid: { containLabel: true },
      series: [
        {
          type: "bar",
          stack: "数值",
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                { offset: 1, color: "RGBA(250, 71, 165, 1)" },
                { offset: 0, color: "RGBA(254, 81, 82, 1)" },
              ],
              globalCoord: false,
            },
            borderRadius: 20,
          },
          barWidth: 40,
          barMinHeight: 10,
          zlevel: 5,
        },
        {
          type: "bar",
          stack: "数值",
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                { offset: 1, color: "RGBA(43, 194, 248, 1)" },
                { offset: 0, color: "RGBA(17, 142, 225, 1)" },
              ],
              globalCoord: false,
            },
            borderRadius: [0, 50, 50, 0],
            shadowBlur: 0,
            shadowColor: "RGBA(17, 142, 225, 1)",
            shadowOffsetX: -20,
          },
          barWidth: 40,
          barMinHeight: 10,
          zlevel: 4,
        },
        {
          type: "bar",
          stack: "数值",
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                { offset: 1, color: "RGBA(189, 225, 1, 1)" },
                { offset: 0, color: "RGBA(156, 214, 1, 1)" },
              ],
              globalCoord: false,
            },
            borderRadius: [0, 50, 50, 0],
            shadowBlur: 0,
            shadowColor: "RGBA(156, 214, 1, 1)",
            shadowOffsetX: -20,
          },
          barWidth: 40,
          barMinHeight: 10,
          zlevel: 3,
        },
        {
          type: "bar",
          stack: "数值",
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                { offset: 1, color: "RGBA(255, 207, 43, 1)" },
                { offset: 0, color: "RGBA(254, 191, 33, 1)" },
              ],
              globalCoord: false,
            },
            borderRadius: [0, 50, 50, 0],
            shadowBlur: 0,
            shadowColor: "RGBA(254, 191, 33, 1)",
            shadowOffsetX: -20,
          },
          barWidth: 40,
          barMinHeight: 10,
          zlevel: 2,
        },
      ],
      title: { text: "单位：人" },
      tooltip: { textStyle: { fontSize: 30 } },
      xAxis: {},
      yAxis: { type: "category", inverse: true },
      legend: { show: true },
      dataset: { source: [] },
    };
  },
};
</script>

<style lang="less" scoped></style>
