<template>
  <fragment>
    <Title title="按年龄段分析" className="ny-title-small"></Title>
    <div class="layout-view withoutTitle fdr">
      <div class="layout-view mr10 wd50">
        <PlatformElderByElderNumber></PlatformElderByElderNumber>
      </div>
      <div class="layout-view l20 wd50">
        <PlatformElderByGender></PlatformElderByGender>
      </div>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import PlatformElderByElderNumber from "@/components/nh/PlatformElderByElderNumber.vue";
import PlatformElderByGender from "@/components/nh/PlatformElderByGender.vue";
export default {
  components: {
    Title,
    PlatformElderByElderNumber,
    PlatformElderByGender,
  },
  data() {
    return {};
  },
  methods: {},
  beforeMount() {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
