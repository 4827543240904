<template>
  <!-- 机构养老主窗体内容 -->
  <div :class="`layout-main layout-main-${currentRightPage} institutional-endowment`">
    <!-- 主窗体左侧 -->
    <div class="layout-main-left">
      <div
        class="layout-view"
        @dblclick="setMiddleCoverComponent('WelfareCentreOverview')"
      >
        <WelfareCentreOverview></WelfareCentreOverview>
      </div>
      <div
        class="layout-view"
        @dblclick="setMiddleCoverComponent('WelfareCentreOperation')"
      >
        <WelfareCentreOperation></WelfareCentreOperation>
      </div>
      <div class="layout-view" @dblclick="setMiddleCoverComponent('WelfareCentreNature')">
        <WelfareCentreNature></WelfareCentreNature>
      </div>
    </div>
    <!-- 主窗体左侧 -->

    <!-- 主窗体中部 -->
    <div class="layout-main-middle">
      <WelfareCentreMap></WelfareCentreMap>
      <div class="layout-view abs" v-if="middleCoverComponent">
        <span class="close" @click="closeCoverComponent"></span>
        <component :is="middleCoverComponent"></component>
      </div>
    </div>
    <!-- 主窗体中部 -->

    <!-- 主窗体右侧第一屏 -->
    <div v-if="currentRightPage == 1" class="layout-main-right">
      <!-- 第四列 -->
      <div class="layout-view wd50 mr10">
        <!-- 4-1 -->
        <div class="layout-view">
          <Title title="机构图片介绍"></Title>
          <div class="layout-view withoutTitle">
            <WelfareCentrePictureIntroduction></WelfareCentrePictureIntroduction>
          </div>
        </div>
        <!-- 4-1 -->
        <!-- 4-2 -->
        <div class="layout-view" @dblclick="setMiddleCoverComponent('TodayBedOverview')">
          <TodayBedOverview></TodayBedOverview>
        </div>
        <!-- 4-2 -->
        <!-- 4-3 -->
        <div class="layout-view">
          <Title title="安全监控"></Title>
          <div class="layout-view withoutTitle pb0">
            <div class="layout-view">
              <div class="layout-view hg50">
                <SafetyMonitoringe type="消防安全"></SafetyMonitoringe>
              </div>
              <div class="layout-view hg50">
                <SafetyMonitoringe type="食品安全"></SafetyMonitoringe>
              </div>
            </div>
          </div>
        </div>
        <!-- 4-3 -->
      </div>
      <!-- 第四列 -->
      <!-- 第五列 -->
      <div class="layout-view wd50 ml10">
        <!-- 5-1 -->
        <div class="layout-view">
          <div class="layout-view fdr">
            <div
              class="layout-view wd50 mr10"
              @dblclick="setMiddleCoverComponent('WelfareCentreIntroduce')"
            >
              <WelfareCentreIntroduce></WelfareCentreIntroduce>
            </div>
            <div
              class="layout-view wd50 ml10"
              @dblclick="setMiddleCoverComponent('LeaderIntroduce')"
            >
              <LeaderIntroduce></LeaderIntroduce>
            </div>
          </div>
        </div>
        <!-- 5-1 -->
        <!-- 5-2 -->
        <div class="layout-view" @dblclick="setMiddleCoverComponent('ElderResidents')">
          <ElderResidents></ElderResidents>
        </div>
        <!-- 5-2 -->
        <!-- 5-3 -->
        <div class="layout-view">
          <div class="layout-view fdr">
            <div
              class="layout-view wd50 mr10"
              @dblclick="setMiddleCoverComponent('ElderResidentsByNursingLevel')"
            >
              <ElderResidentsByNursingLevel></ElderResidentsByNursingLevel>
            </div>
            <div
              class="layout-view wd50 ml10"
              @dblclick="setMiddleCoverComponent('ElderResidentsBySubsidiesType')"
            >
              <ElderResidentsBySubsidiesType></ElderResidentsBySubsidiesType>
            </div>
          </div>
        </div>
        <!-- 5-3 -->
      </div>
      <!-- 第五列 -->
    </div>
    <!-- 主窗体右侧第一屏 -->

    <!-- 主窗体右侧第二屏 -->
    <div v-if="currentRightPage == 2" class="layout-main-right">
      <!-- 第四列 -->
      <div class="layout-view wd50 mr10">
        <!-- 4-1 -->
        <div
          class="layout-view"
          @dblclick="setMiddleCoverComponent('DailyActivitiesAndNews')"
        >
          <DailyActivitiesAndNews></DailyActivitiesAndNews>
        </div>
        <!-- 4-1 -->
        <!-- 4-2 + 4-3 -->
        <div class="layout-view bd br20 pd20">
          <div class="layout-view hg50">
            <Title title="从业人员情况分析">
              <template v-slot:righter>
                <div class="nav-intruduce-span lh60 mr0">
                  从业人员总数 | {{ employeesNum || 0 }}
                </div>
              </template>
            </Title>
            <div class="layout-view withoutTitle fdr">
              <div
                class="layout-view wd50 mr10"
                @dblclick="setMiddleCoverComponent('EmployeesSituationByAge')"
              >
                <EmployeesSituationByAge
                  :set_employees_num="set_employees_num"
                ></EmployeesSituationByAge>
              </div>
              <div
                class="layout-view wd50 ml10"
                @dblclick="setMiddleCoverComponent('EmployeesSituationByGender')"
              >
                <EmployeesSituationByGender></EmployeesSituationByGender>
              </div>
            </div>
          </div>
          <div class="layout-view hg50">
            <div class="layout-view fdr">
              <div
                class="layout-view wd50 mr10"
                @dblclick="setMiddleCoverComponent('EmployeesSituationByPostType')"
              >
                <EmployeesSituationByPostType></EmployeesSituationByPostType>
              </div>
              <div
                class="layout-view wd50 ml10"
                @dblclick="setMiddleCoverComponent('EmployeesSituationByEducation')"
              >
                <EmployeesSituationByEducation></EmployeesSituationByEducation>
              </div>
            </div>
          </div>
        </div>
        <!-- 4-2 + 4-3 -->
      </div>
      <!-- 第四列 -->
      <!-- 第五列 -->
      <div class="layout-view wd50 ml10">
        <!-- 5-1 -->
        <div class="layout-view">
          <div class="layout-view fdr">
            <div
              class="layout-view wd50 mr10"
              @dblclick="setMiddleCoverComponent('ServiceChargesStandard')"
            >
              <ServiceChargesStandard></ServiceChargesStandard>
            </div>
            <div
              class="layout-view wd50 ml10"
              @dblclick="
                setMiddleCoverComponent('CombinationMedicalAndNursingCareIntroduce')
              "
            >
              <CombinationMedicalAndNursingCareIntroduce></CombinationMedicalAndNursingCareIntroduce>
            </div>
          </div>
        </div>
        <!-- 5-1 -->
        <!-- 5-2 + 5-3 -->
        <div class="layout-view bd br20 pd20">
          <div
            class="layout-view hg50"
            @dblclick="setMiddleCoverComponent('EmployeesSituationSubsidies')"
          >
            <EmployeesSituationSubsidies></EmployeesSituationSubsidies>
          </div>
          <div class="layout-view hg50">
            <div class="layout-view fdr">
              <div
                class="layout-view wd50 mr10"
                @dblclick="setMiddleCoverComponent('EmployeesSubsidiesNumber')"
              >
                <EmployeesSubsidiesNumber></EmployeesSubsidiesNumber>
              </div>
              <div
                class="layout-view wd50 ml10"
                @dblclick="setMiddleCoverComponent('PostSubsidiesNumber')"
              >
                <PostSubsidiesNumber></PostSubsidiesNumber>
              </div>
            </div>
          </div>
        </div>
        <!-- 5-2 + 5-3 -->
      </div>
      <!-- 第五列 -->
    </div>
    <!-- 主窗体右侧第二屏 -->

    <!-- 主窗体右侧第三屏 -->
    <div v-show="currentRightPage == 3" class="layout-main-right">
      <OrgScreen3></OrgScreen3>
    </div>
    <!-- 主窗体右侧第三屏 -->
  </div>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";

import WelfareCentreOverview from "@/components/org/WelfareCentreOverview.vue";

import WelfareCentreOperation from "@/components/org/WelfareCentreOperation.vue";
import WelfareCentreOperationElderResidentsNumber from "@/components/org/WelfareCentreOperationElderResidentsNumber.vue";
import WelfareCentreOperationElderResidentsRate from "@/components/org/WelfareCentreOperationElderResidentsRate.vue";
import WelfareCentreOperationEmployeesNumber from "@/components/org/WelfareCentreOperationEmployeesNumber.vue";

import WelfareCentreMap from "@/components/org/WelfareCentreMap.vue";

import WelfareCentreNature from "@/components/org/WelfareCentreNature.vue";
import WelfareCentreNatureElderResidentsBedNumber from "@/components/org/WelfareCentreNatureElderResidentsBedNumber.vue";
import WelfareCentreNatureEmployeesNumber from "@/components/org/WelfareCentreNatureEmployeesNumber.vue";

import WelfareCentrePictureIntroduction from "@/components/org/WelfareCentrePictureIntroduction.vue";
import TodayBedOverview from "@/components/org/TodayBedOverview.vue";

import SafetyMonitoringe from "@/components/org/SafetyMonitoringe.vue";

import WelfareCentreIntroduce from "@/components/org/WelfareCentreIntroduce.vue";
import LeaderIntroduce from "@/components/org/LeaderIntroduce.vue";

import ElderResidents from "@/components/org/ElderResidents.vue";
import ElderResidentsByAge from "@/components/org/ElderResidentsByAge.vue";
import ElderResidentsByGender from "@/components/org/ElderResidentsByGender.vue";
import ElderResidentsByHouseholdRegister from "@/components/org/ElderResidentsByHouseholdRegister.vue";

import ElderResidentsByNursingLevel from "@/components/org/ElderResidentsByNursingLevel.vue";
import ElderResidentsBySubsidiesType from "@/components/org/ElderResidentsBySubsidiesType.vue";

import DailyActivitiesAndNews from "@/components/org/DailyActivitiesAndNews.vue";

import EmployeesSituationByAge from "@/components/org/EmployeesSituationByAge.vue";
import EmployeesSituationByGender from "@/components/org/EmployeesSituationByGender.vue";
import EmployeesSituationByPostType from "@/components/org/EmployeesSituationByPostType.vue";
import EmployeesSituationByEducation from "@/components/org/EmployeesSituationByEducation.vue";

import ServiceChargesStandard from "@/components/org/ServiceChargesStandard.vue";
// import ServiceChargesStandardBedPrice from "@/components/org/ServiceChargesStandardBedPrice.vue";
// import ServiceChargesStandardNursingPrice from "@/components/org/ServiceChargesStandardNursingPrice.vue";
// import ServiceChargesStandardFoodPrice from "@/components/org/ServiceChargesStandardFoodPrice.vue";

import CombinationMedicalAndNursingCareIntroduce from "@/components/org/CombinationMedicalAndNursingCareIntroduce.vue";

import EmployeesSituationSubsidies from "@/components/org/EmployeesSituationSubsidies.vue";

import EmployeesSubsidiesNumber from "@/components/org/EmployeesSubsidiesNumber.vue";
import PostSubsidiesNumber from "@/components/org/PostSubsidiesNumber.vue";

import OrgScreen3 from "@/components/org/OrgScreen3.vue";

import OverviewBase from "@/views/mixin/OverviewBase.vue";

export default {
  components: {
    Title,
    Nav,

    WelfareCentreOverview,

    WelfareCentreOperation,
    WelfareCentreOperationElderResidentsNumber,
    WelfareCentreOperationElderResidentsRate,
    WelfareCentreOperationEmployeesNumber,

    WelfareCentreNature,
    WelfareCentreNatureElderResidentsBedNumber,
    WelfareCentreNatureEmployeesNumber,

    WelfareCentreMap,

    WelfareCentrePictureIntroduction,

    TodayBedOverview,

    SafetyMonitoringe,

    WelfareCentreIntroduce,

    LeaderIntroduce,

    ElderResidents,
    ElderResidentsByAge,
    ElderResidentsByGender,
    ElderResidentsByHouseholdRegister,

    ElderResidentsByNursingLevel,
    ElderResidentsBySubsidiesType,

    DailyActivitiesAndNews,

    EmployeesSituationByAge,
    EmployeesSituationByGender,
    EmployeesSituationByPostType,
    EmployeesSituationByEducation,

    CombinationMedicalAndNursingCareIntroduce,

    ServiceChargesStandard,
    // ServiceChargesStandardBedPrice,
    // ServiceChargesStandardNursingPrice,
    // ServiceChargesStandardFoodPrice,

    EmployeesSituationSubsidies,

    EmployeesSubsidiesNumber,
    PostSubsidiesNumber,
    OrgScreen3,
  },
  mixins: [OverviewBase],
  data() {
    return {
      employeesNum: 0,
    };
  },
  methods: {
    set_employees_num(employeesNum) {
      this.employeesNum = employeesNum;
    },
  },
};
</script>

<style lang="less" scoped>
.layout-main-2 {
  .layout-main-right {
    .layout-view.bd {
      height: 2130px !important;
      // 现场会修改2会影响原来的
      // height: 66.6666% !important;
    }
  }
}
</style>
