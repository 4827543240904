<template>
  <div class="components-layout fsszhylfzjc pd0">
    <div class="title">佛山市智慧养老发展进程</div>
    <div class="picture">
      <img :src="picture" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picture: "/images/process.jpg",
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scope>
.fsszhylfzjc {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 0.48rem;
    height: 32vh;
  }
  .picture {
    width: 100%;
    max-height: 2160px;
    img {
      .setImg(contain);
      height: unset;
    }
  }
}
</style>
