<template>
  <div class="components-layout">
    <div class="nav-intruduce">
      <span>男性 | {{ data.allManNum }}</span>
      <span>女性 | {{ data.allWomanNum }}</span>
    </div>
    <div class="charts">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </div>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
      data: {
        allManNum: 0,
        allWomanNum: 0,
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/nh/getElderNumList?type=sex`,
        success: (response) => {
          let max = 0,
            elderAgeObj = {
              "60岁以下": 0,
              "60-70岁": 1,
              "70-80岁": 2,
              "80-90岁": 3,
              "90-100岁": 4,
              "100岁以上": 5,
            },
            indicatorArr = [
              { name: "60岁以下" },
              { name: "60-70岁" },
              { name: "70-80岁" },
              { name: "80-90岁" },
              { name: "90-100岁" },
              { name: "100岁以上" },
            ],
            manArr = [0, 0, 0, 0, 0, 0],
            womanArr = [0, 0, 0, 0, 0, 0];

          response.data &&
            response.data.data &&
            response.data.data.manList &&
            response.data.data.manList.map((item) => {
              if (elderAgeObj[item.age] !== undefined) {
                if (item.number > max) {
                  max = item.number;
                }
                manArr[elderAgeObj[item.age]] = item.number;
              }
            });

          response.data &&
            response.data.data &&
            response.data.data.womanList &&
            response.data.data.womanList.map((item) => {
              if (elderAgeObj[item.age] !== undefined) {
                if (item.number > max) {
                  max = item.number;
                }
                womanArr[elderAgeObj[item.age]] = item.number;
              }
            });

          // 男女长者数
          this.data = {
            allManNum: response.data.data.allManNum || 0,
            allWomanNum: response.data.data.allWomanNum || 0,
          };

          // 设置边界值
          indicatorArr = this.setAndicatorArrMax(indicatorArr, max);

          // 更新配置
          this.option = {
            legend: {},
            radar: {
              axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
              radius: "70%",
              shape: "polygon",
              splitArea: {
                areaStyle: {
                  color: "transparent",
                },
              },
              splitLine: {
                lineStyle: {
                  color: [
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                  ],
                  width: 1,
                },
              },
              name: { textStyle: { color: "#ffffff" } },
              indicator: [
                // { name: "100岁以上", max: 680 },
                // { name: "70-79岁", max: 680 },
                // { name: "80-89岁", max: 680 },
                // { name: "90-99岁", max: 680 },
                ...indicatorArr,
              ],
            },
            series: [
              // {
              //   type: "custom",
              //   name: "男",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              // {
              //   type: "custom",
              //   name: "女",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              {
                areaStyle: {
                  normal: {
                    color: "transparent",
                    shadowBlur: 20,
                    // shadowColor: "rgba(255, 255, 255, 0.1)",
                  },
                },
                itemStyle: {
                  normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 },
                },
                lineStyle: { normal: { width: 5 } },
                symbol: "circle",
                type: "radar",
                data: [
                  // { value: [6, 2, 409, 328], name: "男" },
                  // { value: [59, 4, 596, 680], name: "女" },
                  { value: [...manArr], name: "男" },
                  { value: [...womanArr], name: "女" },
                ],
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            color: ["#16B9FF", "#FD415F"],
            polar: { radius: "70%", center: ["50%", "50%"] },
            angleAxis: {
              type: "category",
              clockwise: false,
              boundaryGap: false,
              splitLine: { show: false },
              axisLine: { show: false },
            },
            radiusAxis: {
              type: "value",
              max: 100,
              splitLine: { show: false },
              axisTick: { show: false },
              axisLabel: { show: false },
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.components-layout {
  flex-direction: column;
  .charts {
    width: 100%;
    height: calc(~"100% - 120px");
  }
}
</style>
