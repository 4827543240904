<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/home/getElderAnalysisListByAge`,
        param: {
          idOrg: this.HomeId,
        },
        success: (response) => {
          let indicatorArr = [],
            numberArr = [],
            max = 0;
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              if (item.number > max) {
                max = item.number;
              }
              indicatorArr.push({ name: item.age });
              numberArr.push(item.number);
            });

          // 设置边界值
          indicatorArr = this.setAndicatorArrMax(indicatorArr, max);

          this.option = {
            legend: { show: false },
            radar: {
              axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
              shape: "polygon",
              splitArea: { areaStyle: { color: "transparent" } },
              splitLine: {
                lineStyle: {
                  color: [
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                  ],
                  width: 1,
                },
              },
              name: { textStyle: { color: "#ffffff" } },
              indicator: [
                // { name: "80-89岁", max: 200 },
                // { name: "70-79岁", max: 200 },
                // { name: "60-69岁", max: 200 },
                // { name: "90-99岁", max: 200 },
                // { name: "100岁以上", max: 200 },
                ...indicatorArr,
              ],
            },
            series: [
              // {
              //   type: "custom",
              //   name: "按年龄段",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              {
                areaStyle: { normal: { color: "transparent" } },
                itemStyle: {
                  normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 },
                },
                lineStyle: { normal: { width: 5 } },
                symbol: "circle",
                type: "radar",
                // data: [{ value: [60, 44, 12, 196, 10], name: "按年龄段" }],
                data: [{ value: [...numberArr], name: "按年龄段" }],
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            color: ["#16B9FF"],
            polar: { radius: "75%", center: ["50%", "50%"] },
            angleAxis: {
              type: "category",
              clockwise: false,
              boundaryGap: false,
              splitLine: { show: false },
              axisLine: { show: false },
            },
            radiusAxis: {
              type: "value",
              max: 100,
              splitLine: { show: false },
              axisTick: { show: false },
              axisLabel: { show: false },
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
