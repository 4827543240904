<template>
  <fragment>
    <Title title="各区服务长者情况"></Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav pb0">
      <component :is="currentComponent"></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import EachDistrictServingElderByAge from "@/components/fs/EachDistrictServingElderByAge.vue";
import EachDistrictServingElderByGender from "@/components/fs/EachDistrictServingElderByGender.vue";
import EachDistrictServingElderByAssessment from "@/components/fs/EachDistrictServingElderByAssessment.vue";
export default {
  components: {
    Title,
    Nav,
    EachDistrictServingElderByAge,
    EachDistrictServingElderByGender,
    EachDistrictServingElderByAssessment,
  },
  mixins: [LayoutBase],
  data() {
    return {
      components: [
        {
          key: "EachDistrictServingElderByAge",
          title: "按年龄段",
        },
        {
          key: "EachDistrictServingElderByGender",
          title: "按性别",
        },
        {
          key: "EachDistrictServingElderByAssessment",
          title: "按老年人能力评估标准",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped></style>
