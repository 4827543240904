<template>
  <fragment>
    <Title title="志愿者参与情况"></Title>
    <div class="layout-view withoutTitle">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/com/selectVolunteerList",
        param: {
          idOrg: this.ComId,
        },
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.foundedDate, item.memberTotalNum]);
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            legend: { show: true },
            series: [
              {
                type: "bar",
                showItemStyle: true,
                barWidth: "20%",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "#2AC1F4" },
                      { offset: 0, color: "#0063CF" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 60,
                },
              },
              { type: "line" },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: { type: "category" },
            yAxis: [{}, {}],
            dataset: {
              source: [
                // [2017, 30, 30],
                // [2018, 50, 50],
                // [2019, 90, 90],
                // [2020, 30, 30],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
