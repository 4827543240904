<template>
  <div class="layout-view withoutNav pb0">
    <div class="layout-view hg50">
      <HomeCareElderNumber></HomeCareElderNumber>
    </div>
    <div class="layout-view hg50">
      <HomeCareOrderNumber></HomeCareOrderNumber>
    </div>
  </div>
</template>

<script>
import HomeCareElderNumber from "@/components/nh/HomeCareElderNumber.vue";
import HomeCareOrderNumber from "@/components/nh/HomeCareOrderNumber.vue";

export default {
  components: {
    HomeCareElderNumber,
    HomeCareOrderNumber,
  },
  data() {
    return {};
  },
  methods: {},
  beforeMount() {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
