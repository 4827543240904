<template>
  <fragment>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <component :is="currentComponent"></component>
  </fragment>
</template>

<script>
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import InstitutionalEndowment from "@/components/nh/InstitutionalEndowment.vue";
import CommunityEndowment from "@/components/nh/CommunityEndowment.vue";
import HomeEndowment from "@/components/nh/HomeEndowment.vue";
export default {
  components: {
    Nav,
    InstitutionalEndowment,
    CommunityEndowment,
    HomeEndowment,
  },
  mixins: [LayoutBase],
  data() {
    return {
      components: [
        {
          key: "InstitutionalEndowment",
          title: "机构养老",
        },
        {
          key: "CommunityEndowment",
          title: "社区养老",
        },
        {
          key: "HomeEndowment",
          title: "居家养老",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped></style>
