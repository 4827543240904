<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/nh/getCountByCategoryAndTown`,
        success: (response) => {
          let arr = [],
            temp = {};
          response.data &&
            response.data.data &&
            response.data.data.org &&
            response.data.data.org.map &&
            response.data.data.org.map((item) => {
              if (temp[item.divisionName] === undefined) {
                temp[item.divisionName] = arr.length;
                arr.push([item.divisionName, 0, 0, 0]);
              }
              arr[temp[item.divisionName]][1] = item.allNum;
            });
          response.data &&
            response.data.data &&
            response.data.data.com &&
            response.data.data.com.map &&
            response.data.data.com.map((item) => {
              if (temp[item.divisionName] === undefined) {
                temp[item.divisionName] = arr.length;
                arr.push([item.divisionName, 0, 0, 0]);
              }
              arr[temp[item.divisionName]][2] = item.allNum;
            });
          response.data &&
            response.data.data &&
            response.data.data.home &&
            response.data.data.home.map &&
            response.data.data.home.map((item) => {
              if (temp[item.divisionName] === undefined) {
                temp[item.divisionName] = arr.length;
                arr.push([item.divisionName, 0, 0, 0]);
              }
              arr[temp[item.divisionName]][3] = item.allNum;
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            series: [
              {
                itemStyle: {
                  color: {
                    colorStops: [
                      { color: "RGBA(191, 226, 9, 1)", offset: 0 },
                      { color: "RGBA(42, 181, 0, 1)", offset: 1 },
                    ],
                    globalCoord: false,
                    type: "linear",
                    x: 0,
                    x2: 0,
                    y: 0,
                    y2: 1,
                  },
                  borderRadius: 50,
                },
                name: "养老机构",
                type: "bar",
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
              {
                itemStyle: {
                  color: {
                    colorStops: [
                      { color: "RGBA(255, 137, 4, 1)", offset: 1 },
                      { color: "RGBA(255, 208, 42, 1)", offset: 0 },
                    ],
                    globalCoord: false,
                    type: "linear",
                    x: 0,
                    x2: 0,
                    y: 0,
                    y2: 1,
                  },
                  borderRadius: 50,
                },
                name: "社区养老场所",
                type: "bar",
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
              {
                itemStyle: {
                  color: {
                    colorStops: [
                      { color: "RGBA(254, 79, 79, 1)", offset: 1 },
                      { color: "RGBA(255, 137, 4, 1)", offset: 0 },
                    ],
                    globalCoord: false,
                    type: "linear",
                    x: 0,
                    x2: 0,
                    y: 0,
                    y2: 1,
                  },
                  borderRadius: 50,
                },
                name: "入库供应商",
                type: "bar",
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
            ],
            tooltip: {},
            title: {
              text: "单位：家",
            },
            xAxis: { type: "category" },
            yAxis: {},
            legend: { show: true },
            dataset: {
              source: [
                ["镇街", "养老机构", "社区幸福院", "入库供应商"],
                // ["桂城街道", 1, 31, 8],
                // ["九江镇", 1, 12, 1],
                // ["西樵镇", 4, 19, 0],
                // ["丹灶镇", 1, 19, 0],
                // ["狮山镇", 9, 605, 4],
                // ["大沥镇", 4, 40, 1],
                // ["里水镇", 3, 36, 0],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
