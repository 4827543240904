<template>
  <fragment></fragment>
</template>

<script>
import { bsType } from "@/utils/index.js";
export default {
  data() {
    return {
      config: {
        centerLng: 113.036626,
        centerLat: 23.039371,
        zoom: 13,
      },
    };
  },
  computed: {
    bsType() {
      return bsType == "平台大屏" ? 1 : 2;
    },
  },
};
</script>

<style lang="less" scoped></style>
