
export default class {
    constructor(config) {
        let configs = Object.assign({ elementId: 'scroll-element', scrollDirection: 'scrollTop', scrollSpeed: 40, resetSpeed: 250 }, config);
        // 定时器/延时器标识
        this.stv = null;
        // 开始滚动的地方
        this.scrollBegin = 0;
        // 滚动速度，数值越小滚动越快
        this.scrollSpeed = configs.scrollSpeed;
        // 重置速度，数值越小滚动越快
        this.resetSpeed = configs.resetSpeed;
        // 元素ID
        this.elementId = configs.elementId;
        // 元素
        this.element = document.getElementById(this.elementId);

        if (configs.scrollDirection == 'scrollTop') {
            // 方向
            this.scrollDirection = 'scrollTop';
            // 滚动长度
            this.clientLength = 'clientHeight';
            // 已滚动的长度
            this.scrollLength = 'scrollHeight';
        } else {
            this.scrollDirection = 'scrollLeft';
            this.clientLength = 'clientWidth';
            this.scrollLength = 'scrollWidth';
        }
    }
    resetScroll() {
        // 开启定时器
        this.stv = window.setInterval(() => {
            if (this.element[this.scrollDirection] <= 0) {
                // 清空定时器
                window.clearInterval(this.stv);
                // 延时器一秒后再开始滚动
                this.stv = window.setTimeout(() => {
                    // 清空延时器
                    window.clearTimeout(this.stv);
                    // 开始滚动
                    this.beginScroll();
                }, 1000);
            } else {
                // 曲线滚动返回顶部
                this.element[this.scrollDirection] -=
                    Math.floor(this.element[this.scrollDirection]) / this.resetSpeed;
            }
        }, 1);
        return this;
    }
    // 开始滚动
    beginScroll() {
        if (!this.element) {
            return this;
        }
        let length = this.scrollBegin || 0;
        // 开启定时器
        this.stv = window.setInterval(() => {
            if (
                // 多个1px，防止误差
                1 + this.element[this.clientLength] +
                this.element[this.scrollDirection] >=
                this.element[this.scrollLength]
            ) {
                // 到底部，清空定时器
                window.clearInterval(this.stv);
                // 清空留存的
                this.scrollBegin = 0;
                // 延时器一秒后再开始重置
                this.stv = window.setTimeout(() => {
                    // 清空延时器
                    window.clearTimeout(this.stv);
                    // 重置滚动条到顶部
                    this.resetScroll();
                }, 1000);
            } else {
                // 滚动高度+1
                length += 1;
                this.element[this.scrollDirection] = length;
            }
        }, this.scrollSpeed);
        return this;
    }
    eventMouseover() {
        if (this.stv) {
            // 存起来以便下一次从这里开始
            this.scrollBegin = this.element[this.scrollDirection];
            window.clearInterval(this.stv);
        }
    }
    // 绑定移入移出的事件
    mouseInOrOut(eventsId) {
        if (!this.element) {
            return;
        }
        // 事件ID
        this.eventsId = eventsId || `events-layout-${this.elementId}`;
        // 事件
        this.events = document.getElementById(this.eventsId);
        if (!this.events) {
            console.error('【找不到可绑定元素ID】' + this.eventsId);
            return;
        }
        this.events.addEventListener("mouseover", () => {
            if (this.stv) {
                // 存起来以便下一次从这里开始
                this.scrollBegin = this.element[this.scrollDirection];
                window.clearInterval(this.stv);
            }
        });
        this.events.addEventListener("mouseout", () => {
            this.beginScroll();
        });
        return this;
    }
    // 设置开始滚动的点
    setScrollBegin(number = 0) {
        window.clearInterval(this.stv);
        window.clearTimeout(this.stv);
        this.scrollBegin = number;
        return this;
    }
    // 摧毁
    destroy() {
        // 清空定时器/延时器
        if (this.stv) {
            window.clearInterval(this.stv);
            window.clearTimeout(this.stv);
        }
        // 清空元素对象
        if (this.element) {
            this.element = null;
        }
    }
}