<template>
  <div class="iframe-layout">
    <iframe :src="url"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
    };
  },
  methods: {},
  mounted() {
    this.url = this.$store.state.currentIframeUrl;
  },
  watch: {
    "$store.state.currentIframeUrl": {
      deep: true,
      handler: function (url) {
        this.url = url;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.iframe-layout {
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
