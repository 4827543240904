<template>
  <div class="components-layout pd0">
    <div class="picture-introduction">
      <div v-if="picture_list && picture_list.length" class="main">
        <!-- 大图 -->
        <div class="picture-left">
          <img
            v-for="(item, index) in picture_list"
            v-show="index == (picture_list.length > 2 ? activeArr[1] : activeArr[0])"
            :key="index"
            :src="`${imgUrl}${item}`"
            @click="setImage(`${imgUrl}${item}`)"
          />
        </div>
        <!-- 大图 -->
        <!-- 小图列表 -->
        <div class="picture-right">
          <ul>
            <li
              v-for="(item, index) in picture_list"
              v-show="activeArr.includes(index)"
              :key="index"
              :class="`order-${activeObj[index]}`"
            >
              <img :src="`${imgUrl}${item}`" @click="setImage(`${imgUrl}${item}`)" />
            </li>
          </ul>
        </div>
        <!-- 小图列表 -->
      </div>
    </div>
    <EmptyData v-if="isEmpty" />
  </div>
</template>

<script>
import EmptyData from "@/components/common/EmptyData.vue";
import { imgUrl } from "@/utils/config.js";
export default {
  components: {
    EmptyData,
  },
  props: {
    picture_list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    times: {
      type: Number,
      default: 3000,
    },
  },
  computed: {
    isEmpty() {
      return this.picture_list.length == 0;
    },
  },
  data() {
    return {
      imgUrl,
      stv: null,
      activeIndex: 0,
      activeArr: [],
      activeObj: {},
    };
  },
  methods: {
    setImage(item) {
      // 已经展开图片组件了
      if (this.$store.state.currentMiddleModule == "Picture") {
        this.$store.commit("setCommonValue", [
          {
            k: "currentPictureUrl",
            v: item,
          },
        ]);
      } else {
        // 开
        this.$store.commit("setCommonValue", [
          {
            k: "currentMiddleModule",
            v: "Picture",
          },
        ]);
        // 换图片
        setTimeout(() => {
          this.$store.commit("setCommonValue", [
            {
              k: "currentPictureUrl",
              v: item,
            },
          ]);
        }, 500);
      }
    },
    startStv() {
      let picture_list = this.picture_list || [];
      if (this.activeIndex == picture_list.length - 1) {
        this.activeIndex = 0;
      } else {
        ++this.activeIndex;
      }
      let a1 = this.activeIndex - 1 < 0 ? picture_list.length - 1 : this.activeIndex - 1,
        a2 = this.activeIndex,
        a3 = this.activeIndex + 1 > picture_list.length - 1 ? 0 : this.activeIndex + 1,
        activeObj = {};
      (activeObj[a1] = 1), (activeObj[a2] = 2), (activeObj[a3] = 3);
      (this.activeArr = [a1, a2, a3]), (this.activeObj = activeObj);
    },
    setInit() {
      return new Promise((resolve) => {
        this.stv = null;
        this.activeIndex = 0;
        this.activeArr = [];
        this.activeObj = {};
        resolve();
      });
    },
  },
  mounted() {
    if (this.picture_list && this.picture_list.length > 1) {
      this.stv = window.setInterval(() => {
        this.startStv();
      }, this.times || 3000);
    }
  },
  beforeDestroy() {
    // 清空定时器/延时器
    this.stv && window.clearInterval(this.stv);
  },
  watch: {
    picture_list() {
      if (this.stv) {
        window.clearInterval(this.stv);
      }
      this.setInit().then(() => {
        this.stv = window.setInterval(() => {
          this.startStv();
        }, this.times || 3000);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.picture-introduction {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .main {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .picture-left {
      width: calc(~"70% - .1rem");
      cursor: pointer;
    }
    .picture-right {
      width: 30%;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      ul {
        display: flex;
        flex-direction: column;
        height: 100%;
        li {
          height: calc((100% - 0.2rem) / 3);
          margin-bottom: 0.1rem;
          cursor: pointer;
        }
        li.order-1 {
          order: 1;
        }
        li.order-2 {
          order: 2;
        }
        li.order-3 {
          order: 3;
        }
      }
    }
  }
}
</style>
