<template>
  <fragment>
    <Title title="长者体验人数分析"></Title>
    <div class="layout-view withoutTitle pb0">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {},
  mounted() {
    this.option = {
      grid: { left: 150 },
      series: [
        {
          type: "line",
          symbol: "emptyCircle",
          symbolSize: 20,
          label: { show: true },
          lineStyle: { color: "RGBA(48, 148, 235, 1)" },
          itemStyle: { normal: {} },
        },
      ],
      title: { text: "单位：人" },
      tooltip: {},
      xAxis: { type: "category" },
      yAxis: {},
      dataset: {
        source: [
          ["Q1", 7],
          ["Q2", 6],
          ["Q3", 10],
          ["Q4", 11],
        ],
      },
    };
  },
};
</script>

<style lang="less" scoped></style>
