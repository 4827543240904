<template>
  <div class="full-space">
    <div :id="echartId" class="echarts-layout"></div>
    <EmptyData v-if="isEmptyData"></EmptyData>
  </div>
</template>

<script>
import { symbolSize, createUniqueKey } from "@/utils/index.js";
import EmptyData from "@/components/common/EmptyData.vue";

export default {
  components: {
    EmptyData,
  },
  // 父组件传过来的echarts的option
  props: {
    option: {
      type: Object,
    },
  },
  computed: {
    isEmptyData() {
      if (!this.option) {
        return true;
      }
      if (
        (this.option.dataset && !this.option.dataset.source) ||
        (this.option.dataset &&
          this.option.dataset.source &&
          this.option.dataset.source.length === 0)
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      echartObj: null,
      echartId: createUniqueKey(12),
      isRender: false,
    };
  },
  methods: {
    showEcharts() {
      // 获取echarts对象
      let myChart = this.$echarts.init(
        // ID
        document.getElementById(this.echartId),
        // 主题名字
        "echartTheme"
      );

      // 通用的symbolSize处理函数
      if (this.option && this.option.series && Array.isArray(this.option.series)) {
        for (let i = 0; i < this.option.series.length; i++) {
          if (
            this.option.series[i] &&
            this.option.series[i]["markPoint"] &&
            !this.option.series[i]["markPoint"]["symbolSize"]
          ) {
            this.option.series[i]["markPoint"]["symbolSize"] = symbolSize;
          }
        }
      }

      // 设置echarts的option
      myChart.setOption(this.option);

      // this.nextTick(() => {
      //   myChart.resize();
      // });
      this.echartObj = myChart;

      this.$once("hook:beforeDestroy", () => {
        myChart.dispose();
        this.echartObj = null;
      });
    },
  },
  watch: {
    // 外部初始化会传来null，有值才渲染
    option() {
      if (this.isRender == false) {
        if (this.option) {
          this.showEcharts();
          this.isRender = true;
        }
      } else {
        this.echartObj.setOption(this.option, true);
      }
    },
  },
};
</script>

<style lang="less">
.echarts-layout {
  min-height: 200px;
  div {
    font-size: 0.18rem !important;
    color: #fff !important;
    line-height: 1.4 !important;
    span {
      font-size: 0.18rem !important;
      color: #fff !important;
      float: none;
    }
  }
  > div {
    &:last-child:not(:empty) {
      padding: 0.1rem !important;
      background: rgba(50, 50, 50, 0.7) !important;
      border-color: transparent !important;
      border-radius: 0.1rem !important;
      visibility: unset !important;
      opacity: 1 !important;
      > div {
        > div {
          > div {
            span {
              &:first-child {
                height: 0.08rem !important;
                width: 0.08rem !important;
                position: relative;
                top: -0.03rem;
              }
              &:nth-child(2) {
                margin-right: 20px;
              }
              &:nth-child(3) {
                margin-left: auto !important;
                float: none !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
