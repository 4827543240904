<template>
  <div class="ny-mask"></div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="less" scoped>
.ny-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  backdrop-filter: blur(10px);
  background: #000;
  opacity: 0.5;
}
</style>
