<template>
  <div class="layout-top">
    <!-- 主窗体模块标题 -->
    <div class="layout-module-title" @click="showLayouts">
      <Title
        v-if="currentOverView.title"
        :title="currentOverView.title"
        withLine="true"
      ></Title>
      <div class="layout-module-select" v-show="layoutsSelectVisible">
        <div
          class="layout-module-option"
          v-for="(item, index) in layouts"
          :key="index"
          @click.stop="toOverview(item.v)"
        >
          {{ item.t }}
        </div>
      </div>
    </div>
    <!-- 主窗体模块标题 -->

    <!-- 呼吸灯 -->
    <Breath></Breath>
    <!-- 呼吸灯 -->

    <!-- 主窗体顶部总标题 -->
    <h1>{{ h1 }}</h1>

    <!-- 右侧模块副标题 -->
    <div v-if="rightTitle" class="right-title">
      <h3>{{ rightTitle }}</h3>
    </div>
    <!-- 右侧模块副标题 -->

    <!-- 主窗体顶部总标题 -->
    <div v-if="currentOverView.pages > 0" class="right-pages">
      <span
        @click.stop="setRightPage(item)"
        v-for="(item, index) in currentOverView.pages"
        :key="index"
        :class="item == currentRightPage ? 'active' : ''"
        >{{ item }}</span
      >
    </div>
  </div>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Breath from "@/views/common/Breath.vue";
export default {
  props: ["currentOverView"],
  components: { Title, Breath },
  data() {
    return {
      title: null,
      h1: "南海区智慧养老综合服务管理平台",
      currentRightPage: null,
      layoutsSelectVisible: false,
      layouts: [
        {
          t: "佛山市总览",
          v: "toFS",
        },
        {
          t: "南海区总览",
          v: "toNH",
        },
        {
          t: "机构养老",
          v: "toOrg",
        },
        {
          t: "社区幸福院",
          v: "toCom",
        },
        {
          t: "入库供应商",
          v: "toHome",
        },
      ],
    };
  },
  methods: {
    showLayouts() {
      this.layoutsSelectVisible = !this.layoutsSelectVisible;
    },
    toOverview(v) {
      this.layoutsSelectVisible = false;
      this.$store.commit("setCommonValue", {
        k: "webSocketInstructions",
        v: {
          k: "setOverview",
          v,
        },
      });
    },
    setRightPage(item) {
      // 切换页码
      this.$store.commit("setCommonValue", { k: "currentRightPage", v: item });
      // 存到ls
      window.localStorage.setItem("currentRightPage", item);
    },
  },
  computed: {
    rightTitle() {
      let rightTitle = false;
      if (this.currentOverView.title == "入库供应商" && this.currentRightPage == 3) {
        rightTitle = this.$store.state.HomeName;
      } else if (this.currentOverView.title == "机构养老") {
        rightTitle = this.$store.state.OrgName;
      } else if (this.currentOverView.title == "社区幸福院") {
        rightTitle = this.$store.state.ComName;
      }
      return rightTitle;
    },
  },
  beforeMount() {
    let currentRightPage = window.localStorage.getItem("currentRightPage");
    if (!currentRightPage || isNaN(currentRightPage)) {
      this.currentRightPage = 1;
    } else {
      this.currentRightPage = currentRightPage;
    }
  },
  watch: {
    "$store.state.currentRightPage": {
      handler: function (newValue) {
        this.currentRightPage = newValue;
      },
    },
  },
};
</script>

<style lang="less"></style>
