<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  props: {
    current_year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getAcvitityTop`,
        param: {
          year: this.current_year,
          type: "活动",
        },
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push({ 发布活动总数: item.number, 机构名: item.orgName });
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            series: [
              {
                type: "bar",
                encode: { x: "发布活动总数", y: "机构名" },
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "#FFCE2B" },
                      { offset: 0, color: "#FF8500" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 20,
                },
                barMaxWidth: "20%",
              },
            ],
            title: { text: "单位：次" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {},
            yAxis: { type: "category", inverse: true },
            dataset: {
              source: [
                // { 发布活动总数: 360, 机构名: "狮北村社区幸福院" },
                // { 发布活动总数: 316, 机构名: "大涡塘村幸福院" },
                // { 发布活动总数: 298, 机构名: "狮南村社区幸福院" },
                // { 发布活动总数: 226, 机构名: "同德社区幸福院" },
                // { 发布活动总数: 207, 机构名: "大镇社区幸福院" },
                // { 发布活动总数: 168, 机构名: "崇南社区幸福院" },
                // { 发布活动总数: 161, 机构名: "永安社区幸福院" },
                // { 发布活动总数: 159, 机构名: "河东社区幸福院" },
                // { 发布活动总数: 145, 机构名: "狮中村社区幸福院" },
                // { 发布活动总数: 139, 机构名: "五星社区幸福院" },
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
