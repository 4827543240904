<template>
  <div class="prAndFlexGrow">
    <EchartsLayout :option="option"></EchartsLayout>
    <div class="infomation">
      <span>高龄津贴老人 | 0</span>
      <span>30天内认定过 | 0</span>
      <span>31-60天之间认定过 | 0</span>
      <span>61-90天之间认定过 | 0</span>
      <span>91天以上未认定过 | 0</span>
    </div>
  </div>
</template>

<script>
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {},
  mounted() {
    // this.option = {
    //   legend: {},
    //   radar: {
    //     axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
    //     radius: "70%",
    //     shape: "polygon",
    //     splitArea: { areaStyle: { color: "transparent" } },
    //     splitLine: {
    //       lineStyle: {
    //         color: ["#6b6b6b", "#6b6b6b", "#6b6b6b", "#6b6b6b", "#6b6b6b", "#6b6b6b"],
    //         width: 1,
    //       },
    //     },
    //     name: { textStyle: { color: "#ffffff" } },
    //     indicator: [
    //       { name: "100岁以上", max: null },
    //       { name: "60-70岁", max: null },
    //       { name: "80-90岁", max: null },
    //       { name: "90-100岁", max: null },
    //     ],
    //   },
    //   series: [
    //     // {
    //     //   type: "custom",
    //     //   name: "30天内认定过",
    //     //   silent: true,
    //     //   coordinateSystem: "polar",
    //     //   data: [0],
    //     // },
    //     // {
    //     //   type: "custom",
    //     //   name: "31-60天之间认定过",
    //     //   silent: true,
    //     //   coordinateSystem: "polar",
    //     //   data: [0],
    //     // },
    //     // {
    //     //   type: "custom",
    //     //   name: "61-90天之间认定过",
    //     //   silent: true,
    //     //   coordinateSystem: "polar",
    //     //   data: [0],
    //     // },
    //     // {
    //     //   type: "custom",
    //     //   name: "91天以上未认定过",
    //     //   silent: true,
    //     //   coordinateSystem: "polar",
    //     //   data: [0],
    //     // },
    //     // itemStyle: {
    //     //   color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //     //     { offset: 0, color: "rgba(1, 184, 252, 1)" },
    //     //     { offset: 1, color: "rgba(100, 74, 255, 1)" },
    //     //   ]),
    //     // },
    //     // areaStyle: {
    //     //   color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //     //     { offset: 0, color: "rgba(1, 184, 252, 1)" },
    //     //     { offset: 1, color: "rgba(100, 74, 255, 1)" },
    //     //   ]),
    //     // },
    //     {
    //       areaStyle: { normal: { color: "transparent" } },
    //       itemStyle: { normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 } },
    //       lineStyle: { normal: { width: 5 } },
    //       symbol: "circle",
    //       type: "radar",
    //       data: [
    //         { value: ["5761", "8646", "3301", "8676"], name: "30天内认定过" },
    //         { value: ["4525", "782", "341", "1333"], name: "31-60天之间认定过" },
    //         { value: ["3538", "145", "3437", "337"], name: "61-90天之间认定过" },
    //         { value: ["447", "6796", "897", "3134"], name: "91天以上未认定过" },
    //       ],
    //     },
    //   ],
    //   tooltip: { textStyle: { fontSize: 30 } },
    //   color: ["#16B9FF", "#FD415F", "#A5E300", "#FF8400"],
    //   polar: { radius: "70%", center: ["50%", "50%"] },
    //   angleAxis: {
    //     type: "category",
    //     clockwise: false,
    //     boundaryGap: false,
    //     splitLine: { show: false },
    //     axisLine: { show: false },
    //   },
    //   radiusAxis: {
    //     type: "value",
    //     max: 100,
    //     splitLine: { show: false },
    //     axisTick: { show: false },
    //     axisLabel: { show: false },
    //   },
    // };
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/less/infomation.less";
</style>
