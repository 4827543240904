<template>
  <fragment>
    <Title title="机构介绍"></Title>
    <div class="layout-view withoutTitle">
      <OrganizationIntroduction :data="data"></OrganizationIntroduction>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import OrganizationIntroduction from "@/components/common/OrganizationIntroduction.vue";
export default {
  mixins: [ComponentBase],
  components: {
    Title,
    OrganizationIntroduction,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgIntroduce?id_org=${this.ComId}`,
        success: (response) => {
          this.data = {
            orgName: response.data.data.orgName,
            orgNature: response.data.data.orgNature,
            orgPhone: response.data.data.orgPhone,
            orgAddress: response.data.data.orgAddress,
            starLevel: response.data.data.starLevel,
            orgIntroduce: response.data.data.orgIntroduce,
            establishedDate: response.data.data.establishedDate,
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
