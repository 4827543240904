<template>
  <div>
    <div id="weixin-login"></div>
    <span v-if="errMsg" class="error-msg">{{ errMsg }}</span>
  </div>
</template>

<script>
import { getQueryVariable } from "@/utils/index.js";
import { baseUrl } from "@/utils/config.js";
export default {
  data() {
    return {};
  },
  computed: {
    errMsg() {
      let msg = getQueryVariable("msg"),
        errMsg = "";
      if (msg) {
        switch (msg) {
          case "1":
            errMsg = "用户停止授权登录";
            break;
          case "2":
            errMsg = "微信登录失败，无效的code号";
            break;
          case "3":
            errMsg = "用户无权限，鉴权失败";
            break;
          case "4":
            errMsg = "redis保存失败";
            break;
          default:
            errMsg = "授权失败，请联系管理员";
            break;
        }
      }
      return errMsg;
    },
  },
  mounted() {
    new window.WxLogin({
      id: "weixin-login",
      appid: "wx666909bdffdc6d84",
      scope: "snsapi_login",
      redirect_uri: `https://${baseUrl}/wx/display/login`,
      style: "white",
      state: "bigScreen",
      href: `https://bs.e-health100.com/css/kzt.css`,
    });
  },
};
</script>

<style lang="less">
.error-msg {
  position: fixed;
  top: 20px;
  font-size: 64px;
  left: 50%;
  transform: translateX(-50%);
  color: red;
}
#weixin-login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
