<template>
  <fragment>
    <Title title="服务下单渠道分析"></Title>
    <div class="layout-view withoutTitle">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getOrderBuyOriginWithMonth`,
        success: (response) => {
          let arr = [];
          for (let i = 1; i <= 12; i++) {
            arr.push({ 月份: `${i}月`, 平台下单: 0, 自主下单: 0 });
          }
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              if (!isNaN(item.month) && item.month > 0 && item.month < 13) {
                if (item.origin == "平台") {
                  arr[item.month - 1]["平台下单"] = item.allNum;
                } else if (item.origin == "自主") {
                  arr[item.month - 1]["自主下单"] = item.allNum;
                }
              }
            });
          this.option = {
            // grid: { left: 150 },
            grid: { containLabel: true },
            series: [
              { type: "line", name: "平台下单", ecode: { x: "月份", y: "平台下单" } },
              { type: "line", name: "自主下单", ecode: { x: "月份", y: "自主下单" } },
            ],
            tooltip: { textStyle: { fontSize: 30 }, trigger: "axis" },
            xAxis: { type: "category" },
            yAxis: {},
            legend: { show: true },
            dataset: {
              source: [
                // { 月份: "1月", 平台下单: 13949, 自主下单: 0 },
                // { 月份: "2月", 平台下单: 15615, 自主下单: 0 },
                // { 月份: "3月", 平台下单: 9964, 自主下单: 0 },
                // { 月份: "4月", 平台下单: 6, 自主下单: 0 },
                // { 月份: "5月", 平台下单: 1249, 自主下单: 0 },
                // { 月份: "6月", 平台下单: 3, 自主下单: 0 },
                // { 月份: "7月", 平台下单: 12431, 自主下单: 0 },
                // { 月份: "8月", 平台下单: 11775, 自主下单: 0 },
                // { 月份: "9月", 平台下单: 11020, 自主下单: 0 },
                // { 月份: "10月", 平台下单: 11097, 自主下单: 0 },
                // { 月份: "11月", 平台下单: 11090, 自主下单: 0 },
                // { 月份: "12月", 平台下单: 14258, 自主下单: 0 },
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
