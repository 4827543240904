<template>
  <div>
    <Video v-if="false"></Video>
    <Picture v-if="false"></Picture>
  </div>
</template>

<script>
import Video from "@/components/common/Video.vue";
import Picture from "@/components/common/Picture.vue";
export default {
  components: { Video, Picture },
  data() {
    return {
      currentRightPage: null,
      middleCoverComponent: null,
    };
  },
  methods: {
    setMiddleCoverComponent(item) {
      this.middleCoverComponent = item;
    },
    closeCoverComponent() {
      this.$store.commit("setCommonValue", {
        k: "currentMiddleModule",
        v: "",
      });
      this.middleCoverComponent = null;
    },
  },
  mounted() {},
  beforeMount() {
    let currentRightPage = window.localStorage.getItem("currentRightPage");
    if (!currentRightPage || isNaN(currentRightPage)) {
      this.currentRightPage = 1;
    } else {
      this.currentRightPage = currentRightPage;
    }
  },
  watch: {
    "$store.state.currentRightPage": {
      handler: function (newValue) {
        this.currentRightPage = newValue;
      },
    },
    "$store.state.currentMiddleModule": {
      deep: true,
      handler: function (newValue) {
        this.middleCoverComponent = newValue;
      },
    },
  },
};
</script>

<style lang="less" scoped></style>
