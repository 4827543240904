import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const currentYear = new Date().getFullYear();

var store = new Vuex.Store({
    state: {
        // 最后四年
        lastFourYears: [currentYear, currentYear - 1, currentYear - 2, currentYear - 3],
        // 镇街列表
        townList: ['西樵镇', '桂城街道', '九江镇', '丹灶镇', '里水镇', '狮山镇', '大沥镇'],
        // 养老机构ID
        OrgId: 101,
        // 养老机构名字
        OrgName: "南海区社会福利中心",
        // 幸福院ID
        ComId: 122,
        // 幸福院名字
        ComName: "沥东社区幸福院",
        // 服务商ID
        HomeId: 69,
        // 服务商名字
        HomeName: "广东谷丰健康管理有限公司",
        // 居家服务商特色服务的IframeUrl
        currentIframeUrl: '',
        // 视频的VideoUrl
        currentVideoUrl: '',
        // 图片的PictureUrl
        currentPictureUrl: '',
        // 当前镇街
        currentTown: '',
        // 当前机构
        currentOrganization: '',
        // 当前右侧的页码
        currentRightPage: 1,
        // 当前大屏中间展示的模块
        currentMiddleModule: null,
        // 控制台的Websocket
        webSocket: null,
        webSocketStatus: false,
        webSocketInstructions: '',
        webSocketToken: '',
        kztSocketHeart: null,
        // 紧急呼叫的websocket
        mapWebSocket: null,
        mapSocketHeart: null,
        // 长者紧急呼叫信息
        currentEmergencyInfo: {},
        // 接口返回数据的缓存域
        requestData: {}
    },
    mutations: {
        setRequestData(state, object) {
            state.requestData[object['k']] = object['v'];
        },
        setCommonValue(state, datas) {
            if (Array.isArray(datas)) {
                for (let i = 0; i < datas.length; i++) {
                    state[datas[i]['k']] = datas[i]['v'];
                }
            } else {
                state[datas['k']] = datas['v'];
            }
        },
        setWebSocket(state, object) {
            state.webSocket = object;
        },
        setWebSocketInstructions(state, item) {
            state.webSocketInstructions = item;
        },
    }
});

export default store;