<template>
  <div class="ny-logincode" @click="hide">
    <img :src="login_code" />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      login_code: "",
    };
  },
  methods: {
    hide() {
      this.$emit("setLoginCodeHide");
    },
  },
  beforeMount() {
    // this.$store.commit("setCommonValue", {
    //   k: "webSocketToken",
    //   v: "708e1cddc60b10964c9212c22c82ddcb-kz953982",
    // });
    let that = this;
    this.axios
      .post("/display/getKzToken")
      .then(function (response) {
        if (response.data && response.data.isOk == "Y") {
          that.login_code = response.data.data.qrCode;
          that.$store.commit("setCommonValue", {
            k: "webSocketToken",
            v: response.data.data.kzToken,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style lang="less" scoped>
.ny-logincode {
  position: fixed;
  z-index: 19999999;
  max-width: 1000px;
  max-height: 1000px;
  width: 50vw;
  height: 50vh;
  transition: 0.5s;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  img {
    .setImg(contain);
  }
}
</style>
