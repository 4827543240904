<template>
  <fragment>
    <Title title="服务商营运情况排名前10家">
      <template v-slot:righter>
        <Select :callBack="set_current_year" :dataArray="yearSelecter"></Select>
      </template>
    </Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav">
      <component
        ref="child_component"
        :is="currentComponent"
        :current_year="current_year"
      ></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Select from "@/components/common/Select.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import HomeServiceOperationRankingServiceElderNumber from "@/components/home/HomeServiceOperationRankingServiceElderNumber.vue";
import HomeServiceOperationRankingServiceOrderNumber from "@/components/home/HomeServiceOperationRankingServiceOrderNumber.vue";
import HomeServiceOperationRankingEmployeesNumber from "@/components/home/HomeServiceOperationRankingEmployeesNumber.vue";
import HomeServiceOperationRankingServiceTotalFee from "@/components/home/HomeServiceOperationRankingServiceTotalFee.vue";
export default {
  components: {
    Title,
    Select,
    Nav,
    HomeServiceOperationRankingServiceElderNumber,
    HomeServiceOperationRankingServiceOrderNumber,
    HomeServiceOperationRankingEmployeesNumber,
    HomeServiceOperationRankingServiceTotalFee,
  },
  mixins: [LayoutBase],
  data() {
    return {
      current_year: new Date().getFullYear(),
      components: [
        {
          key: "HomeServiceOperationRankingServiceElderNumber",
          title: "年末服务长者总数",
        },
        {
          key: "HomeServiceOperationRankingServiceOrderNumber",
          title: "年度服务订单总数",
        },
        {
          key: "HomeServiceOperationRankingEmployeesNumber",
          title: "年末从业人数总数",
        },
        {
          key: "HomeServiceOperationRankingServiceTotalFee",
          title: "年度服务费用总数",
        },
      ],
    };
  },
  computed: {
    yearSelecter() {
      return this.$store.state.lastFourYears;
    },
  },
  methods: {
    // 设置当前年份
    set_current_year(current_year) {
      this.current_year = current_year;
      this.$nextTick(() => {
        this.$refs.child_component.get_component_data();
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
