<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  props: {
    current_year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getAcvitityTop`,
        param: {
          year: this.current_year,
          type: "报名",
        },
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push({ 机构名: item.orgName, 活动参加人次: item.number });
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            series: [
              {
                type: "bar",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "#FFCE2B" },
                      { offset: 0, color: "#FF8500" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 20,
                },
                barMaxWidth: "20%",
              },
            ],
            title: { text: "单位：次" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {},
            yAxis: { type: "category", inverse: true },
            dataset: {
              source: [
                // { 机构名: "狮南村社区幸福院", 活动参加人次: 593 },
                // { 机构名: "大镇社区幸福院", 活动参加人次: 419 },
                // { 机构名: "狮北村社区幸福院", 活动参加人次: 353 },
                // { 机构名: "大涡塘村幸福院", 活动参加人次: 303 },
                // { 机构名: "泌冲社区幸福院", 活动参加人次: 281 },
                // { 机构名: "黄洞村社区幸福院", 活动参加人次: 274 },
                // { 机构名: "钟边社区幸福院", 活动参加人次: 256 },
                // { 机构名: "联星社区幸福院", 活动参加人次: 253 },
                // { 机构名: "曹边社区幸福院", 活动参加人次: 249 },
                // { 机构名: "联表社区幸福院", 活动参加人次: 230 },
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
