<template>
  <fragment>
    <Title title="按护理级别分析"></Title>
    <div class="layout-view withoutTitle pb0">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/org/getStatPerson",
        param: {
          idOrg: this.OrgId,
          type: "长者",
          dimension: "nurse",
        },
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.level, item.number]);
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            series: [
              {
                type: "bar",
                barMaxWidth: "20%",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(253, 81, 76, 1)" },
                      { offset: 0, color: "rgba(253, 68, 168, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
              },
            ],
            title: { text: "单位：人" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: { type: "category" },
            yAxis: {},
            dataset: {
              source: [
                // ["介助1", 89],
                // ["介助2", 31],
                // ["介护2", 110],
                // ["个性化护理", 78],
                // ["自理", 146],
                // ["介护1", 154],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
