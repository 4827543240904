export default {
    "color": ["#00b7fe", "#f02b8a", "#afde2a", "#e88410", "#094cfa", "#b430f5"],
    "backgroundColor": "#00083d",
    "textStyle": {
        "fontSize": 32
    },
    "title": {
        "textStyle": {
            "color": "#ffffff",
            "fontSize": 36
        },
        "subtextStyle": {
            "color": "#dddddd"
        }
    },
    "line": {
        "itemStyle": {
            "normal": {
                "borderWidth": "4"
            }
        },
        "lineStyle": {
            "normal": {
                "width": "3"
            }
        },
        "symbolSize": "0",
        "symbol": "circle",
        "smooth": true
    },
    "radar": {
        "itemStyle": {
            "normal": {
                "borderWidth": 12,
                "borderColor": "rgba(87,201,255,0.2)",
                "color": "rgba(87,201,255,0.8)"
            }
        },
        "lineStyle": {
            "normal": {
                "width": 5,
                "color": "rgba(13, 178, 255, 1)"
            }
        },
        "symbolSize": "4",
        "symbol": "circle",
        "smooth": true,
        "areaStyle": {
            "opacity": 0.5,
            "normal": {
                "color": "rgba(13, 178, 255, 0.49)"
            }
        }
    },
    "bar": {
        "itemStyle": {
            "normal": {
                "barBorderWidth": 0,
                "barBorderColor": "#cccccc"
            },
            "emphasis": {
                "barBorderWidth": 0,
                "barBorderColor": "#cccccc"
            }
        },
        "markPoint": {
            // "symbolSize": [120, 120],
        },
    },
    "pie": {
        "itemStyle": {
            "normal": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            },
            "emphasis": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            }
        },
        "label": {
            "formatter": "{c} ({d}%)"
        }
    },
    "scatter": {
        "itemStyle": {
            "normal": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            },
            "emphasis": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            }
        }
    },
    "boxplot": {
        "itemStyle": {
            "normal": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            },
            "emphasis": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            }
        }
    },
    "parallel": {
        "itemStyle": {
            "normal": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            },
            "emphasis": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            }
        }
    },
    "sankey": {
        "itemStyle": {
            "normal": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            },
            "emphasis": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            }
        }
    },
    "funnel": {
        "itemStyle": {
            "normal": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            },
            "emphasis": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            }
        }
    },
    "gauge": {
        "itemStyle": {
            "normal": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            },
            "emphasis": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            }
        }
    },
    "candlestick": {
        "itemStyle": {
            "normal": {
                "color": "#f02b8a",
                "color0": "#00cefc",
                "borderColor": "#f02b8a",
                "borderColor0": "#00b7fe",
                "borderWidth": "2"
            }
        }
    },
    "graph": {
        "itemStyle": {
            "normal": {
                "borderWidth": 0,
                "borderColor": "#cccccc"
            }
        },
        "lineStyle": {
            "normal": {
                "width": "1",
                "color": "#ffffff"
            }
        },
        "symbolSize": "0",
        "symbol": "circle",
        "smooth": true,
        "color": ["#00b7fe", "#f02b8a", "#afde2a", "#e88410", "#094cfa", "#b430f5"],
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#222222"
                }
            }
        }
    },
    "map": {
        "itemStyle": {
            "normal": {
                "areaColor": "rgba(6,31,120,0.7)",
                "borderColor": "#00b7fe",
                "borderWidth": 0.5
            },
            "emphasis": {
                "areaColor": "rgba(72,222,255,0.8)",
                "borderColor": "#48deff",
                "borderWidth": 1
            }
        },
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#e8e8e8"
                }
            },
            "emphasis": {
                "textStyle": {
                    "color": "#e8e8e8"
                }
            }
        }
    },
    "geo": {
        "itemStyle": {
            "normal": {
                "areaColor": "rgba(6,31,120,0.7)",
                "borderColor": "#00b7fe",
                "borderWidth": 0.5
            },
            "emphasis": {
                "areaColor": "rgba(72,222,255,0.8)",
                "borderColor": "#48deff",
                "borderWidth": 1
            }
        },
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#e8e8e8"
                }
            },
            "emphasis": {
                "textStyle": {
                    "color": "#e8e8e8"
                }
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(102,102,102,0.5)"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "#e8e8e8"
            },
            "fontSize": 32
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": ["#e6e6e6"]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(102,102,102,0.5)"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "#e8e8e8"
            },
            "fontSize": 32
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": ["#e6e6e6"]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(102,102,102,0.5)"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "#e8e8e8"
            }
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": ["#e6e6e6"]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(102,102,102,0.5)"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "#e8e8e8"
            }
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": ["#e6e6e6"]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "normal": {
                "borderColor": "#999999"
            },
            "emphasis": {
                "borderColor": "#666666"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#e8e8e8"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#cccccc",
                "width": 1
            },
            "crossStyle": {
                "color": "#cccccc",
                "width": 1
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#00b7fe",
            "width": "2"
        },
        "itemStyle": {
            "normal": {
                "color": "#00b7fe",
                "borderWidth": 1
            },
            "emphasis": {
                "color": "#00b7fe"
            }
        },
        "controlStyle": {
            "normal": {
                "color": "#00b7fe",
                "borderColor": "#00b7fe",
                "borderWidth": "1"
            },
            "emphasis": {
                "color": "#00b7fe",
                "borderColor": "#00b7fe",
                "borderWidth": "1"
            }
        },
        "checkpointStyle": {
            "color": "#f02b8a",
            "borderColor": "#f02b8a"
        },
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#e8e8e8"
                }
            },
            "emphasis": {
                "textStyle": {
                    "color": "#e8e8e8"
                }
            }
        }
    },
    "visualMap": {
        "color": ["#fa5cce", "#29ebf7"]
    },
    "dataZoom": {
        "backgroundColor": "rgba(255,255,255,0)",
        "dataBackgroundColor": "rgba(114,204,255,1)",
        "fillerColor": "rgba(114,204,255,0.2)",
        "handleColor": "#72ccff",
        "handleSize": "100%",
        "textStyle": {
            "color": "#333333"
        }
    },
    "markPoint": {
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#222222"
                }
            },
            "emphasis": {
                "textStyle": {
                    "color": "#222222"
                }
            }
        }
    },
    "$type": "Theme",
    "$props": {
        "baiduMap": {
            "editor": {
                "$type": "CodeInputEditor"
            },
            "propertyType": "simple",
            "title": "百度地图主题样式"
        },
        "baseTheme": {
            "editor": {
                "$type": "ThemeSelectEditor"
            },
            "propertyType": "simple",
            "title": "基础样式"
        },
        "extendStyle": {
            "editor": {
                "$type": "CodeInputEditor"
            },
            "propertyType": "simple",
            "title": "扩展主题样式"
        }
    },
    "hookBinders": [],
    "baiduMap": [{
        "featureType": "scenicspotslabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "scenicspotslabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "medicallabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "medicallabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "educationlabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "educationlabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "estatelabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "estatelabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "businesstowerlabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "on"
        }
    }, {
        "featureType": "companylabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "companylabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "restaurantlabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "restaurantlabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "lifeservicelabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "lifeservicelabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "shoppinglabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "shoppinglabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "carservicelabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "carservicelabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "transportationlabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "transportationlabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "financelabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "financelabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "hotellabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "hotellabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "entertainmentlabel",
        "elementType": "labels",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "entertainmentlabel",
        "elementType": "labels.icon",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "fourlevelway",
        "elementType": "geometry",
        "stylers": {
            "visibility": "on"
        }
    }, {
        "featureType": "fourlevelway",
        "elementType": "labels",
        "stylers": {
            "visibility": "on"
        }
    }, {
        "featureType": "background",
        "elementType": "geometry",
        "stylers": {
            "visibility": "off"
        }
    }, {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": {
            "color": "#96caf0ff"
        }
    }, {
        "featureType": "land",
        "elementType": "geometry",
        "stylers": {
            "color": "#f5f1dfff"
        }
    }, {
        "featureType": "green",
        "elementType": "geometry",
        "stylers": {
            "color": "#b6e28aff"
        }
    }],
    "bedscreening": {
        "emptybed": {
            "background": "linear-gradient(90deg,rgba(190,227,0,1) 0%,rgba(32,179,0,1) 100%)",
            "borderRadius": "60px"
        },
        "inhospital": {
            "background": "rgba(22, 185, 255, 0.3)",
            "borderRadius": "60px"
        },
        "newarrival": {
            "background": "linear-gradient(90deg,rgba(253,81,76,1),rgba(253,68,168,1))",
            "borderRadius": "60px"
        },
        "takeleave": {
            "background": "linear-gradient(90deg,rgba(255,132,0,1) 0%,rgba(255,209,44,1) 100%)",
            "borderRadius": "60px"
        }
    },
    "boxShadow": "inset 0px 0px 60px 20px #219AFE",
    "descriptionBorder": "4px solid green",
    "mapMessageBox": {
        "backgroundImage": "url(\"/images/elder/mapmoduleimg.png\")",
        "backgroundImage2": "url(\"/images/elder/mapmoduleimg2.png\")",
        "backgroundImage3": "url(\"/images/elder/mapmoduleimg3.png\")",
        "backgroundImage4": "url(\"/images/elder/mapmoduleimg4.png\")",
        "backgroundImage5": "url(\"/images/elder/mapmoduleimg5.png\")",
        "backgroundPosition": "center center",
        "backgroundRepeat": "no-repeat",
        "backgroundSize": "100% 100%",
        "padding": "60px"
    },
    "mapfilterbox": {
        "selected": {
            "background": "linear-gradient(90deg,rgba(0,78,255,1),rgba(41,171,254,1))",
            "borderRadius": "60px"
        },
        "unselected": {
            "background": "linear-gradient(90deg,rgba(0,78,255,0.3),rgba(41,171,254,0.3))",
            "borderRadius": "60px"
        }
    },
    "monitorList": {
        "backgroundColor": "",
        "borderRadius": "10px",
        "textBtn": {
            "backgroundColor": "#122F77",
            "border": "6px solid rgb(33, 154, 254)",
            "borderRadius": "60px"
        }
    },
    "panelBorder": "1px sold blue",
    "singlebox": {
        "selected": {
            "background": "linear-gradient(90deg,rgba(0,78,255,1),rgba(41,171,254,1))",
            "borderRadius": "60px"
        },
        "unselected": {
            "border": "8px solid rgba(33,154,254,1)",
            "borderRadius": "60px"
        }
    },
    "textColor": "#ffffff",
    "titleBackgroundImg": {
        "backgroundImage": "url(\"/images/elder/futitle_background.png\")",
        "backgroundPosition": "right",
        "backgroundRepeat": "no-repeat",
        "backgroundSize": "237px 72px",
        "paddingRight": "266px"
    },
    "titleColor": "#219AFE",
    "watchlist": {
        "background": "#122D76",
        "backgroundColor": "none",
        "border": "8px solid rgba(33,154,254,1)",
        "borderRadius": "60px",
        "watchimg": "10px"
    },
    "domclick": "pointer",
    "echartNodata": {
        "padding": "20px",
        "borderRadius": "20px",
        "backgroundColor": "rgba(255, 255, 255, 0.2)",
        "color": "rgb(255, 255, 255)"
    }
};