<template>
  <fragment>
    <Title title="历年评比排名"></Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout">
        <div class="linianpingbipaiming">
          <table border="1" cellspacing="0">
            <tr>
              <th>年度</th>
              <th>分数</th>
              <th>排名</th>
              <th>排名增长</th>
            </tr>
            <tr v-for="(item, index) in dataSource" :key="index">
              <td>{{ item.year }}</td>
              <td>{{ item.score }}</td>
              <td>{{ item.rank }}</td>
              <td>{{ item.addRank || "-" }}</td>
            </tr>
            <tr v-if="isEmpty">
              <td colspan="4" :style="{ height: '67%', position: 'relative' }">
                <EmptyData />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";

export default {
  components: { Title, EmptyData },
  mixins: [ComponentBase],
  data() {
    return {
      dataSource: [],
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getHappinessAssessmentByearList`,
        param: {
          idOrg: this.ComId,
        },
        success: (response) => {
          this.dataSource =
            response && response.data && response.data.data ? response.data.data : [];
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.linianpingbipaiming {
  width: 100%;
  height: 100%;
  table {
    width: 100%;
    height: 99%;
    tr {
      th,
      td {
        border: 1px solid @mainBlue;
        color: #fff;
        font-size: 0.18rem;
        text-align: center;
        width: 25%;
      }
    }
  }
}
</style>
