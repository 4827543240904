<template>
  <fragment>
    <Title title="服务长者情况分析">
      <template v-slot:righter>
        <div class="nav-intruduce-span lh60 mr0">总长者数 | 277</div>
      </template>
    </Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav">
      <component :is="currentComponent"></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import ServiceElderByAge from "@/components/home/ServiceElderByAge.vue";
import ServiceElderByGender from "@/components/home/ServiceElderByGender.vue";
import ServiceElderByAbility from "@/components/home/ServiceElderByAbility.vue";
import ServiceElderByPayType from "@/components/home/ServiceElderByPayType.vue";
export default {
  components: {
    Title,
    Nav,
    ServiceElderByAge,
    ServiceElderByGender,
    ServiceElderByAbility,
    ServiceElderByPayType,
  },
  mixins: [LayoutBase],
  data() {
    return {
      components: [
        {
          key: "ServiceElderByAge",
          title: "按年龄段",
        },
        {
          key: "ServiceElderByGender",
          title: "按性别",
        },
        {
          key: "ServiceElderByAbility",
          title: "按老年人能力评估标准",
        },
        {
          key: "ServiceElderByPayType",
          title: "支付类型",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped></style>
