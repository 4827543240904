<template>
  <div :class="className ? `ny-select ${className}` : 'ny-select'">
    <div :class="currentClass ? `ny-select-list ${currentClass}` : 'ny-select-list'">
      <div class="ny-select-selected" @click.stop="toggleSelect">
        {{ findSelected }}
      </div>
      <div class="ny-select-options">
        <div
          class="ny-select-option"
          v-for="(item, index) in dataArray"
          :key="index"
          @click.stop="setSelect(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
    },
    dataArray: {
      type: Array,
    },
    callBack: {
      type: Function,
    },
    default: {
      type: [String, Number],
    },
  },
  data() {
    return {
      currentClass: "",
      currentSelected: null,
    };
  },
  computed: {
    findSelected() {
      return this.currentSelected || this.default || this.dataArray[0];
    },
  },
  methods: {
    setSelect(item) {
      this.currentClass = "";
      this.currentSelected = item;
      this.callBack && this.callBack(item);
    },
    toggleSelect(type) {
      if (type == "stop") {
        this.currentClass = "";
        return;
      }
      if (this.currentClass == "active") {
        this.currentClass = "";
      } else {
        this.currentClass = "active";
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.ny-select {
  .ny-select-list {
    cursor: pointer;
    padding: 0;
    background: rgba(0, 106, 255, 1);
    border-radius: 0.1rem;
    border: 0.03rem solid rgba(0, 106, 255, 1);
    position: relative;
    .ny-select-selected {
      width: 100%;
      height: 100%;
      border-radius: 0.1rem;
      padding: 0.05rem 0.14rem 0.05rem 0.24rem;
      position: relative;
      user-select: none;
      display: block;
      position: relative;
      z-index: 2;
      font-size: @fontSizeSmall;
      color: #fff;
      &:before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 0.1rem;
        width: 0.1rem;
        height: 0.1rem;
        background: url(/images/xiala.png) no-repeat left center;
        background-size: contain;
        display: block;
        transform: translateY(-50%) rotate(-90deg);
        transition: 0.5s;
      }
    }
    .ny-select-options {
      position: absolute;
      top: 0.36rem;
      left: 0;
      opacity: 0;
      pointer-events: none;
      transition: 0.5s;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      border-radius: 0.1rem;
      padding: 0.1rem;
      z-index: 99;
      width: 100%;
      background: rgba(0, 106, 255, 0.8);
      .ny-select-option {
        font-size: 0.18rem;
        color: #fff;
        cursor: pointer;
        text-align: center;
        margin-bottom: 0.1rem;
        &:last-child {
          margin-bottom: 0rem;
        }
      }
    }
  }
  .ny-select-list.active {
    .ny-select-selected {
      &:before {
        transform: translateY(-50%);
      }
    }
    .ny-select-options {
      opacity: 1;
      pointer-events: all;
    }
  }
}
</style>
