
import VueRouter from "vue-router";
// 主窗体
import OverView from "@/views/OverView.vue"
import LoginView from "@/views/LoginView.vue"

// 用于生成路由
let appRouterArray = [
	// 默认首页
	{ path: '/', redirect: '/OverView', },
	// 主窗体
	{ path: '/OverView', component: OverView, title: '大屏主窗体' },
	{ path: '/LoginView', component: LoginView, title: '登录主窗体' },
];

var router = new VueRouter({
	// 去掉url中的#
	// mode: 'history',
	routes: [
		// { path: '/', redirect: '/home', },
		...appRouterArray
	],
})
// 路由
export default router;