<template>
  <fragment>
    <Title title="医养结合介绍"></Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout">
        <div class="yiyangjiehejieshao">
          <div class="main">
            <div :id="`events-layout-${scrollId}`">
              <div class="html-css yyjh-html" :id="scrollId" v-html="contents"></div>
            </div>
          </div>
        </div>
      </div>
      <EmptyData v-if="isEmpty" />
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";

export default {
  components: {
    Title,
    EmptyData,
  },
  mixins: [ComponentBase],
  data() {
    return {
      scrollId: createUniqueKey(12),
      autoScrollObject: null,
      contents: "",
    };
  },
  computed: {
    isEmpty() {
      return this.contents.length == 0;
    },
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgIntroduce?id_org=${this.OrgId}`,
        success: (response) => {
          this.contents = response.data.data.medicalIntroducte;
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.yiyangjiehejieshao {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .main {
    overflow: hidden;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .yyjh-html {
      overflow-y: scroll;
      // pointer-events: none;
      height: 100%;
    }
  }
}
</style>
