<template>
  <div class="components-layout nh-right-pure-pic pd0">
    <div class="picture">
      <img :src="picture" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picture: "/images/nanhaibiaozhunhuajianshe.png",
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less">
.nh-right-pure-pic {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .picture {
    width: 100%;
    max-height: 2160px;
    img {
      .setImg(contain);
      height: unset;
    }
  }
}
</style>
