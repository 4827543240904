<template>
  <fragment>
    <Title title="今日床位总览"></Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout jinrichuangweizonglan pd0">
        <div class="main">
          <!-- 总览 -->
          <ul class="nav">
            <li v-for="(item, index) in counts" :key="index">
              {{ item.name }} | {{ item.num }}
            </li>
          </ul>
          <!-- 总览 -->
          <!-- 床位信息 -->
          <div class="beds-content">
            <div :id="`events-layout-${scrollId}`">
              <ul class="beds-ul" :id="scrollId">
                <li v-for="(item, index) in dataSource" :key="index">
                  <!-- 栋，楼名 -->
                  <div class="building-name">
                    {{ item.fullName }}
                  </div>
                  <!-- 栋，楼名 -->
                  <!-- 床位列表 -->
                  <ul :class="`beds-list has-last-${item.bedList.length % 6}`">
                    <li
                      v-for="(itm, idx) in item.bedList"
                      :key="idx"
                      :class="{ empty: itm.isOccupy == '0' }"
                    >
                      <span class="bed-name"> {{ itm.bedName }}</span>
                      <span class="elder-info" v-if="itm.isOccupy == '1'">
                        <span>{{ itm.elderName }} <i /> {{ itm.sex }}</span>
                        <span></span>
                        <span>{{ itm.bedCode }}</span>
                        <span>{{ itm.idCard }}</span>
                      </span>
                    </li>
                  </ul>
                  <!-- 床位列表 -->
                </li>
              </ul>
            </div>
            <EmptyData v-if="isEmpty" />
          </div>
          <!-- 床位信息 -->
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";
export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EmptyData,
  },
  data() {
    return {
      scrollId: createUniqueKey(12),
      counts: [],
      dataSource: [],
      autoScrollObject: null,
    };
  },
  methods: {
    get_component_data() {
      this.counts = [
        { name: "床位总数", num: 0 },
        { name: "养老床位", num: 0 },
        { name: "护理床位", num: 0 },
        { name: "在院", num: 0 },
        { name: "新入住", num: 0 },
        { name: "请假", num: 0 },
        { name: "空床", num: 0 },
      ];
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgBedCount?id_org=${this.OrgId}`,
        success: (response) => {
          let response_data =
            response && response.data && response.data.data ? response.data.data : {};
          this.counts = [
            { name: "床位总数", num: response_data.allBedCount || 0 },
            { name: "养老床位", num: response_data.oldBedCount || 0 },
            { name: "护理床位", num: response_data.nurseCount || 0 },
            { name: "在院", num: response_data.isInCount || 0 },
            { name: "新入住", num: response_data.newCount || 0 },
            { name: "请假", num: response_data.leaveCount || 0 },
            { name: "空床", num: response_data.nullBedCount || 0 },
          ];
        },
      });

      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgBedList?id_org=${this.OrgId}`,
        success: (response) => {
          let dataSource = [],
            beds_temp = {};
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              if (item.fullName && item.bedName) {
                if (beds_temp[item.fullName] === undefined) {
                  // 保存当前下标
                  beds_temp[item.fullName] = dataSource.length;
                  // 新增一个楼栋数据
                  dataSource.push({ fullName: item.fullName, bedList: [] });
                }
                dataSource[beds_temp[item.fullName]]["bedList"].push(item);
              }
            });
          this.dataSource = dataSource;
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.jinrichuangweizonglan {
  display: flex;
  flex-direction: column;
  width: 100%;
  .main {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    ul.nav {
      display: flex;
      justify-content: space-between;
      padding: 0.1rem;
      li {
        background: rgba(22, 185, 255, 0.3);
        border-radius: 0.6rem;
        display: flex;
        padding: 0.1rem;
        color: #fff;
        font-size: 0.18rem;
      }
    }
    .beds-content {
      display: flex;
      flex: 1 1 auto;
      color: #fff;
      overflow: hidden;
      ul.beds-ul {
        overflow-y: scroll;
        height: 100%;
        border: 1px solid @mainBlue;
        > li {
          display: flex;
          .building-name {
            padding: 0.1rem;
            font-size: 0.18rem;
            width: 20%;
            // border-left: 1px solid transparent;
            border-top: 1px solid @mainBlue;
            border-bottom: 1px solid @mainBlue;
            border-bottom: none;
          }
          ul.beds-list {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 80%;
            li {
              height: 100px;
              width: 16.666%;
              border-right: 1px solid @mainBlue;
              border-bottom: 1px solid @mainBlue;
              position: relative;
              &:nth-child(6n-5) {
                border-left: 1px solid @mainBlue;
              }
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6) {
                border-top: 1px solid @mainBlue;
              }
              > span {
                color: #fff;
                font-size: 36px;
                > span {
                  display: block;
                  line-height: 1.5;
                }
              }
              .bed-name {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .elder-info {
                position: absolute;
                left: 50%;
                top: 100px;
                transform: translateX(-50%);
                z-index: 9;
                background: #16b9ff;
                border-radius: 10px;
                opacity: 0;
                transition: 0.5s;
                padding: 20px;
                pointer-events: none;
                i {
                  padding-right: 30px;
                }
              }
              &:hover {
                span {
                  opacity: 1;
                }
              }
              &:nth-child(6n) {
                border-right: 1px solid transparent;
                .elder-info {
                  right: 0;
                  left: unset;
                  transform: unset;
                }
              }
              &:nth-child(6n-5) {
                .elder-info {
                  left: 0;
                  transform: unset;
                }
              }
            }
            li.empty {
              background: linear-gradient(
                90deg,
                rgb(190, 227, 0) 0%,
                rgb(32, 179, 0) 100%
              );
            }
          }
          ul.beds-list.has-last-0 {
            li:nth-last-child(6),
            li:nth-last-child(5),
            li:nth-last-child(4),
            li:nth-last-child(3),
            li:nth-last-child(2),
            li:nth-last-child(1) {
              border-bottom: none;
            }
          }
          ul.beds-list.has-last-1 {
            li:nth-last-child(1) {
              border-bottom: none;
            }
          }
          ul.beds-list.has-last-2 {
            li:nth-last-child(2),
            li:nth-last-child(1) {
              border-bottom: none;
            }
          }
          ul.beds-list.has-last-3 {
            li:nth-last-child(3),
            li:nth-last-child(2),
            li:nth-last-child(1) {
              border-bottom: none;
            }
          }
          ul.beds-list.has-last-4 {
            li:nth-last-child(4),
            li:nth-last-child(3),
            li:nth-last-child(2),
            li:nth-last-child(1) {
              border-bottom: none;
            }
          }
          ul.beds-list.has-last-5 {
            li:nth-last-child(5),
            li:nth-last-child(4),
            li:nth-last-child(3),
            li:nth-last-child(2),
            li:nth-last-child(1) {
              border-bottom: none;
            }
          }
          &:first-child {
            .building-name {
              border-top: none;
            }
            ul.beds-list {
              li {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {
                  border-top: none;
                }
              }
            }
          }
          &:last-child {
            .building-name {
              border-bottom: 1px solid @mainBlue;
            }
            ul.beds-list {
              li:nth-last-child(5),
              li:nth-last-child(4),
              li:nth-last-child(3),
              li:nth-last-child(2),
              li:nth-last-child(1) {
                border-bottom: 1px solid @mainBlue;
              }
            }
          }
        }
      }
    }
  }
}
</style>
