<template>
  <fragment>
    <Title title="居家二级服务类型分析"></Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout jujiaerjifuwuleixingfenxi">
        <div class="nav">
          <Select
            className="jjejfw"
            :callBack="set_current_type_name"
            :dataArray="dataSource"
            :default="defaultValue"
          ></Select>
          <div class="counts">总订单数 | {{ orderNum }}</div>
        </div>
        <div class="charts">
          <EchartsLayout :option="option"></EchartsLayout>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";
import Select from "@/components/common/Select.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
    Select,
  },
  data() {
    return {
      option: null,
      orderNum: 0,
      dataSource: [],
      defaultValue: "陪同服务",
    };
  },
  methods: {
    set_current_type_name(data) {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/home/getOrderByType`,
        param: {
          serviceTypeName: data,
        },
        success: (response) => {
          let arr = [],
            orderNum = 0;
          response.data &&
            response.data.data &&
            response.data.data.list &&
            response.data.data.list.map &&
            response.data.data.list.map((item) => {
              arr.push([item.serviceTypeChildren, item.orderNum || 0]);
              orderNum += item.orderNum || 0;
            });

          this.orderNum = orderNum;

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            series: [
              {
                radius: ["50%", "70%"],
                type: "pie",
                label: {
                  normal: {
                    formatter: "{c|{c}}\n{hr|}\n{d|{d}%}",
                    rich: {
                      b: { align: "left", color: "#12EABE", fontSize: 35, padding: 4 },
                      c: { align: "left", color: "#fff", fontSize: 35, padding: 4 },
                      d: { align: "left", color: "#fff", fontSize: 35, padding: 4 },
                      hr: {
                        borderColor: "#12EABE",
                        borderWidth: 2,
                        height: 0,
                        width: "100%",
                      },
                    },
                  },
                },
              },
              {
                itemStyle: { normal: { color: "rgba(250,250,250,0.5)" } },
                label: { show: false },
                type: "pie",
                radius: ["66%", "70%"],
              },
            ],
            tooltip: { formatter: "{c}", textStyle: { fontSize: 60 } },
            color: [
              {
                colorStops: [
                  { color: "rgba(19, 116, 255, 1)", offset: 1 },
                  { color: "rgba(86, 36, 255, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(190, 227, 0, 1)", offset: 1 },
                  { color: "rgba(32, 179, 0, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(255, 132, 0, 1)", offset: 1 },
                  { color: "rgba(255, 209, 44, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(253, 81, 76, 1)", offset: 1 },
                  { color: "rgba(253, 68, 168, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(0, 78, 255, 1)", offset: 1 },
                  { color: "rgba(45, 198, 248, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
            ],
            dataset: {
              source: [...arr],
            },
          };
        },
      });
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getServiceTypeList`,
        success: (response) => {
          let dataSource = [];
          response &&
            response.data &&
            response.data.data &&
            response.data.data.length &&
            response.data.data.map &&
            response.data.data.map((item) => {
              item.name && dataSource.push(item.name);
            });
          this.dataSource = dataSource;
          if (this.defaultValue) {
            this.set_current_type_name(this.defaultValue);
          } else if (dataSource.length > 0) {
            this.set_current_type_name(dataSource[0]);
          }
        },
      });
    },
  },
};
</script>

<style lang="less">
.jujiaerjifuwuleixingfenxi {
  height: 100%;
  display: flex;
  flex-direction: column;
  .nav {
    width: 100%;
    height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .ny-select-list,
    .counts {
      font-size: 0.18rem;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: linear-gradient(90deg, rgb(0, 78, 255), rgb(41, 171, 254));
      border-radius: 0.3rem;
      padding: 0.1rem;
      position: relative;
      min-width: 1.7rem;
      margin: 0 0.1rem;
    }
    .ny-select-list {
      border: none;
      .ny-select-selected {
        padding: 0 0.24rem;
      }
      .ny-select-options {
        top: 0.44rem;
        background: linear-gradient(90deg, rgb(0, 78, 255), rgb(41, 171, 254));
        border-radius: 0.3rem;
        max-height: 100vh;
        overflow-y: scroll;
      }
    }
  }
  .charts {
    width: 100%;
    flex-grow: 1;
    .echarts-layout {
      height: 100%;
    }
  }
}
</style>
