<template>
  <Map
    v-if="areaData.length > 0"
    :config="config"
    :areaData="areaData"
    :renderAreaData="renderAreaData"
    mapType="入库供应商"
    className="fws-baidu-map"
  ></Map>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Map from "@/components/common/Map.vue";
import MapBase from "@/components/mixin/MapBase.vue";
export default {
  mixins: [MapBase, ComponentBase],
  components: { Map },
  data() {
    return {
      areaData: [],
    };
  },
  methods: {
    renderAreaData(item, index) {
      return `
        <div class="area-info area-info-${index}">
            <p class="t">${item.divisionName}</p>
            <p class="d">居家养老服务供应商数：${item.allNum}（${item.orgProportion}）</p>
            <p class="d">居家养老服务长者人数：${item.elderNum}（${item.elderProportion}）</p>
            <p class="d">其中政府补贴长者人数：${item.subsidyNum}</p>
        </div>
      `;
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `home/getOrderAndElderByTown`,
        success: (response) => {
          let areaData = [];
          response &&
            response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              areaData.push({
                divisionName: item.divisionName,
                allNum: item.allNum || 0,
                orgProportion: item.orgProportion || "0%",
                elderNum: item.elderNum || 0,
                elderProportion: item.elderProportion || "0%",
                subsidyNum: item.subsidyNum || 0,
              });
            });
          this.areaData = areaData;
        },
      });
    },
  },
};
</script>
<style lang="less"></style>
