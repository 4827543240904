<template>
  <fragment>
    <Title title="社区长者情况分析">
      <template v-slot:righter>
        <div class="nav-intruduce-span lh60 mr0">总长者数 | {{ elderNum || 0 }}</div>
      </template>
    </Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav">
      <component :is="currentComponent"></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";

import CommunityElderByAge from "@/components/com/CommunityElderByAge.vue";
import CommunityElderByGender from "@/components/com/CommunityElderByGender.vue";
import CommunityElderByAbility from "@/components/com/CommunityElderByAbility.vue";
import CommunityElderByInterval from "@/components/com/CommunityElderByInterval.vue";
export default {
  components: {
    Title,
    Nav,
    CommunityElderByAge,
    CommunityElderByGender,
    CommunityElderByAbility,
    CommunityElderByInterval,
  },
  mixins: [LayoutBase, ComponentBase],
  data() {
    return {
      elderNum: 0,
      components: [
        {
          key: "CommunityElderByAge",
          title: "按年龄段",
        },
        {
          key: "CommunityElderByGender",
          title: "按性别",
        },
        {
          key: "CommunityElderByAbility",
          title: "按老年人能力评估标准",
        },
        {
          key: "CommunityElderByInterval",
          title: "按高龄津贴认定时隔",
        },
      ],
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getComElderCountByOrg`,
        param: {
          idOrg: this.ComId,
        },
        success: (response) => {
          this.elderNum = response.data.data.elderNum || 0;
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
