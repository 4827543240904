<template>
  <fragment>
    <Title title="社区人员风采"></Title>
    <div class="layout-view withoutTitle">
      <PersonnelIntroduction :person_data="person_data"></PersonnelIntroduction>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import PersonnelIntroduction from "@/components/common/PersonnelIntroduction.vue";
export default {
  mixins: [ComponentBase],
  components: {
    Title,
    PersonnelIntroduction,
  },
  data() {
    return {
      person_data: {},
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getOrgIntroduce?id_org=${this.ComId}`,
        success: (response) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.staff
          ) {
            this.person_data = {
              name: response.data.data.staff.staffName,
              post: response.data.data.staff.post,
              photo: response.data.data.staff.workerPhoto,
              years: response.data.data.staff.workYears,
              description: response.data.data.staff.personnelIntroduce,
            };
          } else {
            this.person_data = {};
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
