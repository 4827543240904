<template>
  <fragment>
    <Title title="按补贴类型分析"></Title>
    <div class="layout-view withoutTitle pb0">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getElderSubsidy?id_org=${this.OrgId}`,
        success: (response) => {
          if (response.data && response.data.data) {
            // 更新配置
            this.option = {
              grid: { containLabel: true },
              series: [
                {
                  type: "bar",
                  barMaxWidth: "40%",
                  showItemStyle: true,
                  itemStyle: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 1,
                      y2: 0,
                      colorStops: [
                        { offset: 1, color: "rgba(253, 81, 76, 1)" },
                        { offset: 0, color: "rgba(253, 68, 168, 1)" },
                      ],
                      globalCoord: false,
                    },
                    borderRadius: 50,
                  },
                },
              ],
              title: { text: "单位：人" },
              tooltip: { textStyle: { fontSize: 30 } },
              xAxis: { type: "category" },
              yAxis: {},
              dataset: {
                source: [
                  ["name", "长者数"],
                  ["特困供养人员", response.data.data.subsidyNum],
                  ["自费长者", response.data.data.ownNum],
                ],
              },
            };
          }
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
