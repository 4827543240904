<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: "/org/getOrgNatureSort",
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.orgNature, item.bedNum, item.residentNum]);
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            title: { text: "单位：人/个" },
            series: [
              {
                type: "bar",
                barMaxWidth: "10%",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(255, 132, 0, 1)" },
                      { offset: 0, color: "rgba(255, 209, 44, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
              },
              {
                type: "bar",
                barMaxWidth: "10%",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(253, 81, 76, 1)" },
                      { offset: 0, color: "rgba(253, 68, 168, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: { type: "category" },
            yAxis: {},
            legend: {
              show: true,
              height: "40%",
              width: "100%",
              itemWidth: 50,
              itemHeight: 50,
            },
            dataset: {
              source: [
                ["性质", "床位数", "入住长者数"],
                ...arr,
                // ["公办", 2846, 2204],
                // ["公建民营", 0, 0],
                // ["民办非营利", 1032, 558],
                // ["民办企业", 1811, 1021],
              ],
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
