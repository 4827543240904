<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest, sortAge } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/com/getElderTypeList",
        param: {
          idOrg: this.ComId,
          selectType: "age",
        },
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.age, item.elderNum]);
            });

          // 排序
          arr.sort(sortAge);

          // 设置边界值
          // indicatorArr = this.setAndicatorArrMax(indicatorArr, max);

          // // 一
          // const sexArr = [
          //   { 男: response.data.data.maleNumber || 0 },
          //   { 女: response.data.data.femaleNumber || 0 },
          // ];
          // // 二
          // const sexArr = [{ 男: 0 }, { 女: 0 }];

          // response.data.data.length &&
          //   response.data.data.map((item) => {
          //     if (item.sex === "男") {
          //       sexArr[0]["男"] = item.number;
          //     } else if (item.sex === "女") {
          //       sexArr[1]["女"] = item.number;
          //     }
          //   });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            series: [
              {
                radius: ["48%", "65%"],
                type: "pie",
                label: {
                  normal: {
                    formatter: "{c|{c}}\n{hr|}\n{d|{d}%}",
                    rich: {
                      b: { align: "left", color: "#12EABE", fontSize: 35, padding: 4 },
                      c: { align: "left", color: "#fff", fontSize: 35, padding: 4 },
                      d: { align: "left", color: "#fff", fontSize: 35, padding: 4 },
                      hr: {
                        borderColor: "#12EABE",
                        borderWidth: 2,
                        height: 0,
                        width: "100%",
                      },
                    },
                  },
                },
              },
              {
                itemStyle: { normal: { color: "rgba(250,250,250,0.5)" } },
                label: { show: false },
                type: "pie",
                radius: ["61%", "65%"],
              },
            ],
            tooltip: { formatter: "{c}", textStyle: { fontSize: 60 } },
            color: [
              {
                colorStops: [
                  { color: "rgba(19, 116, 255, 1)", offset: 1 },
                  { color: "rgba(86, 36, 255, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(190, 227, 0, 1)", offset: 1 },
                  { color: "rgba(32, 179, 0, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(255, 132, 0, 1)", offset: 1 },
                  { color: "rgba(255, 209, 44, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(253, 81, 76, 1)", offset: 1 },
                  { color: "rgba(253, 68, 168, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(0, 78, 255, 1)", offset: 1 },
                  { color: "rgba(45, 198, 248, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
            ],
            legend: {
              bottom: 0,
              show: true,
              height: "40%",
              width: "100%",
              itemWidth: 50,
              itemHeight: 50,
            },
            dataset: {
              source: [
                // ["20-29岁", 29],
                // ["30-39岁", 34],
                // ["40-49岁", 53],
                // ["50-59岁", 55],
                // ["60-69岁", 2],
                ...arr,
              ],
            },
          };
          // this.option = {
          //   legend: { show: false },
          //   radar: {
          //     axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
          //     shape: "polygon",
          //     splitArea: { areaStyle: { color: "transparent" } },
          //     splitLine: {
          //       lineStyle: {
          //         color: [
          //           "#6b6b6b",
          //           "#6b6b6b",
          //           "#6b6b6b",
          //           "#6b6b6b",
          //           "#6b6b6b",
          //           "#6b6b6b",
          //         ],
          //         width: 1,
          //       },
          //     },
          //     name: { textStyle: { color: "#ffffff" } },
          //     indicator: [
          //       // { name: "80-89岁", max: 23 },
          //       // { name: "100岁以上", max: 23 },
          //       // { name: "90-99岁", max: 23 },
          //       ...indicatorArr,
          //     ],
          //   },
          //   series: [
          //     // {
          //     //   type: "custom",
          //     //   name: "按年龄",
          //     //   silent: true,
          //     //   coordinateSystem: "polar",
          //     //   data: [0],
          //     // },
          //     {
          //       areaStyle: { normal: { color: "transparent" } },
          //       itemStyle: {
          //         normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 },
          //       },
          //       lineStyle: { normal: { width: 5 } },
          //       symbol: "circle",
          //       type: "radar",
          //       // data: [{ value: [23, 1, 20], name: "按年龄" }],
          //       data: [{ value: [...valueArr], name: "按年龄" }],
          //     },
          //   ],
          //   tooltip: { textStyle: { fontSize: 30 } },
          //   color: ["#16B9FF"],
          //   polar: { radius: "75%", center: ["50%", "50%"] },
          //   angleAxis: {
          //     type: "category",
          //     clockwise: false,
          //     boundaryGap: false,
          //     splitLine: { show: false },
          //     axisLine: { show: false },
          //   },
          //   radiusAxis: {
          //     type: "value",
          //     max: 100,
          //     splitLine: { show: false },
          //     axisTick: { show: false },
          //     axisLabel: { show: false },
          //   },
          // };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
