<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  props: {
    current_year: {
      type: Number,
    },
    current_fly: {
      type: Object,
    },
  },
  data() {
    return {
      option: null,
      avgData: [],
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getAssessmentAvg`,
        param: {
          year: this.current_year,
        },
        success: (response) => {
          if (response.data && response.data.data) {
            let arr = [];
            arr.push({
              value: [
                response.data.data.operationScore || 0,
                response.data.data.businessScore || 0,
                response.data.data.financialScore || 0,
                response.data.data.brandBuildScore || 0,
                response.data.data.safetyScore || 0,
                response.data.data.businessScore || 0,
              ],
              name: "平均值",
            });
            this.avgData = arr;

            // if(this.current_fly &&this.current_fly.idOrg) {

            // }
            // 获取分机构数据
            getCacheOrRequest.call(this, {
              method: "post",
              url: `/com/getAssessmentAvg`,
              param: {
                idOrg: this.current_fly.idOrg,
                year: this.current_year,
              },
              success: (response) => {
                if (response.data && response.data.data) {
                  let arr = [];

                  if (this.current_fly && this.current_fly.idOrg) {
                    arr.push({
                      value: [
                        response.data.data.operationScore || 0,
                        response.data.data.businessScore || 0,
                        response.data.data.financialScore || 0,
                        response.data.data.brandBuildScore || 0,
                        response.data.data.safetyScore || 0,
                        response.data.data.businessScore || 0,
                      ],
                      name: this.current_fly.orgName,
                    });
                  }
                  // 更新配置
                  this.option = {
                    legend: {
                      show: true,
                      height: "40%",
                      width: "100%",
                      itemWidth: 50,
                      itemHeight: 50,
                    },
                    radar: {
                      radius: "70%",
                      shape: "polygon",
                      name: { textStyle: { color: "#ffffff" } },
                      indicator: [
                        { max: 100, name: "运营管理" },
                        { max: 100, name: "业务成效" },
                        { max: 100, name: "财务管理" },
                        { max: 100, name: "品牌建设" },
                        { max: 100, name: "安全管理" },
                        { max: 100, name: "社会评价" },
                      ],
                    },
                    series: [
                      {
                        areaStyle: { normal: { color: "transparent" } },
                        itemStyle: {
                          normal: {
                            borderColor: "rgba(87,201,255,0.2)",
                            borderWidth: 12,
                          },
                        },
                        lineStyle: { normal: { width: 5 } },
                        symbol: "circle",
                        type: "radar",
                        data: [
                          // { value: [13.66, 31.12, 9.93, 9.18, 14.87, 9.93], name: "平均值" },
                          // { value: [16.5, 39, 10, 10, 16, 9], name: "联星社区幸福院" },
                          ...this.avgData,
                          ...arr,
                        ],
                      },
                    ],
                    tooltip: {},
                  };
                }
              },
            });
          }
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
