<template>
  <fragment>
    <Title title="日常活动与新闻资讯"></Title>
    <div class="layout-view withoutTitle">
      <div class="huodongyuzixun">
        <div class="main">
          <ul class="nav">
            <li
              v-for="(item, index) in dataSource"
              :class="[activeIndex == index ? 'active' : '']"
              :key="index"
              @click="changeHDZX(index)"
            >
              {{ item.newsTitle }}
            </li>
          </ul>
          <div class="hdzx-content">
            <div :id="`events-layout-${scrollId}`">
              <ul class="hdzx-ul" :id="scrollId">
                <li
                  class="html-css"
                  v-for="(item, index) in dataSource"
                  v-html="item.newsContent"
                  v-show="activeIndex == index"
                  :key="index"
                ></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <EmptyData v-if="isEmpty" />
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EmptyData,
  },
  data() {
    return {
      dataSource: [],
      activeIndex: 0,
      scrollId: createUniqueKey(12),
      autoScrollObject: null,
    };
  },
  methods: {
    // 点击事件
    changeHDZX(index) {
      if (this.activeIndex != index) {
        this.activeIndex = index;
        this.autoScrollObject && this.autoScrollObject.setScrollBegin().resetScroll();
      }
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/org/getOrgNewsList",
        param: {
          idOrg: this.OrgId,
          newsTypeName: "新闻",
        },
        success: (response) => {
          let dataSource = [];
          response &&
            response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              let newsItem = { ...item };
              newsItem.newsContent = newsItem.newsContent.replace(
                /\/build\/upload\//g,
                "https://zf.e-health100.com/build/upload/"
              );
              dataSource.push(newsItem);
            });
          this.dataSource = dataSource;
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.huodongyuzixun {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .main {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    ul.nav {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 25%;
      li {
        background: #112e74;
        border-radius: 0.1rem 0rem 0rem 0.1rem;
        padding: 0.1rem;
        color: #fff;
        font-size: 0.18rem;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        margin-bottom: 20px;
        &:after {
          content: "";
          position: absolute;
          display: block;
          right: -0.5rem;
          width: 1rem;
          top: 0;
          height: 100%;
          background: #112e74;
          z-index: -1;
        }
      }
      li.active {
        background: #105fff;
        &:after {
          background: #105fff;
        }
      }
    }
    .hdzx-content {
      width: 75%;
      flex: 1 1 auto;
      display: flex;
      flex: 1 1 auto;
      color: #fff;
      overflow: hidden;
      border-radius: 0 0.1rem 0.1rem 0;
      ul.hdzx-ul {
        overflow-y: scroll;
        // pointer-events: none;
        height: 100%;
        li {
          padding: 0.2rem;
          background: #105fff;
        }
      }
    }
  }
}
</style>
<style lang="less">
.hdzx-ul {
  .html-css {
    img {
      width: 100% !important;
      max-width: 100%;
      display: block;
    }
  }
}
</style>
