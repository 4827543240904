<template>
  <div class="tesefuwu">
    <div class="pdf">
      <Pdf :url="url" ref="pdf" className="tesefuwu-pdf" :callBack="callBack"></Pdf>
    </div>
    <div class="nav">
      <ul>
        <li @click="setPage(item.page)" v-for="(item, index) in nav" :key="index">
          {{ item.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Pdf from "@/components/common/Pdf.vue";
export default {
  components: { Pdf },
  data() {
    return {
      url: "/pdf/tsfw.pdf",
      nav: [
        {
          title: "政策法规",
          page: 1,
        },
        {
          title: "长者教育",
          page: 2,
        },
        {
          title: "健康美食",
          page: 3,
        },
        {
          title: "预防检测",
          page: 4,
        },
        {
          title: "休闲旅游",
          page: 5,
        },
        {
          title: "孝道文化",
          page: 6,
        },
      ],
    };
  },
  methods: {
    setPage(page) {
      this.$refs.pdf.linkClicked(page);
    },
    callBack(type) {
      if (type == "pageLoaded") {
        setTimeout(() => {
          let as = document
            .getElementsByClassName("tesefuwu-pdf")[0]
            .getElementsByTagName("a");
          const that = this;
          for (let i = 0; i < as.length; i++) {
            const href = as[i].href;
            as[i].addEventListener("click", function () {
              that.$store.commit("setCommonValue", [
                {
                  k: "currentMiddleModule",
                  v: "BSIframe",
                },
                {
                  k: "currentIframeUrl",
                  v: href,
                },
              ]);
            });
            as[i].href = "#";
          }
        }, 1000);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.tesefuwu {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @mainBackGroundColor;
  .pdf {
    width: 1400px;
    // 现场会修改2会影响原来的
    // width: 90%;
    height: 100%;
    .tesefuwu-pdf {
      width: 1400px;
      height: 100%;
      // 现场会修改2会影响原来的
      // width: 100%;
      span {
        display: flex !important;
        align-items: center;
      }
    }
  }
  .nav {
    width: calc(~"(100% - 1440px)");
    height: 100%;
    // 现场会修改2会影响原来的
    // width: 10%;
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      li {
        width: 100%;
        border-radius: @mainButtonBorderRadius;
        background: @mainGradientColor;
        font-size: @fontSizeSmall;
        font-weight: 700;
        color: #fff;
        text-align: center;
        cursor: pointer;
        padding: 0.15rem 0;
      }
    }
  }
}
</style>
