<template>
  <div :class="className ? `ny-title ${className}` : 'ny-title'">
    <span @dblclick="titleClick" :class="withLine ? 'with-line' : ''">{{
      title || ""
    }}</span>
    <slot name="righter"></slot>
  </div>
</template>

<script>
export default {
  props: ["title", "className", "withLine", "clickable"],
  methods: {
    titleClick() {
      this.$emit("clickComp");
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.ny-title {
  user-select: none;
  height: @mainTitleHeight;
  display: flex;
  align-items: center;
  line-height: 1;
  width: 100%;
  justify-content: space-between;

  span {
    position: relative;
    color: @mainBlue;
    font-size: @fontSizeMiddle;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      background: url(/images/futitle_background.png) no-repeat;
      background-size: contain;
      right: -440px;
      top: 50%;
      width: 400px;
      height: 100%;
      background-size: contain;
      transform: translateY(-50%);
    }
  }

  span.with-line {
    padding-left: 40px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      background-color: rgb(33, 154, 254);
      border-radius: 20px;
      height: 100%;
      position: absolute;
      width: 12px;
    }
  }
}
.ny-title.ny-title-small {
  span {
    font-size: 48px;
  }
}
.ny-title.ny-title-small.mt {
  margin-top: 20px;
}
</style>
