<template>
  <fragment>
    <Title title="按岗位类型分析" className="ny-title-small"></Title>
    <div class="layout-view withoutTitle pb0">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/org/getStatPerson",
        param: {
          idOrg: this.OrgId,
          type: "工作人员",
          dimension: "post",
        },
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.level, item.number]);
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            series: [
              {
                type: "bar",
                showItemStyle: true,
                barMaxWidth: "40%",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(19, 116, 255, 1)" },
                      { offset: 0, color: "rgba(86, 36, 255, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                borderRadius: 50,
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: { type: "category" },
            yAxis: {},
            dataset: {
              source: [
                // ["专业技能人员", 37],
                // ["其他", 12],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
