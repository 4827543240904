<template>
  <Map
    v-if="areaData.length > 0"
    :config="config"
    :areaData="areaData"
    :divisionData="divisionData"
    :renderAreaData="renderAreaData"
    className="fs-baidu-map"
  ></Map>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import Map from "@/components/common/Map.vue";
import MapBase from "@/components/mixin/MapBase.vue";
import ComponentBase from "@/components/mixin/ComponentBase.vue";

export default {
  mixins: [MapBase, ComponentBase],
  components: { Map },
  data() {
    return {
      config: {
        centerLng: 112.910145,
        centerLat: 23.152663,
        zoom: 12,
      },
      divisionData: [
        {
          divisionName: "全市",
          id: "all",
        },
        {
          divisionName: "禅城区",
          id: "cc",
          // data: [
          //   {
          //     title: "祖庙",
          //   },
          //   {
          //     title: "季华",
          //   },
          // ],
        },
        {
          divisionName: "南海区",
          id: "nh",
        },
        {
          divisionName: "顺德区",
          id: "sd",
        },
        {
          divisionName: "高明区",
          id: "gm",
        },
        {
          divisionName: "三水区",
          id: "ss",
        },
      ],
      areaData: [],
    };
  },
  methods: {
    renderAreaData(item, index) {
      return `
        <div class="area-info area-info-${index}">
          <p class="t">${item.divisionName}${item.allElderNum || 0}长者</p>
          <p class="d">养老机构：${item.orgNum || 0}家</p>
          <p class="d">社区养老场所：${item.comOrgNum || 0}家</p>
          <p class="d">入库供应商：${item.homeOrgNum || 0}家</p>
          <p class="d">居家养老服务长者：${item.serviceElderNum || 0}人</p>
        </div>
      `;
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `nh/getElderNumListByArea`,
        success: (response) => {
          this.areaData = [
            {
              divisionName: "南海区",
              allElderNum:
                response.data.data.allElderNum > 10000
                  ? `${response.data.data.allElderNum / 10000}万`
                  : response.data.data.allElderNum === 0
                  ? "0万"
                  : response.data.data.allElderNum || "0万",
              orgNum: response.data.data.orgNum,
              comOrgNum: response.data.data.comOrgNum,
              homeOrgNum: response.data.data.homeOrgNum,
              serviceElderNum: 0,
              location: { lng: 113.176619, lat: 23.290158 },
              offset: [-400, -1350],
              color: "rgb(253, 65, 95)",
            },
            {
              divisionName: "禅城区",
              allElderNum: "0万",
              orgNum: 0,
              comOrgNum: 0,
              homeOrgNum: 0,
              serviceElderNum: 0,
              location: { lng: 113.263144, lat: 23.049082 },
              offset: [300, -600],
              color: "rgb(165, 227, 0)",
            },
            {
              divisionName: "顺德区",
              allElderNum: "0万",
              orgNum: 0,
              comOrgNum: 0,
              homeOrgNum: 0,
              serviceElderNum: 0,
              location: { lng: 113.371515, lat: 22.780115 },
              offset: [this.bsType == 1 ? 100 : -100, 100],
              color: "rgb(3, 226, 228)",
            },
            {
              divisionName: "高明区",
              allElderNum: "0万",
              orgNum: 0,
              comOrgNum: 0,
              homeOrgNum: 0,
              serviceElderNum: 0,
              location: { lng: 112.60429, lat: 22.875913 },
              offset: [this.bsType == 1 ? -1300 : -700, 350],
              color: "rgb(79, 44, 255)",
            },
            {
              divisionName: "三水区",
              allElderNum: "0万",
              orgNum: 0,
              comOrgNum: 0,
              homeOrgNum: 0,
              serviceElderNum: 0,
              location: { lng: 112.833394, lat: 23.574521 },
              offset: [this.bsType == 1 ? -1100 : -900, -100],
              color: "rgb(139, 13, 255)",
            },
          ];
        },
      });
    },
  },
};
</script>
<style lang="less"></style>
