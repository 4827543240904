<template>
  <div id="score-stars" class="score-stars">
    <span
      v-for="(item, index) in !isNaN(score) && pure ? score : 5"
      :key="index"
      :class="score >= item ? 'active' : ''"
      >{{ item }}</span
    >
  </div>
</template>

<script>
export default {
  props: ["score", "pure"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less">
.score-stars {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > span {
    text-indent: -99px;
    overflow: hidden;
    width: 20%;
    padding-top: 20%;
    max-width: 0.2rem;
    max-height: 0.2rem;
    background: url(/images/star.png) no-repeat center;
    background-size: contain;
    margin-right: 0.05rem;
    transition: 0.5s;
    &:last-child {
      margin-right: 0;
    }
  }
  span.active {
    background: url(/images/star-a.png) no-repeat center;
    background-size: contain;
  }
}
</style>
