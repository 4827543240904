<template>
  <fragment>
    <div class="left-fixed">
      <div class="left-hover-btn">本地户籍 | {{ localNum }}</div>
      <div class="left-hover-btn">非本地户籍 | {{ unLocalNum }}</div>
    </div>
    <EchartsLayout :option="option"></EchartsLayout>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  props: {
    set_elder_num: {
      type: Function,
    },
  },
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      localNum: 0,
      unLocalNum: 0,
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/org/getElderTypeList",
        param: {
          idOrg: this.OrgId,
          selectType: "household",
        },
        success: (response) => {
          let jsonSample = {
              "60岁以下": 0,
              "60-70岁": 1,
              "70-80岁": 2,
              "80-90岁": 3,
              "90-100岁": 4,
              "100岁以上": 5,
            },
            indicatorArr = [
              { name: "60岁以下" },
              { name: "60-70岁" },
              { name: "70-80岁" },
              { name: "80-90岁" },
              { name: "90-100岁" },
              { name: "100岁以上" },
            ],
            localArr = [0, 0, 0, 0, 0, 0],
            unLocalArr = [0, 0, 0, 0, 0, 0],
            tempArr = {},
            localNum = 0,
            unLocalNum = 0,
            elderNum = 0,
            max = 0;
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              elderNum += item.number;
              if (item.number > max) {
                max = item.number;
              }
              // if (tempArr[item.age] === undefined) {
              tempArr[item.age] = localArr.length;
              // indicatorArr.push({ name: item.age });
              if (item.selectType == "本地户籍") {
                localNum += item.number;
                // localArr.push(item.number);
                // unLocalArr.push(0);
                if (jsonSample[item.age]) {
                  localArr[jsonSample[item.age]] += item.number || 0;
                }
              } else {
                unLocalNum += item.number;
                // localArr.push(0);
                // unLocalArr.push(item.number);
                if (jsonSample[item.age]) {
                  unLocalArr[jsonSample[item.age]] += item.number || 0;
                }
              }
              // } else {
              //   if (item.selectType == "本地户籍") {
              //     localNum += item.number;
              //     unLocalArr[tempArr[item.age]] = item.number;
              //   } else {
              //     unLocalNum += item.number;
              //     localArr[tempArr[item.age]] = item.number;
              //   }
              // }
            });

          // 非本地户籍
          this.unLocalNum = unLocalNum;
          // 本地户籍
          this.localNum = localNum;

          // 设置边界值
          indicatorArr = this.setAndicatorArrMax(indicatorArr, max);

          // 设置长者总数
          this.set_elder_num && this.set_elder_num(elderNum);

          // 更新配置
          this.option = {
            legend: {},
            radar: {
              axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
              shape: "polygon",
              splitArea: { areaStyle: { color: "transparent" } },
              splitLine: {
                lineStyle: {
                  color: [
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                  ],
                  width: 1,
                },
              },
              name: { textStyle: { color: "#ffffff" } },
              indicator: [
                // { name: "60-69岁", max: 400 },
                // { name: "70-79岁", max: 400 },
                // { name: "80-89岁", max: 400 },
                // { name: "90-99岁", max: 400 },
                // { name: "100岁以上", max: 400 },
                // { name: "60岁以下", max: 400 },
                ...indicatorArr,
              ],
            },
            series: [
              // {
              //   type: "custom",
              //   name: "本地户籍",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              // {
              //   type: "custom",
              //   name: "非本地户籍",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              {
                areaStyle: { normal: { color: "transparent" } },
                itemStyle: {
                  normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 },
                },
                lineStyle: { normal: { width: 5 } },
                symbol: "circle",
                type: "radar",
                data: [
                  {
                    // value: [17, 117, 347, 135, 7, 4],
                    value: [...localArr],
                    name: "本地户籍",
                  },
                  {
                    // value: [6, 44, 199, 63, 3, 3],
                    value: [...unLocalArr],
                    name: "非本地户籍",
                  },
                ],
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            color: ["#16B9FF"],
            polar: { radius: "75%", center: ["50%", "50%"] },
            angleAxis: {
              type: "category",
              clockwise: false,
              boundaryGap: false,
              splitLine: { show: false },
              axisLine: { show: false },
            },
            radiusAxis: {
              type: "value",
              max: 100,
              splitLine: { show: false },
              axisTick: { show: false },
              axisLabel: { show: false },
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less"></style>
