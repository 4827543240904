<template>
  <div class="picture-layout">
    <img :src="pictureUrl" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      pictureUrl: "",
    };
  },
  watch: {
    "$store.state.currentPictureUrl": {
      deep: true,
      handler: function (url) {
        if (url) {
          this.pictureUrl = url;
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.picture-layout {
  width: 100%;
  height: 100%;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>
