<template>
  <fragment>
    <Title title="长者增长趋势" className="ny-title-small mt"></Title>
    <div class="layout-view withoutTitle">
      <PlatformElderIncreaseTendencyChart></PlatformElderIncreaseTendencyChart>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import PlatformElderIncreaseTendencyChart from "@/components/nh/PlatformElderIncreaseTendencyChart.vue";
export default {
  components: {
    Title,
    PlatformElderIncreaseTendencyChart,
  },
  data() {
    return {};
  },
  methods: {},
  beforeMount() {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
