<template>
  <fragment>
    <Title title="服务产品列表">
      <template v-slot:righter>
        <div class="nav-intruduce-span lh60 mr0">
          服务产品总数 | {{ dataSource.length || 0 }}
        </div>
      </template>
    </Title>
    <div class="layout-view withoutTitle pb0">
      <div class="components-layout fuwuchanpin">
        <div :id="`events-layout-${scrollId}`">
          <ul class="fwcp-ul" :id="scrollId">
            <li v-for="(item, index) in dataSource" :key="index">
              <div class="picture">
                <img
                  :src="`${
                    item.productImg ? `${imgUrl}${item.productImg}` : `${defaultPicture}`
                  }`"
                />
              </div>
              <div class="content">
                <p>{{ item.porductName }}</p>
                <p>服务类型：{{ item.serviceTypeName || "服务套餐" }}</p>
                <p>服务次数：{{ item.saleCount || 0 }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <EmptyData v-if="isEmpty" />
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";
import { imgUrl } from "@/utils/config.js";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EmptyData,
  },
  data() {
    return {
      imgUrl,
      scrollId: createUniqueKey(12),
      dataSource: [],
      defaultPicture: "/images/product.jpg",
      autoScrollObject: null,
      scrollDirection: "scrollLeft",
    };
  },
  methods: {
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/home/getHomePorductList`,
        param: {
          idOrg: this.HomeId,
        },
        success: (response) => {
          this.dataSource =
            response && response.data && response.data.data ? response.data.data : [];
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.fuwuchanpin {
  display: flex;
  width: 100%;
  .fwcp-ul {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    height: 100%;
    // pointer-events: none;
    li {
      width: 30%;
      margin-right: 20px;
      flex-shrink: 0;
      background: @mainSessionBackGroundColor;
      border-radius: @mainBorderRadius;
      overflow: hidden;
      padding: @mainPadding;
      .picture {
        width: 100%;
        padding-top: 100%;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          .setImg(cover);
        }
      }
      .content {
        p {
          font-weight: 700;
          color: #fff;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          line-height: @mainLineHeight;
          font-size: @fontSizeMiddle;
          margin-bottom: 0.1rem;
          text-align: justify;
          &:first-child {
            margin-top: 0.1rem;
            .suolve(3);
          }
          &:last-child {
            margin-bottom: 0rem;
          }
        }
      }
    }
  }
}
</style>
