<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  props: {
    set_elder_num: {
      type: Function,
    },
  },
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/org/getElderTypeList",
        param: {
          idOrg: this.OrgId,
          selectType: "age",
        },
        success: (response) => {
          let jsonSample = {
              "60岁以下": 0,
              "60-70岁": 1,
              "70-80岁": 2,
              "80-90岁": 3,
              "90-100岁": 4,
              "100岁以上": 5,
            },
            indicatorArr = [
              { name: "60岁以下" },
              { name: "60-70岁" },
              { name: "70-80岁" },
              { name: "80-90岁" },
              { name: "90-100岁" },
              { name: "100岁以上" },
            ],
            valueArr = [0, 0, 0, 0, 0, 0],
            elderNum = 0,
            max = 0;
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              if (jsonSample[item.age]) {
                valueArr[jsonSample[item.age]] += item.number || 0;
              }
              // indicatorArr.push({ name: item.age });
              // valueArr.push(item.number);
              elderNum += item.number || 0;
              if (item.number > max) {
                max = item.number;
              }
            });

          // 设置边界值
          indicatorArr = this.setAndicatorArrMax(indicatorArr, max);

          // 设置长者总数
          this.set_elder_num && this.set_elder_num(elderNum);

          // 更新配置
          this.option = {
            legend: { data: ["按年龄段"] },
            radar: {
              axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
              shape: "polygon",
              splitArea: { areaStyle: { color: "transparent" } },
              splitLine: {
                lineStyle: {
                  color: [
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                  ],
                  width: 1,
                },
              },
              name: {
                textStyle: { color: "#ffffff" },
              },
              indicator: [
                // { name: "60-69岁", max: 600 },
                // { name: "70-79岁", max: 600 },
                // { name: "80-89岁", max: 600 },
                // { name: "90-99岁", max: 600 },
                // { name: "100岁以上", max: 600 },
                // { name: "60岁以下", max: 600 },
                ...indicatorArr,
              ],
            },
            series: [
              // {
              //   type: "custom",
              //   name: "按年龄段",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              {
                areaStyle: { normal: { color: "transparent" } },
                itemStyle: {
                  normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 },
                },
                // areaStyle: {
                //   color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //     { offset: 0, color: "rgba(0, 230, 98, 1)" },
                //     { offset: 1, color: "rgba(0, 155, 171, 1)" },
                //   ]),
                // },
                // itemStyle: {
                //   color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //     { offset: 0, color: "rgba(0, 230, 98, 1)" },
                //     { offset: 1, color: "rgba(0, 155, 171, 1)" },
                //   ]),
                // },
                lineStyle: { normal: { width: 5 } },
                symbol: "circle",
                type: "radar",
                data: [
                  {
                    // value: [23, 161, 546, 198, 10, 7],
                    value: [...valueArr],
                    name: "按年龄段",
                  },
                ],
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            color: ["#16B9FF"],
            polar: { radius: "75%", center: ["50%", "50%"] },
            angleAxis: {
              type: "category",
              clockwise: false,
              boundaryGap: false,
              splitLine: { show: false },
              axisLine: { show: false },
            },
            radiusAxis: {
              type: "value",
              max: 100,
              splitLine: { show: false },
              axisTick: { show: false },
              axisLabel: { show: false },
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
