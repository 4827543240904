<template>
  <fragment>
    <Title title="从业人员增长趋势" className="ny-title-small mt"></Title>
    <div class="layout-view withoutTitle">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";
export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/nh/getStaffAnalysisListByYear`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.year, item.number]);
            });

          // 更新配置
          this.option = {
            series: [
              {
                label: { show: true, position: "inside" },
                name: "1",
                type: "bar",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 0, color: "RGBA(191, 226, 9, 1)" },
                      { offset: 1, color: "RGBA(42, 181, 0, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
              {
                label: { show: true, position: "inside" },
                name: "2",
                type: "bar",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 1, color: "RGBA(255, 137, 4, 1)" },
                      { offset: 0, color: "RGBA(255, 208, 42, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
              {
                label: { show: true, position: "inside" },
                name: "3",
                type: "bar",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 1, color: "RGBA(254, 79, 79, 1)" },
                      { offset: 0, color: "RGBA(255, 137, 4, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
              {
                type: "line",
                itemStyle: { color: "#00b7fe" },
                lineStyle: { color: "#00b7fe" },
                encode: { x: "level", y: "养老机构" },
              },
              {
                type: "line",
                itemStyle: { color: "#f02b8a" },
                lineStyle: { color: "#f02b8a" },
                encode: { x: "level", y: "社区幸福院" },
              },
              {
                type: "line",
                itemStyle: { color: "#afde2a" },
                lineStyle: { color: "#afde2a" },
                encode: { x: "level", y: "入库供应商" },
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: { axisLine: { lineStyle: { color: "white" } }, type: "category" },
            yAxis: {
              axisLine: { lineStyle: { color: "white" } },
              boundaryGap: [0, 0.01],
              type: "value",
            },
            legend: { show: false },
            dataset: {
              source: [
                ["level", "养老机构", "社区幸福院", "入库供应商"],
                [2017, 178, 13, 54],
                [2018, 542, 339, 235],
                [2019, 615, 551, 296],
                [2020, 615, 551, 296],
                [2021, 615, 551, 296],
                [2022, 615, 551, 296],
                [2023, 615, 551, 296],
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
