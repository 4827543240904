<template>
  <fragment></fragment>
</template>

<script>
import autoScroll from "@/utils/autoScroll.js";
export default {
  methods: {
    setAndicatorArrMax(indicatorArr, max) {
      indicatorArr.map((item) => {
        item.max = max;
      });
      return indicatorArr;
    },
  },
  beforeMount() {
    // 获取组件数据
    this.get_component_data && this.get_component_data();
  },
  data() {
    return {
      // 数据源
      dataSource: [],
    };
  },
  mounted() {
    if (this.scrollId) {
      this.autoScrollObject = new autoScroll({
        elementId: this.scrollId,
        scrollDirection: this.scrollDirection || "scrollTop",
      })
        .beginScroll()
        .mouseInOrOut();
    }
  },
  beforeDestroy() {
    // 执行销毁函数
    this.autoScrollObject && this.autoScrollObject.destroy();
    this.autoScrollObject = null;
  },
  computed: {
    // 是否显示无数据
    isEmpty() {
      return this.dataSource.length == 0 ? true : false;
    },
    OrgId: {
      get() {
        return this.$store.state.OrgId;
      },
      set() {},
    },
    OrgName: {
      get() {
        return this.$store.state.OrgName;
      },
      set() {},
    },
    ComId: {
      get() {
        return this.$store.state.ComId;
      },
      set() {},
    },
    ComName: {
      get() {
        return this.$store.state.ComName;
      },
      set() {},
    },
    HomeId: {
      get() {
        return this.$store.state.HomeId;
      },
      set() {},
    },
    HomeName: {
      get() {
        return this.$store.state.HomeName;
      },
      set() {},
    },
  },
  watch: {
    "$store.state.OrgId": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.OrgId = newValue;
          this.$nextTick(() => {
            this.get_component_data && this.get_component_data();
          });
        }
      },
    },
    "$store.state.ComId": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.ComId = newValue;
          this.$nextTick(() => {
            this.get_component_data && this.get_component_data();
          });
        }
      },
    },
    "$store.state.HomeId": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.HomeId = newValue;
          this.$nextTick(() => {
            this.get_component_data && this.get_component_data();
          });
        }
      },
    },
  },
};
</script>

<style lang="less" scoped></style>
