<template>
  <div class="components-layout pd0">
    <div class="jujiafuwushangzonglan">
      <!-- 居家 -->
      <div class="section jjfws">
        <p>入库供应商</p>
        <p>{{ data.orgNum }}家</p>
      </div>
      <!-- 居家 -->

      <!-- 社区 -->
      <div class="section fwzzzs">
        <p>服务长者总数</p>
        <p>{{ data.elderNum }}人</p>
      </div>
      <div class="section cyryzs">
        <p>从业人员总数</p>
        <p>{{ data.staffNum }}人</p>
      </div>
      <div class="section ljfwdd">
        <p>累计服务订单</p>
        <p>{{ data.orderNum }}单</p>
      </div>
      <div class="section fwcps">
        <p>服务产品数</p>
        <p>{{ data.productNum }}个</p>
      </div>
      <!-- 社区 -->
    </div>
  </div>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
export default {
  mixins: [ComponentBase],
  data() {
    return {
      data: {
        // 居家服务商
        orgNum: 0,
        // 服务长者人数
        elderNum: 0,
        // 从业人员总数
        staffNum: 0,
        // 累计服务订单
        orderNum: 0,
        // 服务产品数
        productNum: 0,
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: "/home/getHomeOverview",
        success: (response) => {
          this.data = {
            orgNum: response.data.data.orgNum,
            elderNum: response.data.data.elderNum,
            staffNum: response.data.data.staffNum,
            orderNum: response.data.data.orderNum,
            productNum: response.data.data.productNum,
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.jujiafuwushangzonglan {
  position: relative;
  width: 100%;
  padding-top: 44%;
  background: #00083d url(/images/home_service.png) no-repeat center;
  background-size: contain;
  .section {
    color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20%;
    width: 16%;
    height: 36.6%;
    p {
      &:first-child {
        padding: 0 5%;
      }
    }
  }
  .jjfws {
    left: 35.6%;
    top: 4.4%;
    width: 29%;
    height: 67%;
    font-size: @fontSizeLarge;
  }
  .fwzzzs {
    left: 8.3%;
    top: 0;
  }
  .cyryzs {
    left: 16.5%;
    top: 60.2%;
  }
  .ljfwdd {
    left: 75.8%;
    top: 3%;
  }
  .fwcps {
    left: 65.4%;
    top: 63.5%;
  }
}
</style>
