<template>
  <!-- 佛山市总览主窗体内容 -->
  <div class="layout-main fss">
    <!-- 主窗体左侧 -->
    <div class="layout-main-left">
      <div class="layout-view" @dblclick="setMiddleCoverComponent('FoShanOverview')">
        <FoShanOverview></FoShanOverview>
      </div>
      <div
        class="layout-view"
        @dblclick="setMiddleCoverComponent('EachDistrictConstruction')"
      >
        <EachDistrictConstruction></EachDistrictConstruction>
      </div>
      <div
        class="layout-view"
        @dblclick="setMiddleCoverComponent('EachDistrictServingElder')"
      >
        <EachDistrictServingElder></EachDistrictServingElder>
      </div>
    </div>
    <!-- 主窗体左侧 -->

    <!-- 主窗体中部 -->
    <div class="layout-main-middle">
      <FoShanMap></FoShanMap>
      <div class="layout-view abs" v-if="middleCoverComponent">
        <span class="close" @click="closeCoverComponent"></span>
        <component :is="middleCoverComponent"></component>
      </div>
    </div>
    <!-- 主窗体中部 -->

    <!-- 主窗体右侧 -->
    <div class="layout-main-right">
      <FoShanPensionDevelopmentProcess></FoShanPensionDevelopmentProcess>
    </div>
    <!-- 主窗体右侧 -->
  </div>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import FoShanOverview from "@/components/fs/FoShanOverview.vue";

import EachDistrictConstruction from "@/components/fs/EachDistrictConstruction.vue";
import EachDistrictConstructionWelfareCentreNumber from "@/components/fs/EachDistrictConstructionWelfareCentreNumber.vue";
import EachDistrictConstructionByLocalElderNumber from "@/components/fs/EachDistrictConstructionByLocalElderNumber.vue";

import EachDistrictServingElder from "@/components/fs/EachDistrictServingElder.vue";
import EachDistrictServingElderByAge from "@/components/fs/EachDistrictServingElderByAge.vue";
import EachDistrictServingElderByGender from "@/components/fs/EachDistrictServingElderByGender.vue";
import EachDistrictServingElderByAssessment from "@/components/fs/EachDistrictServingElderByAssessment.vue";

import FoShanMap from "@/components/fs/FoShanMap.vue";
import FoShanPensionDevelopmentProcess from "@/components/fs/FoShanPensionDevelopmentProcess.vue";

import OverviewBase from "@/views/mixin/OverviewBase.vue";

export default {
  components: {
    Title,
    Nav,
    FoShanOverview,
    EachDistrictConstruction,
    EachDistrictConstructionWelfareCentreNumber,
    EachDistrictConstructionByLocalElderNumber,
    EachDistrictServingElder,
    EachDistrictServingElderByAge,
    EachDistrictServingElderByGender,
    EachDistrictServingElderByAssessment,
    FoShanMap,
    FoShanPensionDevelopmentProcess,
  },
  mixins: [OverviewBase],
};
</script>

<style lang="less" scoped></style>
