<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getOrderByTypeTopFive`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.serviceTypeChildren, item.allNum]);
            });
          // 更新配置
          this.option = {
            grid: { containLabel: true },
            // grid: { bottom: "30%", left: "10%" },
            series: [
              {
                type: "bar",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 1, color: "RGBA(44, 198, 246, 1)" },
                      { offset: 0, color: "RGBA(4, 103, 206, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                barWidth: 80,
                ecode: { x: "服务类型", y: "订单数" },
              },
            ],
            title: { text: "" },
            tooltip: {},
            xAxis: { axisLabel: { interval: 0, rotate: 40 }, type: "category" },
            yAxis: {},
            dataset: {
              source: [
                // ["居家清洁", 24408],
                // ["养生保健", 22130],
                // ["普通谈心", 21658],
                // ["日托服务", 21558],
                // ["陪同就医", 2260],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
