<template>
  <fragment>
    <Title title="活动情况分析"></Title>
    <div class="layout-view fdr withoutTitle">
      <div class="layout-view wd50 mr10">
        <ActivityFrequencyGrowthTrend></ActivityFrequencyGrowthTrend>
      </div>
      <div class="layout-view wd50 ml10">
        <ActivityParticipatingElderGrowthTrend></ActivityParticipatingElderGrowthTrend>
      </div>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import ActivityFrequencyGrowthTrend from "@/components/com/ActivityFrequencyGrowthTrend.vue";
import ActivityParticipatingElderGrowthTrend from "@/components/com/ActivityParticipatingElderGrowthTrend.vue";
export default {
  components: {
    Title,
    ActivityFrequencyGrowthTrend,
    ActivityParticipatingElderGrowthTrend,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
