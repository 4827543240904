<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  mounted() {
    // this.option = {
    //   //   grid: {
    //   //     left: 150,
    //   //   },
    //   series: [
    //     {
    //       type: "bar",
    //       barGap: "-100%",
    //       itemStyle: {
    //         color: {
    //           type: "linear",
    //           x: 0,
    //           y: 0,
    //           x2: 1,
    //           y2: 0,
    //           colorStops: [
    //             {
    //               offset: 1,
    //               color: "RGBA(252, 6, 187, 1)",
    //             },
    //             {
    //               offset: 0,
    //               color: "RGBA(197, 31, 251, 1)",
    //             },
    //           ],
    //           globalCoord: false,
    //         },
    //         borderRadius: 20,
    //       },
    //       barWidth: 40,
    //       zlevel: 5,
    //     },
    //     {
    //       type: "bar",
    //       barGap: "-100%",
    //       itemStyle: {
    //         color: {
    //           type: "linear",
    //           x: 0,
    //           y: 0,
    //           x2: 1,
    //           y2: 0,
    //           colorStops: [
    //             {
    //               offset: 1,
    //               color: "RGBA(19, 115, 255, 1)",
    //             },
    //             {
    //               offset: 0,
    //               color: "RGBA(33, 98, 255, 1)",
    //             },
    //           ],
    //           globalCoord: false,
    //         },
    //         borderRadius: 50,
    //       },
    //       barWidth: 40,
    //       zlevel: 4,
    //     },
    //     {
    //       type: "bar",
    //       barGap: "-100%",
    //       itemStyle: {
    //         color: {
    //           type: "linear",
    //           x: 0,
    //           y: 0,
    //           x2: 1,
    //           y2: 0,
    //           colorStops: [
    //             {
    //               offset: 1,
    //               color: "RGBA(192, 224, 0, 1)",
    //             },
    //             {
    //               offset: 0,
    //               color: "RGBA(146, 215, 1, 1)",
    //             },
    //           ],
    //           globalCoord: false,
    //         },
    //         borderRadius: 50,
    //       },
    //       barWidth: 40,
    //       zlevel: 3,
    //     },
    //     {
    //       type: "bar",
    //       barGap: "-100%",
    //       itemStyle: {
    //         color: {
    //           type: "linear",
    //           x: 0,
    //           y: 0,
    //           x2: 1,
    //           y2: 0,
    //           colorStops: [
    //             {
    //               offset: 1,
    //               color: "RGBA(46, 237, 250, 1)",
    //             },
    //             {
    //               offset: 0,
    //               color: "RGBA(30, 199, 237, 1)",
    //             },
    //           ],
    //           globalCoord: false,
    //         },
    //         borderRadius: 50,
    //       },
    //       barWidth: 40,
    //       zlevel: 2,
    //     },
    //     {
    //       type: "bar",
    //       stack: "背景",
    //       itemStyle: {
    //         color: "RGBA(252, 6, 187, 1)",
    //         borderRadius: 20,
    //       },
    //       barWidth: 40,
    //       barMinHeight: 50,
    //       zlevel: 5,
    //     },
    //     {
    //       type: "bar",
    //       stack: "背景",
    //       itemStyle: {
    //         color: "RGBA(19, 115, 255, 1)",
    //         borderRadius: 50,
    //       },
    //       barWidth: 40,
    //       barMinHeight: 50,
    //       zlevel: 5,
    //     },
    //     {
    //       type: "bar",
    //       stack: "背景",
    //       itemStyle: {
    //         color: "RGBA(192, 224, 0, 1)",
    //         borderRadius: 50,
    //       },
    //       barWidth: 40,
    //       barMinHeight: 50,
    //       zlevel: 5,
    //     },
    //     {
    //       type: "bar",
    //       stack: "背景",
    //       itemStyle: {
    //         color: "RGBA(46, 237, 250, 1)",
    //         borderRadius: 50,
    //       },
    //       barWidth: 40,
    //       barMinHeight: 50,
    //       zlevel: 5,
    //     },
    //   ],
    //   title: {
    //     text: "单位：人",
    //   },
    //   tooltip: {
    //     textStyle: {
    //       fontSize: 36,
    //     },
    //     trigger: "axis",
    //   },
    //   xAxis: {
    //     type: "value",
    //   },
    //   yAxis: {
    //     type: "category",
    //     data: [],
    //   },
    //   legend: {
    //     show: true,
    //   },
    //   dataset: {
    //     source: [],
    //   },
    // };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/nh/getElderNurseNumList`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.level, item.number]);
            });

          // 更新配置
          this.option = {
            series: [
              {
                type: "bar",
                name: "数量",
                barMaxWidth: "20%",
                showItemStyle: true,
                label: {
                  show: true,
                  position: "top",
                },
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(0, 97, 206, 1)" },
                      { offset: 0, color: "rgba(45, 198, 248, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {
              axisLabel: { interval: 0 },
              axisLine: { lineStyle: { color: "white" } },
              type: "category",
            },
            yAxis: {
              axisLine: { lineStyle: { color: "white" } },
              boundaryGap: [0, 0.01],
              type: "value",
            },
            dataset: {
              source: [
                ["level", "number"],
                // ["0-1年", 1495],
                // ["10年以上", 2],
                // ["5-10年", 1],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
