<template>
  <div :class="`pdf-layout ${className || ''}`">
    <pdf
      v-if="flag"
      :src="src"
      :page="page"
      @loaded="loaded"
      @page-loaded="pageLoaded"
      @link-clicked="linkClicked"
      @error="error"
    ></pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
    callBack: {
      type: Function,
    },
  },
  components: { pdf },
  data() {
    return {
      page: 1,
      src: "",
      flag: true,
    };
  },
  methods: {
    loaded() {
      this.callBack && this.callBack("loaded");
    },
    pageLoaded() {
      this.callBack && this.callBack("pageLoaded");
    },
    linkClicked($event) {
      this.page = $event;
    },
    error() {
      // 切换过快会报错，尝试重载组件解决
      this.flag = false;
      setTimeout(() => {
        this.flag = true;
      }, 500);
    },
    loadPdf(url) {
      // 用这个方法在切换组件的时候会失效
      this.src = pdf.createLoadingTask(url);
      this.src.promise.then((pdf) => {
        console.log("【PDF】", this.src, pdf);
      });
    },
  },
  mounted() {
    this.src = this.url;
    // this.url && this.loadPdf(this.url);
  },
};
</script>

<style lang="less">
.pdf-layout {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: @mainBackGroundColor;
  flex-grow: 1;
  > span {
    height: 100%;
    width: 100%;
  }
}
</style>
