<template>
  <fragment>
    <Title title="慈善项目"></Title>
    <div class="layout-view withoutTitle pb0">
      <div class="components-layout pd0">
        <div class="cishanxiangmu">
          <EmptyData />
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";
export default {
  components: {
    Title,
    EmptyData,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.components-layout {
  .cishanxiangmu {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: rgb(17, 46, 118);
    border-radius: 40px;
  }
}
</style>
