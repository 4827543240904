<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: "/org/getCheckNumTop",
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.orgName, item.subsidyNum || 19, item.ownNum]);
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            // grid: { left: 550 },
            series: [
              {
                type: "bar",
                stack: "区域",
                barGap: "-100%",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(19, 116, 255, 1)" },
                      { offset: 0, color: "rgba(86, 36, 255, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                z: 6,
                barMaxWidth: "40%",
              },
              {
                type: "bar",
                stack: "区域",
                barGap: "-100%",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(190, 227, 0, 1)" },
                      { offset: 0, color: "rgba(32, 179, 0, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                barMaxWidth: "40%",
                z: 5,
              },
              // {
              //   type: "bar",
              //   stack: "背景",
              //   itemStyle: { color: "rgba(19, 116, 255, 1)", borderRadius: 50 },
              //   barMaxWidth: "40%",
              //   z: 4,
              // },
              // {
              //   type: "bar",
              //   stack: "背景",
              //   itemStyle: { color: "rgba(190, 227, 0, 1)", borderRadius: 50 },
              //   barMaxWidth: "40%",
              //   z: 3,
              // },
            ],
            title: { text: "单位：人" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {},
            yAxis: { type: "category", inverse: true },
            legend: {
              show: true,
              height: "40%",
              width: "100%",
              itemWidth: 50,
              itemHeight: 50,
            },
            dataset: {
              source: [
                ["机构名", "特困供养人员数", "自费长者"],
                ...arr,
                // ["南海区社会福利中心", 0, 944],
                // ["广东泰成逸园养老院", 0, 852],
                // ["桂城敬老院", 0, 243],
                // ["大沥敬老院", 0, 220],
                // ["文华颐养公寓（狮山）", 0, 150],
                // ["九如城（狮山）康养中心", 0, 146],
                // ["佛山市南海区九江桃苑颐养院", 0, 139],
                // ["西樵福利中心", 0, 114],
                // ["狮山镇松岗敬老院", 0, 108],
                // ["佛山市南海区狮山镇官窑敬老院", 0, 106],
              ],
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
