<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: "/org/getStaffNumTop",
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push({ 从业人员总数: item.staffNum, 机构名: item.orgName });
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            // grid: { left: 550 },
            series: [
              {
                type: "bar",
                showItemStyle: true,
                barMaxWidth: "40%",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(255, 132, 0, 1)" },
                      { offset: 0, color: "rgba(255, 209, 44, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                encode: { x: "从业人员总数", y: "机构名" },
              },
            ],
            title: { text: "单位：人" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {},
            yAxis: { type: "category", inverse: true },
            dataset: {
              source: [
                ...arr,
                // { 从业人员总数: 285, 机构名: "广东泰成逸园养老院" },
                // { 从业人员总数: 261, 机构名: "南海区社会福利中心" },
                // { 从业人员总数: 84, 机构名: "九如城（狮山）康养中心" },
                // { 从业人员总数: 80, 机构名: "大沥敬老院" },
                // { 从业人员总数: 68, 机构名: "桂城敬老院" },
                // { 从业人员总数: 45, 机构名: "西樵福利中心" },
                // { 从业人员总数: 44, 机构名: "佛山市南海区九江桃苑颐养院" },
                // { 从业人员总数: 41, 机构名: "文华颐养公寓(狮山)" },
                // { 从业人员总数: 30, 机构名: "佛山市南海区狮山镇官窑敬老院" },
                // { 从业人员总数: 28, 机构名: "佛山市南海区狮山镇罗村敬老院" },
              ],
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
