<template>
  <div class="prAndFlexGrow">
    <EchartsLayout :option="option"></EchartsLayout>
    <div class="infomation">
      <span>男性 | {{ maleNum }}</span>
      <span>女性 | {{ femaleNum }}</span>
    </div>
  </div>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
      maleNum: 0,
      femaleNum: 0,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/com/getElderTypeList",
        param: {
          idOrg: this.ComId,
          selectType: "sex",
        },
        success: (response) => {
          let indicatorArr = [],
            maleArr = [],
            femaleArr = [],
            tempArr = {},
            maleNum = 0,
            femaleNum = 0,
            max = 0;
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              if (tempArr[item.age] === undefined) {
                tempArr[item.age] = maleArr.length;
                indicatorArr.push({ name: item.age });
                if (item.elderNum > max) {
                  max = item.elderNum;
                }
                if (item.selectType == "男") {
                  maleNum += item.elderNum;
                  maleArr.push(item.elderNum);
                  femaleArr.push(0);
                } else {
                  femaleNum += item.elderNum;
                  maleArr.push(0);
                  femaleArr.push(item.elderNum);
                }
              } else {
                if (item.selectType == "男") {
                  maleNum += item.elderNum;
                  maleArr[tempArr[item.age]] = item.elderNum;
                } else {
                  femaleNum += item.elderNum;
                  femaleArr[tempArr[item.age]] = item.elderNum;
                }
              }
            });

          // 女性人数
          this.femaleNum = femaleNum;
          // 男性人数
          this.maleNum = maleNum;

          // 设置边界值
          indicatorArr = this.setAndicatorArrMax(indicatorArr, max);

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            series: [
              {
                radius: ["48%", "65%"],
                type: "pie",
                label: {
                  normal: {
                    formatter: "{c|{c}}\n{hr|}\n{d|{d}%}",
                    rich: {
                      b: { align: "left", color: "#12EABE", fontSize: 35, padding: 4 },
                      c: { align: "left", color: "#fff", fontSize: 35, padding: 4 },
                      d: { align: "left", color: "#fff", fontSize: 35, padding: 4 },
                      hr: {
                        borderColor: "#12EABE",
                        borderWidth: 2,
                        height: 0,
                        width: "100%",
                      },
                    },
                  },
                },
              },
              {
                itemStyle: { normal: { color: "rgba(250,250,250,0.5)" } },
                label: { show: false },
                type: "pie",
                radius: ["61%", "65%"],
              },
            ],
            tooltip: { formatter: "{c}", textStyle: { fontSize: 60 } },
            color: [
              {
                colorStops: [
                  { color: "rgba(19, 116, 255, 1)", offset: 1 },
                  { color: "rgba(86, 36, 255, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(190, 227, 0, 1)", offset: 1 },
                  { color: "rgba(32, 179, 0, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(255, 132, 0, 1)", offset: 1 },
                  { color: "rgba(255, 209, 44, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(253, 81, 76, 1)", offset: 1 },
                  { color: "rgba(253, 68, 168, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(0, 78, 255, 1)", offset: 1 },
                  { color: "rgba(45, 198, 248, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
            ],
            legend: {
              bottom: 0,
              show: true,
              height: "40%",
              width: "100%",
              itemWidth: 50,
              itemHeight: 50,
            },
            dataset: {
              source: [
                ["男", maleNum],
                ["女", femaleNum],
              ],
            },
          };
          // 原来的雷达图
          // this.option = {
          //   legend: {
          //     show: true,
          //     height: "40%",
          //     width: "100%",
          //     itemWidth: 50,
          //     itemHeight: 50,
          //   },
          //   radar: {
          //     axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
          //     radius: "70%",
          //     shape: "polygon",
          //     splitArea: { areaStyle: { color: "transparent" } },
          //     splitLine: {
          //       lineStyle: {
          //         color: [
          //           "#6b6b6b",
          //           "#6b6b6b",
          //           "#6b6b6b",
          //           "#6b6b6b",
          //           "#6b6b6b",
          //           "#6b6b6b",
          //         ],
          //         width: 1,
          //       },
          //     },
          //     name: { textStyle: { color: "#ffffff" } },
          //     indicator: [
          //       // { name: "90-99岁", max: 600 },
          //       // { name: "80-89岁", max: 600 },
          //       // { name: "70-79岁", max: 600 },
          //       // { name: "100岁以上", max: 600 },
          //       // { name: "60-69岁", max: 600 },
          //       // { name: "60岁以下", max: 600 },
          //       ...indicatorArr,
          //     ],
          //   },
          //   series: [
          //     // {
          //     //   type: "custom",
          //     //   name: "男",
          //     //   silent: true,
          //     //   coordinateSystem: "polar",
          //     //   data: [0],
          //     // },
          //     // {
          //     //   type: "custom",
          //     //   name: "女",
          //     //   silent: true,
          //     //   coordinateSystem: "polar",
          //     //   data: [0],
          //     // },
          //     {
          //       areaStyle: { normal: { color: "transparent" } },
          //       itemStyle: {
          //         normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 },
          //       },
          //       lineStyle: { normal: { width: 5 } },
          //       symbol: "circle",
          //       type: "radar",
          //       data: [
          //         {
          //           value: [
          //             // 51, 236, 435, 5, 582, 4
          //             ...maleArr,
          //           ],
          //           name: "男",
          //         },
          //         {
          //           value: [
          //             // 53, 219, 420, 11, 543, 1
          //             ...femaleArr,
          //           ],
          //           name: "女",
          //         },
          //       ],
          //     },
          //   ],
          //   tooltip: { textStyle: { fontSize: 30 } },
          //   color: ["#16B9FF", "#FD415F"],
          //   polar: { radius: "70%", center: ["50%", "50%"] },
          //   angleAxis: {
          //     type: "category",
          //     clockwise: false,
          //     boundaryGap: false,
          //     splitLine: { show: false },
          //     axisLine: { show: false },
          //   },
          //   radiusAxis: {
          //     type: "value",
          //     max: 100,
          //     splitLine: { show: false },
          //     axisTick: { show: false },
          //     axisLabel: { show: false },
          //   },
          // };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/less/infomation.less";
</style>
