<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  props: {
    current_town: {
      type: String,
    },
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getorderNumByTypeAndMonth?division_name=${this.current_town}`,
        success: (response) => {
          let arr = [];
          for (let i = 1; i <= 12; i++) {
            arr.push({ 月份: `${i}月`, A类: 0, B类: 0, C类: 0, 补贴合计: 0 });
          }
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              if (!isNaN(item.month) && item.month > 0 && item.month < 13) {
                arr[item.month - 1]["A类"] = item.numA;
                arr[item.month - 1]["B类"] = item.numB;
                arr[item.month - 1]["C类"] = item.numC;
                arr[item.month - 1]["补贴合计"] = item.numA + item.numB + item.numC;
              }
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            // grid: { left: 150 },
            series: [
              { type: "line", ecode: { x: "月份", y: "A类" }, name: "A类" },
              { type: "line", ecode: { x: "月份", y: "B类" }, name: "B类" },
              { type: "line", ecode: { x: "月份", y: "C类" }, name: "C类" },
              { type: "line", ecode: { x: "月份", y: "补贴合计" }, name: "补贴合计" },
            ],
            title: { text: "补贴长者人数 单位：人" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: { type: "category" },
            yAxis: {},
            legend: {
              show: true,
              height: "40%",
              width: "100%",
              itemWidth: 50,
              itemHeight: 50,
            },
            dataset: { source: [...arr] },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
