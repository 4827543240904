<template>
  <fragment>
    <Title title="服务类型"></Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout fuwuleixing">
        <div :id="`events-layout-${scrollId}`">
          <ul class="fwlx-ul" :id="scrollId">
            <li v-for="(item, index) in dataSource" :key="index">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <EmptyData v-if="isEmpty" />
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";

export default {
  mixins: [ComponentBase],
  components: { Title, EmptyData },
  data() {
    return {
      scrollId: createUniqueKey(12),
      dataSource: [],
      autoScrollObject: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getServiceTypeList`,
        success: (response) => {
          this.dataSource = response.data.data || [];
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.fuwuleixing {
  display: flex;
  overflow: hidden;
  ul.fwlx-ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-y: scroll;
    // pointer-events: none;
    height: 100%;
    li {
      width: calc(~"(100% - 20px)  / 2");
      background-color: rgb(3, 84, 223);
      border-radius: 20px;
      padding: 20px;
      font-size: @fontSizeMiddle;
      color: #fff;
      text-align: center;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-last-child(2),
      &:last-child {
        margin-bottom: 0%;
      }
    }
  }
}
</style>
