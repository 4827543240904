<template>
  <div class="components-layout pd0">
    <div class="nav-intruduce">
      <span>能力指标评估</span>
    </div>
    <ul class="assessment-list">
      <li>
        <p class="t">日常生活活动</p>
        <p class="c">
          进食、洗澡、修饰、穿衣、大便控制、小便控制、如厕、床椅转移、平底行走、上下楼梯
        </p>
      </li>
      <li>
        <p class="t">精神状态</p>
        <p class="c">认知能力、攻击能力、抑郁症状</p>
      </li>
      <li>
        <p class="t">感知觉与沟通</p>
        <p class="c">意识水平、视力、听力、沟通交流</p>
      </li>
      <li>
        <p class="t">社会参与</p>
        <p class="c">生活能力、工作能力、时间/空间定向、人物定向、社会社交能力</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.components-layout {
  flex-direction: column;
  .assessment-list {
    width: 100%;
    height: calc(~"100% - 120px");
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      display: block;
      width: calc(~"(100% - 20px) / 2");
      height: calc(~"(100% - 20px) / 2");
      border: 4px solid rgb(1, 224, 247);
      padding: 20px 40px;
      border-radius: @mainBorderRadius;
      box-shadow: rgb(33, 154, 254) 0 0 0.6rem 0.2rem inset;
      &:nth-child(1),
      &:nth-child(3) {
        margin-right: 20px;
      }
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 20px;
      }
      p {
        font-size: @fontSizeSmall;
        line-height: @mainLineHeight;
        font-weight: bold;
        color: #fff;
        text-align: justify;
      }
      p.t {
        font-size: @fontSizeMiddle;
        text-align: center;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
