<template>
  <fragment>
    <div class="left-fixed">
      <div class="left-hover-btn">男性 | {{ maleNum }}</div>
      <div class="left-hover-btn">女性 | {{ femaleNum }}</div>
    </div>
    <EchartsLayout :option="option"></EchartsLayout>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  props: {
    set_elder_num: {
      type: Function,
    },
  },
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
      maleNum: 0,
      femaleNum: 0,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/org/getElderTypeList",
        param: {
          idOrg: this.OrgId,
          selectType: "sex",
        },
        success: (response) => {
          let jsonSample = {
              "60岁以下": 0,
              "60-70岁": 1,
              "70-80岁": 2,
              "80-90岁": 3,
              "90-100岁": 4,
              "100岁以上": 5,
            },
            indicatorArr = [
              { name: "60岁以下" },
              { name: "60-70岁" },
              { name: "70-80岁" },
              { name: "80-90岁" },
              { name: "90-100岁" },
              { name: "100岁以上" },
            ],
            maleArr = [0, 0, 0, 0, 0, 0],
            femaleArr = [0, 0, 0, 0, 0, 0],
            tempArr = {},
            maleNum = 0,
            femaleNum = 0,
            elderNum = 0,
            max = 0;
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              elderNum += item.number;
              if (item.number > max) {
                max = item.number;
              }
              // if (tempArr[item.age] === undefined) {
              tempArr[item.age] = maleArr.length;
              // indicatorArr.push({ name: item.age });
              if (item.selectType == "男") {
                maleNum += item.number;
                // maleArr.push(item.number);
                // femaleArr.push(0);
                if (jsonSample[item.age]) {
                  maleArr[jsonSample[item.age]] += item.number || 0;
                }
              } else {
                femaleNum += item.number;
                // maleArr.push(0);
                // femaleArr.push(item.number);
                if (jsonSample[item.age]) {
                  femaleArr[jsonSample[item.age]] += item.number || 0;
                }
              }
              // } else {
              //   if (item.selectType == "男") {
              //     maleNum += item.number;
              //     maleArr[tempArr[item.age]] = item.number;
              //   } else {
              //     femaleNum += item.number;
              //     femaleArr[tempArr[item.age]] = item.number;
              //   }
              // }
            });

          // 女性总数
          this.femaleNum = femaleNum;
          // 男性总数
          this.maleNum = maleNum;

          // 设置边界值
          indicatorArr = this.setAndicatorArrMax(indicatorArr, max);

          // 设置长者总数
          this.set_elder_num && this.set_elder_num(elderNum);

          // 更新配置
          this.option = {
            legend: {},
            radar: {
              axisLine: { lineStyle: { color: "rgba(238,238,238, 0.2)" } },
              shape: "polygon",
              splitArea: { areaStyle: { color: "transparent" } },
              splitLine: {
                lineStyle: {
                  color: [
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                    "#6b6b6b",
                  ],
                  width: 1,
                },
              },
              name: { textStyle: { color: "#ffffff" } },
              indicator: [...indicatorArr],
            },
            series: [
              // {
              //   type: "custom",
              //   name: "男",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              // {
              //   type: "custom",
              //   name: "女",
              //   silent: true,
              //   coordinateSystem: "polar",
              //   data: [0],
              // },
              {
                areaStyle: { normal: { color: "transparent" } },
                itemStyle: {
                  normal: { borderColor: "rgba(87,201,255,0.2)", borderWidth: 12 },
                },
                lineStyle: { normal: { width: 5 } },
                symbol: "circle",
                type: "radar",
                data: [
                  {
                    // value: [9, 50, 170, 54, 3, 3],
                    value: [...maleArr],
                    name: "男",
                  },
                  {
                    // value: [14, 111, 376, 144, 7, 4],
                    value: [...femaleArr],
                    name: "女",
                  },
                ],
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            color: ["#16B9FF"],
            polar: { radius: "75%", center: ["50%", "50%"] },
            angleAxis: {
              type: "category",
              clockwise: false,
              boundaryGap: false,
              splitLine: { show: false },
              axisLine: { show: false },
            },
            radiusAxis: {
              type: "value",
              max: 100,
              splitLine: { show: false },
              axisTick: { show: false },
              axisLabel: { show: false },
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
