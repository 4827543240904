<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/com/getActivityAndPartipateNumList",
        param: {
          idOrg: this.ComId,
          type: "报名",
        },
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.year, item.number, item.number, item.number]);
            });

          // 更新配置
          this.option = {
            grid: { left: 150 },
            series: [
              { type: "bar", name: "次数" },
              {
                type: "line",
                symbol: "emptyCircle",
                symbolSize: 20,
                label: { show: true, color: "RGBA(48, 148, 235, 1)" },
                lineStyle: { color: "RGBA(48, 148, 235, 1)" },
              },
            ],
            title: { text: "活动参与长者增长趋势 单位：次" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: { type: "category" },
            yAxis: {},
            legend: { show: false },
            dataset: {
              source: [...arr],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
