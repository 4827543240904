<template>
  <Map
    v-if="areaData.length > 0"
    :config="config"
    :areaData="areaData"
    :renderAreaData="renderAreaData"
    className="nh-baidu-map"
  ></Map>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Map from "@/components/common/Map.vue";
import MapBase from "@/components/mixin/MapBase.vue";
export default {
  mixins: [MapBase, ComponentBase],
  components: { Map },
  data() {
    return {
      areaData: [],
    };
  },
  methods: {
    renderAreaData(item, index) {
      return `
        <div class="area-info area-info-${index}">
            <p class="t">${item.divisionName}</p>
            <p class="d">长者总人数：${item.elderNum || 0}人</p>
        </div>
      `;
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `nh/getElderNumListByTown`,
        success: (response) => {
          let areaData = [];
          response &&
            response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              areaData.push({
                divisionName: item.divisionName,
                elderNum: item.number || 0,
              });
            });
          this.areaData = areaData;
        },
      });
    },
  },
};
</script>
<style lang="less"></style>
