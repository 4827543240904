<template>
  <fragment>
    <Title title="按时间段呼叫情况统计"></Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout anshijianduanhujiaoqingkuangtongji">
        <ul class="date">
          <Select
            className="hjzx"
            :callBack="set_current_year"
            :dataArray="yearSelecter"
          ></Select>
          <!-- <li
            class="month"
            v-for="(item, index) in 12"
            :key="index"
            @click="set_current_month(item)"
            :class="currentMonth == item ? 'active' : ''"
          >
            {{ (item + "").padStart(2, "0") }}
          </li> -->
          <Nav className="month" :dataArray="monthNav" :callBack="set_current_month"></Nav>
        </ul>
        <ul class="nav">
          <li
            @click="set_current_type('noEmergencyNum')"
            :class="currentType == 'noEmergencyNum' ? 'active' : ''"
          >
            普通呼叫量 | {{ data.noEmergencyNum || 0 }}
          </li>
          <li
            @click="set_current_type('isEmergencyNum')"
            :class="currentType == 'isEmergencyNum' ? 'active' : ''"
          >
            紧急呼叫量 | {{ data.isEmergencyNum || 0 }}
          </li>
          <li
            @click="set_current_type('callOrderNum')"
            :class="currentType == 'callOrderNum' ? 'active' : ''"
          >
            呼叫下单量 | {{ data.callOrderNum || 0 }}
          </li>
        </ul>
        <div class="charts">
          <EchartsLayout :option="option"></EchartsLayout>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";
import Title from "@/components/common/Title.vue";
import Select from "@/components/common/Select.vue";
import Nav from "@/components/common/Nav.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
    Title,
    Select,
    Nav,
  },
  data() {
    return {
      option: null,
      currentYear: new Date().getFullYear(),
      currentMonth: 1,
      currentType: "isEmergencyNum",
      data: {
        isEmergencyNum: 0,
        noEmergencyNum: 0,
        callOrderNum: 0,
      },
    };
  },
  computed: {
    yearSelecter() {
      return this.$store.state.lastFourYears;
    },
    monthNav() {
      let monthNav = [];
      for (let i = 1; i <= 12; i++) {
        monthNav.push({ title: (i + "").padStart(2, "0") });
      }
      return monthNav;
    },
  },
  methods: {
    // 设置当前类型
    set_current_type(currentType) {
      this.currentType = currentType;
      this.$nextTick(() => {
        this.get_component_data();
      });
    },
    // 设置当前月份
    set_current_month(currentMonth) {
      this.currentMonth = parseInt(currentMonth.title);
      this.$nextTick(() => {
        this.get_component_data();
      });
    },
    // 设置当前年份
    set_current_year(currentYear) {
      this.currentYear = currentYear;
      this.$nextTick(() => {
        this.get_component_data();
      });
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/home/getCallNumByHour`,
        param: {
          year: this.currentYear,
          month: this.currentMonth,
        },
        success: (response) => {
          this.data = {
            isEmergencyNum: response.data.data.isEmergencyNum || 0,
            noEmergencyNum: response.data.data.noEmergencyNum || 0,
            callOrderNum: response.data.data.callOrderNum || 0,
          };
          let arr = [];
          for (let i = 1; i <= 24; i++) {
            arr.push([(i + "").padStart(2, "0"), 0]);
          }

          const listKey =
            this.currentType == "isEmergencyNum" ? "isEmergencyList" : "noEmergencyList";
          response.data &&
            response.data.data &&
            response.data.data[listKey] &&
            response.data.data[listKey].map &&
            response.data.data[listKey].map((item) => {
              if (item.hour == 0) {
                item.hour = 24;
              }
              if (!isNaN(item.hour) && item.hour > -1 && item.month < 25) {
                arr[item.hour - 1][1] = item.allPickup;
              }
            });

          this.option = {
            // grid: { left: 150 },
            grid: { containLabel: true },
            series: [
              {
                type: "bar",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "RGBA(44, 198, 246, 1)" },
                      { offset: 0, color: "RGBA(3, 98, 214, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                barWidth: 40,
              },
            ],
            title: { text: "单位： 人" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: { type: "category" },
            yAxis: {},
            dataset: {
              source: [...arr],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less">
.anshijianduanhujiaoqingkuangtongji {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ul.date {
    display: flex;
    width: 100%;
    justify-content: space-between;
    min-height: 0.6rem;
    align-items: center;
    > li {
      font-size: 0.18rem;
      font-weight: bold;
      cursor: pointer;
      border: 0.03rem solid rgb(33, 154, 254);
      background: rgb(20, 56, 140);
      border-radius: 0.2rem;
      padding: 0.05rem 0.14rem;
      color: #fff;
      height: fit-content;
      transition: 0.5s;
      position: relative;
    }
    li.active {
      background: linear-gradient(90deg, rgb(0, 78, 255), rgb(41, 171, 254));
    }
    .month {
      flex-grow: 1;
      width: 0;
      .ny-nav-options {
        justify-content: space-evenly;
        .ny-nav-option {
          margin: 0 0;
        }
      }
    }
  }
  ul.nav {
    display: flex;
    width: 100%;
    justify-content: space-around;
    min-height: 0.6rem;
    align-items: center;
    li {
      font-size: 0.18rem;
      font-weight: 700;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 0.3rem;
      padding: 0.1rem;
      position: relative;
      min-width: 1.7rem;
      margin: 0 0.1rem;
      height: fit-content;
      transition: 0.5s;
      cursor: pointer;
    }
    li.active {
      background: linear-gradient(90deg, rgb(0, 78, 255), rgb(41, 171, 254));
    }
  }
  .charts {
    flex: 1 1 auto;
    width: 100%;
    .echarts-layout {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
