<template>
  <fragment>
    <Title title="基础设施展示">
      <template v-slot:righter>
        <div class="jcss-right">
          <div
            :class="{
              'nav-intruduce-span': true,
              lh60: true,
              unactive: current_type != '功能室',
            }"
            @click="set_current_room('功能室')"
          >
            功能室 | {{ function_room_list.length }}
          </div>
          <div
            :class="{
              'nav-intruduce-span': true,
              lh60: true,
              mr0: true,
              unactive: current_type != '消防设施',
            }"
            @click="set_current_room('消防设施')"
          >
            消防设施 | {{ fire_room_list.length }}
          </div>
        </div>
      </template>
    </Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout">
        <div class="jichusheshizhanshi">
          <div class="main">
            <div class="jcss-content">
              <div :id="`events-layout-${scrollId}`">
                <ul class="jcss-ul" :id="scrollId">
                  <li v-for="(item, index) in dataSource" :key="index">
                    <div class="picture">
                      <img
                        :src="`${imgUrl}${item.roomPhoto || item.fireEquipmentPhoto}`"
                      />
                    </div>
                    <p>{{ item.activityRoomName || item.fireEquipmentName }}</p>
                    <p class="description">
                      {{ item.roomName || item.fireEquipmentIntroduction }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <EmptyData v-if="isEmpty" />
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest, createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EmptyData from "@/components/common/EmptyData.vue";
import { imgUrl } from "@/utils/config.js";

export default {
  components: { Title, EmptyData },
  mixins: [ComponentBase],
  data() {
    return {
      imgUrl,
      scrollId: createUniqueKey(12),
      autoScrollObject: null,
      scrollDirection: "scrollLeft",
      dataSource: [],
      function_room_list: [],
      fire_room_list: [],
      current_type: "功能室",
    };
  },
  methods: {
    set_current_room(type) {
      this.current_type = type;
      if (type === "功能室") {
        this.dataSource = [...this.function_room_list];
      } else if (type === "消防设施") {
        this.dataSource = [...this.fire_room_list];
      }
      this.$nextTick(() => {
        this.autoScrollObject.setScrollBegin().resetScroll();
      });
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getAcvitityRoomList`,
        param: {
          idOrg: this.ComId,
        },
        success: (response) => {
          this.function_room_list =
            response && response.data && response.data.data ? response.data.data : [];
          if (this.current_type === "功能室") {
            this.dataSource = [...response.data.data];
          }
        },
      });
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/com/getFireEquipmentList`,
        param: {
          idOrg: this.ComId,
        },
        success: (response) => {
          this.fire_room_list =
            response && response.data && response.data.data ? response.data.data : [];
          if (this.current_type === "消防设施") {
            this.dataSource = [...response.data.data];
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.jcss-right {
  display: flex;
  .nav-intruduce-span {
    cursor: pointer;
    border: 4px solid rgb(33, 154, 254);
    display: flex;
    align-items: center;
    transition: 0.5s;
  }
  .nav-intruduce-span.unactive {
    background: transparent;
  }
}
.jichusheshizhanshi {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .main {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .jcss-content {
      flex: 1 1 auto;
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      #events-layout {
        width: 100%;
        height: unset;
      }
      .jcss-ul {
        display: flex;
        overflow-x: scroll;
        width: 100%;
        height: 100%;
        // pointer-events: none;
        li {
          width: 20%;
          margin-right: 2%;
          flex-shrink: 0;
          background: #112e76;
          border-radius: 0.1rem;
          overflow: hidden;
          padding: 0.1rem;
          .picture {
            width: 100%;
            padding-top: 77%;
            position: relative;
            img {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              display: block;
              object-fit: cover;
              width: 100%;
              height: 100%;
              border-radius: 0.1rem;
              overflow: hidden;
            }
          }
          p {
            font-weight: 700;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 36px;
            margin: 20px 0;
          }
          p.description {
            font-size: 30px;
            text-align: justify;
            display: -webkit-box !important;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 8;
          }
        }
      }
    }
  }
}
</style>
