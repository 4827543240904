<template>
  <fragment>
    <Title title="就业补贴符合人数"></Title>
    <div class="layout-view withoutTitle pb0">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/org/getSubsidyByAge?id_org=${this.OrgId}`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.age, item.subsidyCount]);
            });

          // 更新配置
          this.option = {
            series: [
              {
                radius: ["48%", "65%"],
                type: "pie",
                label: {
                  normal: {
                    formatter: "{c|{c}}\n{hr|}\n{d|{d}%}",
                    rich: {
                      b: { align: "left", color: "#12EABE", fontSize: 35, padding: 4 },
                      c: { align: "left", color: "#fff", fontSize: 35, padding: 4 },
                      d: { align: "left", color: "#fff", fontSize: 35, padding: 4 },
                      hr: {
                        borderColor: "#12EABE",
                        borderWidth: 2,
                        height: 0,
                        width: "100%",
                      },
                    },
                  },
                },
              },
              {
                itemStyle: { normal: { color: "rgba(250,250,250,0.5)" } },
                label: { show: false },
                type: "pie",
                radius: ["61%", "65%"],
              },
            ],
            tooltip: { formatter: "{c}", textStyle: { fontSize: 60 } },
            color: [
              {
                colorStops: [
                  { color: "rgba(19, 116, 255, 1)", offset: 1 },
                  { color: "rgba(86, 36, 255, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(190, 227, 0, 1)", offset: 1 },
                  { color: "rgba(32, 179, 0, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(255, 132, 0, 1)", offset: 1 },
                  { color: "rgba(255, 209, 44, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(253, 81, 76, 1)", offset: 1 },
                  { color: "rgba(253, 68, 168, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(0, 78, 255, 1)", offset: 1 },
                  { color: "rgba(45, 198, 248, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
            ],
            legend: {
              bottom: 0,
              show: true,
              height: "40%",
              width: "100%",
              itemWidth: 50,
              itemHeight: 50,
            },
            dataset: {
              source: [
                // ["20-29岁", 0],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
