<template>
  <fragment>
    <Title title="共治共建共享"></Title>
    <div class="layout-view withoutTitle">
      <div class="components-layout">
        <div class="gongzhigongjiangongxiang">
          <div class="picture">
            <img :src="picture" />
          </div>
          <div class="description">
            <div :id="`events-layout-${scrollId}`">
              <div class="content" :id="scrollId">
                <div class="html-css" v-html="description"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import { createUniqueKey } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";

export default {
  components: {
    Title,
  },
  mixins: [ComponentBase],
  data() {
    return {
      scrollId: createUniqueKey(12),
      picture: "/images/gzgjgx.png",
      description:
        "2020年8月24日，习近平总书记在主持经济社会领域专家座谈会时，就正确认识和把握中长期经济社会发展重大问题发表重要讲话，强调“要完善共建共治共享的社会治理制度，实现政府治理同社会调节、居民自治良性互动，建设人人有责、人人尽责、人人享有的社会治理共同体。要加强和创新基层社会治理，使每个社会细胞都健康活跃，将矛盾纠纷化解在基层，将和谐稳定创建在基层” 。这些重要论述为“十四五”时期有效适应社会结构、社会关系、社会行为方式、社会心理等深刻变化，进一步加强和创新社会治理、拓展社会发展新局面指明了方向，并提出了新的要求。",
      autoScrollObject: null,
    };
  },
};
</script>

<style lang="less" scoped>
.gongzhigongjiangongxiang {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgb(16, 46, 118);
  padding: 0.1rem;
  border-radius: 0.1rem;
  overflow: hidden;
  .picture {
    width: 50%;
    height: 100%;
    img {
      .setImg(contain);
    }
  }
  .description {
    width: 50%;
    height: 100%;
    padding: 0 0.1rem;
    .content {
      overflow-y: scroll;
      // pointer-events: none;
      height: 100%;
    }
  }
}
</style>
