<template>
  <Map
    :config="config"
    :areaData="areaData"
    :renderAreaData="renderAreaData"
    mapType="社区养老场所"
    className="com-baidu-map"
  ></Map>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import Map from "@/components/common/Map.vue";
import MapBase from "@/components/mixin/MapBase.vue";
import ComponentBase from "@/components/mixin/ComponentBase.vue";

export default {
  mixins: [MapBase, ComponentBase],
  components: { Map },
  data() {
    return {
      areaData: [],
    };
  },
  methods: {
    renderAreaData(item, index) {
      return `
        <div class="area-info area-info-${index}">
            <p class="t">${item.divisionName}</p>
            <p class="d">社区幸福院总数：${item.streetHappinessNum}（${item.streetHappinessProportion}）</p>
            <p class="d">已建成数量：${item.hasBuildNum}（${item.hasBuildProportion}）</p>
            <p class="d">试运行数量：${item.rialRunMum}（${item.rialRunProportion}）</p>
        </div>
      `;
    },
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `com/getComNumByTown`,
        success: (response) => {
          let areaData = [];
          response &&
            response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              areaData.push({
                divisionName: item.divisionName,
                rialRunMum: item.rialRunMum || 0,
                rialRunProportion: item.rialRunProportion || "0%",
                streetHappinessNum: item.streetHappinessNum || 0,
                streetHappinessProportion: item.streetHappinessProportion || "0%",
                hasBuildNum: item.hasBuildNum || 0,
                hasBuildProportion: item.hasBuildProportion || "0%",
              });
            });
          this.areaData = areaData;
        },
      });
    },
  },
};
</script>
<style lang="less"></style>
