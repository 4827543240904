<template>
  <fragment>
    <Title title="从业人员资质情况分析"></Title>
    <div class="layout-view withoutTitle">
      <EchartsLayout :option="option"></EchartsLayout>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: "/com/selectQualificationList",
        param: {
          idOrg: this.ComId,
        },
        success: (response) => {
          let arr = [];
          response &&
            response.data &&
            response.data.data &&
            response.data.data.reverse &&
            response.data.data.reverse().map((item) => {
              arr.push({
                value: item.staffNum || 0,
                名字: item.professionalQualificationName,
              });
            });

          // 更新配置
          this.option = {
            series: [
              {
                radius: ["50%", "70%"],
                type: "pie",
                itemStyle: { borderColor: "rgba(255,255,255,0.5)", borderWidth: 15 },
                label: {
                  normal: {
                    color: "#fff",
                    fontSize: 35,
                    formatter: "{b}\n{d}%",
                    position: "outside",
                    show: true,
                  },
                },
              },
            ],
            tooltip: {
              formatter: { formatter: "{b} : {c} ({d}%)", trigger: "item" },
              textStyle: { fontSize: 60 },
            },
            color: [
              {
                colorStops: [
                  { color: "rgba(19, 116, 255, 1)", offset: 1 },
                  { color: "rgba(86, 36, 255, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(190, 227, 0, 1)", offset: 1 },
                  { color: "rgba(32, 179, 0, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(255, 132, 0, 1)", offset: 1 },
                  { color: "rgba(255, 209, 44, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(253, 81, 76, 1)", offset: 1 },
                  { color: "rgba(253, 68, 168, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
              {
                colorStops: [
                  { color: "rgba(0, 78, 255, 1)", offset: 1 },
                  { color: "rgba(45, 198, 248, 1)", offset: 0 },
                ],
                globalCoord: false,
                type: "linear",
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
            ],
            dataset: {
              source: [
                // { value: 7, 名字: "无证" }
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
