import { render, staticRenderFns } from "./EachDistrictServingElder.vue?vue&type=template&id=182a610a&scoped=true&"
import script from "./EachDistrictServingElder.vue?vue&type=script&lang=js&"
export * from "./EachDistrictServingElder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "182a610a",
  null
  
)

export default component.exports