<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  props: {
    current_year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/home/getOperationTopByElder`,
        param: {
          year: this.current_year,
        },
        success: (response) => {
          let orgArr = [],
            valueArr = [],
            cishanArr = [],
            ownArr = [],
            subsidyArr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              orgArr.push(item.orgName);
              cishanArr.push(0);
              ownArr.push(item.ownNum || 0);
              subsidyArr.push(item.subsidyNum || 0);
              valueArr.push({
                慈善受捐类: 0,
                机构名: item.orgName,
                自费类: item.ownNum || 0,
                补贴类: item.subsidyNum || 0,
              });
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            // grid: { left: 550 },
            series: [
              {
                encode: { x: "自费长者", y: "机构名" },
                name: "自费长者",
                type: "bar",
                barGap: "-100%",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(255, 132, 0, 1)" },
                      { offset: 0, color: "rgba(255, 209, 44, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 20,
                },
                barWidth: 40,
                zlevel: 5,
                data: [...ownArr],
              },
              {
                encode: { x: "补贴长者", y: "机构名" },
                name: "补贴长者",
                type: "bar",
                barGap: "-100%",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(190, 227, 0, 1)" },
                      { offset: 0, color: "rgba(32, 179, 0, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 20,
                },
                barWidth: 40,
                zlevel: 4,
                data: [...subsidyArr],
              },
              {
                encode: { x: "慈善受捐长者", y: "机构名" },
                name: "慈善受捐长者",
                type: "bar",
                barGap: "-100%",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(253, 81, 76, 1)" },
                      { offset: 0, color: "rgba(253, 68, 168, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 20,
                },
                barWidth: 40,
                zlevel: 3,
                data: [...cishanArr],
              },
              {
                encode: { x: "自费长者", y: "机构名" },
                name: "自费长者",
                type: "bar",
                stack: "背景",
                itemStyle: { color: "rgba(255, 132, 0, 1)", borderRadius: 20 },
                barWidth: 40,
                zlevel: 5,
                data: [...ownArr],
              },
              {
                encode: { x: "补贴长者", y: "机构名" },
                name: "补贴长者",
                type: "bar",
                stack: "背景",
                itemStyle: { color: "rgba(190, 227, 0, 1)", borderRadius: 20 },
                barWidth: 40,
                zlevel: 4,
                data: [...subsidyArr],
              },
              {
                encode: { x: "慈善受捐长者", y: "机构名" },
                name: "慈善受捐长者",
                type: "bar",
                stack: "背景",
                itemStyle: { color: "rgba(253, 81, 76, 1)", borderRadius: 20 },
                barWidth: 40,
                zlevel: 3,
                data: [...cishanArr],
              },
            ],
            title: { text: "单位：人" },
            tooltip: { trigger: "item" },
            xAxis: {},
            yAxis: {
              type: "category",
              inverse: true,
              data: [
                // "佛山市南海区春晖养老服务中心",
                // "广东谷丰健康管理有限公司",
                // "佛山市南海区佰乐社会工作服务中心",
                // "佛山市红星社会工作服务中心",
                // "佛山市南海区社会福利中心",
                // "佛山市南海区启正社会工作服务中心",
                // "佛山市仁德社会工作服务中心",
                // "佛山市南海区文华颐养公寓",
                ...orgArr,
              ],
            },
            legend: {
              show: true,
              height: "40%",
              width: "100%",
              itemWidth: 50,
              itemHeight: 50,
            },
            dataset: {
              source: [
                // {
                //   慈善受捐类: 0,
                //   机构名: "佛山市南海区春晖养老服务中心",
                //   自费类: 0,
                //   补贴类: 3150,
                // },
                // {
                //   慈善受捐类: 0,
                //   机构名: "广东谷丰健康管理有限公司",
                //   自费类: 0,
                //   补贴类: 450,
                // },
                // {
                //   慈善受捐类: 0,
                //   机构名: "佛山市南海区佰乐社会工作服务中心",
                //   自费类: 0,
                //   补贴类: 44,
                // },
                // {
                //   慈善受捐类: 0,
                //   机构名: "佛山市红星社会工作服务中心",
                //   自费类: 0,
                //   补贴类: 33,
                // },
                // {
                //   慈善受捐类: 0,
                //   机构名: "佛山市南海区社会福利中心",
                //   自费类: 0,
                //   补贴类: 22,
                // },
                // {
                //   慈善受捐类: 0,
                //   机构名: "佛山市南海区启正社会工作服务中心",
                //   自费类: 0,
                //   补贴类: 1,
                // },
                // {
                //   慈善受捐类: 0,
                //   机构名: "佛山市仁德社会工作服务中心",
                //   自费类: 0,
                //   补贴类: 11,
                // },
                // {
                //   慈善受捐类: 0,
                //   机构名: "佛山市南海区文华颐养公寓",
                //   自费类: 0,
                //   补贴类: 1,
                // },
                ...valueArr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
