import { render, staticRenderFns } from "./ActivityParticipatingElderGrowthTrend.vue?vue&type=template&id=009131af&scoped=true&"
import script from "./ActivityParticipatingElderGrowthTrend.vue?vue&type=script&lang=js&"
export * from "./ActivityParticipatingElderGrowthTrend.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009131af",
  null
  
)

export default component.exports