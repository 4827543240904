<template>
  <div class="components-layout pd0">
    <div class="jigouyanglaozonglan">
      <!-- 机构 -->
      <div class="section yljg">
        <p>养老机构</p>
        <p>{{ data.allOrgCount }}家</p>
      </div>
      <div class="section rzzzzs">
        <p>入住长者总数</p>
        <p>{{ data.residentNum }}人</p>
      </div>
      <div class="section cyryzs">
        <p>从业人员总数</p>
        <p>{{ data.staffNum }}人</p>
      </div>
      <div class="section xfplxtwcbl">
        <p>消防喷淋和烟感系统完成比例</p>
        <p>{{ data.isBuildFireSpraySys }}%</p>
      </div>
      <div class="section cwzs">
        <p>床位总数</p>
        <p>{{ data.bedNum }}</p>
      </div>
      <div class="section yyjh">
        <p>医养结合</p>
        <p>{{ data.medicalCareCount }}家</p>
      </div>
      <!-- 机构 -->
    </div>
  </div>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
export default {
  mixins: [ComponentBase],
  data() {
    return {
      data: {
        // 养老机构
        allOrgCount: 0,
        // 入住长者总数
        rzzzzs: 0,
        // 从业人员总数
        residentNum: 0,
        // 床位总数
        bedNum: 0,
        // 消防喷淋和烟感系统完成比例
        isBuildFireSpraySys: 0,
        // 医养结合
        medicalCareCount: 0,
      },
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: "/org/getOrgOverview",
        success: (response) => {
          this.data = {
            residentNum: response.data.data.residentNum || 0,
            bedNum: response.data.data.bedNum || 0,
            staffNum: response.data.data.staffNum || 0,
            medicalCareCount: response.data.data.medicalCareCount || 0,
            allOrgCount: response.data.data.allOrgCount || 0,
            isBuildFireSpraySys: response.data.data.isBuildFireSpraySys || 0,
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.jigouyanglaozonglan {
  position: relative;
  width: 100%;
  padding-top: 44%;
  background: #00083d url(/images/jgylzl.png) no-repeat center;
  background-size: contain;
  .section {
    color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20%;
    width: 14.8%;
    height: 33.6%;
    p {
      &:first-child {
        padding: 0 5%;
      }
    }
  }
  .yljg {
    left: 40.56%;
    top: 8.4%;
    width: 26.7%;
    height: 62%;
    font-size: 38%;
    p {
      &:first-child {
        padding: 0 20%;
      }
    }
  }
  .rzzzzs {
    left: 18.4%;
    top: 0;
    p {
      &:first-child {
        padding: 0 25%;
      }
    }
  }
  .cyryzs {
    left: 10.7%;
    top: 38.9%;
    p {
      &:first-child {
        padding: 0 25%;
      }
    }
  }
  .xfplxtwcbl {
    left: 75%;
    top: 8.1%;
    p {
      &:first-child {
        padding: 0 15%;
      }
    }
  }
  .cwzs {
    left: 26.7%;
    top: 65.5%;
  }
  .yyjh {
    left: 70.4%;
    top: 62%;
  }
}
</style>
