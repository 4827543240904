<template>
  <fragment>
    <Title title="各区建设情况"></Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav">
      <component :is="currentComponent"></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import EachDistrictConstructionWelfareCentreNumber from "@/components/fs/EachDistrictConstructionWelfareCentreNumber.vue";
import EachDistrictConstructionByLocalElderNumber from "@/components/fs/EachDistrictConstructionByLocalElderNumber.vue";
export default {
  components: {
    Title,
    Nav,
    EachDistrictConstructionWelfareCentreNumber,
    EachDistrictConstructionByLocalElderNumber,
  },
  mixins: [LayoutBase],
  data() {
    return {
      components: [
        {
          key: "EachDistrictConstructionWelfareCentreNumber",
          title: "养老服务机构数量",
        },
        {
          key: "EachDistrictConstructionByLocalElderNumber",
          title: "本地户籍长者数量",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped></style>
