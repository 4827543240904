<template>
  <!-- 社区幸福院主窗体内容 -->
  <div :class="`layout-main  layout-main-${currentRightPage} happiness-com`">
    <!-- 主窗体左侧 -->
    <div class="layout-main-left">
      <div
        class="layout-view"
        @dblclick="setMiddleCoverComponent('CommunityHappinessOverview')"
      >
        <CommunityHappinessOverview></CommunityHappinessOverview>
      </div>
      <div
        class="layout-view"
        @dblclick="setMiddleCoverComponent('CommunityHappinessOperationRanking')"
      >
        <CommunityHappinessOperationRanking></CommunityHappinessOperationRanking>
      </div>
      <div
        class="layout-view"
        @dblclick="setMiddleCoverComponent('CommunityHappinessAnnualRanking')"
      >
        <CommunityHappinessAnnualRanking></CommunityHappinessAnnualRanking>
      </div>
    </div>
    <!-- 主窗体左侧 -->

    <!-- 主窗体中部 -->
    <div class="layout-main-middle">
      <CommunityHappinessMap></CommunityHappinessMap>
      <div class="layout-view abs" v-if="middleCoverComponent">
        <span class="close" @click="closeCoverComponent"></span>
        <component :is="middleCoverComponent"></component>
      </div>
    </div>
    <!-- 主窗体中部 -->

    <!-- 主窗体右侧第一屏 -->
    <div v-if="currentRightPage == 1" class="layout-main-right fdc">
      <!-- 第四，五列 -->
      <!-- 4-1 + 5-1 -->
      <div class="layout-view fdr">
        <div class="layout-view wd50 mr10">
          <Title title="机构图片介绍"></Title>
          <div class="layout-view withoutTitle">
            <CommunityHappinessPictureIntroduction></CommunityHappinessPictureIntroduction>
          </div>
        </div>
        <div class="layout-view wd50 ml10">
          <div class="layout-view fdr">
            <div
              class="layout-view wd50 mr10"
              @dblclick="setMiddleCoverComponent('CommunityHappinessIntroduce')"
            >
              <CommunityHappinessIntroduce></CommunityHappinessIntroduce>
            </div>
            <div
              class="layout-view wd50 ml10"
              @dblclick="setMiddleCoverComponent('CommunityEmployeesIntroduce')"
            >
              <CommunityEmployeesIntroduce></CommunityEmployeesIntroduce>
            </div>
          </div>
        </div>
      </div>
      <!-- 4-1 + 5-1 -->

      <!-- 4-2 + 5-2 -->
      <div class="layout-view fdr">
        <div
          class="layout-view wd50 mr10"
          @dblclick="setMiddleCoverComponent('CommunityElder')"
        >
          <CommunityElder></CommunityElder>
        </div>
        <div class="layout-view wd50 ml10">
          <div class="layout-view fdr">
            <div
              class="layout-view wd50 mr10"
              @dblclick="setMiddleCoverComponent('EmployeesQualification')"
            >
              <EmployeesQualification></EmployeesQualification>
            </div>
            <div
              class="layout-view wd50 ml10 bdl"
              @dblclick="setMiddleCoverComponent('VolunteerParticipation')"
            >
              <VolunteerParticipation></VolunteerParticipation>
            </div>
          </div>
        </div>
      </div>
      <!-- 4-2 + 5-2 -->

      <!-- 4-3 + 5-3 -->
      <div class="layout-view bd pd20 br20 fdr">
        <div
          class="layout-view wd50 mr10"
          @dblclick="setMiddleCoverComponent('PreventiveTreatment')"
        >
          <PreventiveTreatment></PreventiveTreatment>
        </div>
        <div class="layout-view wd50 ml10">
          <div class="layout-view fdr">
            <div class="layout-view wd50 mr10">
              <div class="layout-view">
                <div
                  class="layout-view hg50"
                  @dblclick="setMiddleCoverComponent('ElderExperiencing')"
                >
                  <ElderExperiencing></ElderExperiencing>
                </div>
                <div
                  class="layout-view hg50"
                  @dblclick="setMiddleCoverComponent('ElderHealth')"
                >
                  <ElderHealth></ElderHealth>
                </div>
              </div>
            </div>
            <div
              class="layout-view wd50 ml10"
              @dblclick="setMiddleCoverComponent('ChronicDiseaseTeam')"
            >
              <ChronicDiseaseTeam></ChronicDiseaseTeam>
            </div>
          </div>
        </div>
      </div>
      <!-- 4-3 + 5-3 -->
      <!-- 第四，五列 -->
    </div>
    <!-- 主窗体右侧第一屏 -->

    <!-- 主窗体右侧第二屏 -->
    <div v-if="currentRightPage == 2" class="layout-main-right">
      <!-- 第四列 -->
      <div class="layout-view wd50 mr10">
        <!-- 4-1 -->
        <div
          class="layout-view"
          @dblclick="setMiddleCoverComponent('GovernanceConstructionSharing')"
        >
          <GovernanceConstructionSharing></GovernanceConstructionSharing>
        </div>
        <!-- 4-1 -->
        <!-- 4-2 -->
        <div
          class="layout-view lnpbpm"
          @dblclick="setMiddleCoverComponent('YearsRanking')"
        >
          <YearsRanking></YearsRanking>
        </div>
        <!-- 4-2 -->
        <!-- 4-3 -->
        <div class="layout-view bd pd20 br20 hdqkfx">
          <div class="layout-view">
            <div
              class="layout-view top"
              @dblclick="setMiddleCoverComponent('ActivityAnalysis')"
            >
              <ActivityAnalysis></ActivityAnalysis>
            </div>
            <div class="layout-view fdr bot">
              <div
                class="layout-view wd50 mr10"
                @dblclick="setMiddleCoverComponent('ActivityInformation')"
              >
                <ActivityInformation></ActivityInformation>
              </div>
              <div
                class="layout-view wd50 ml10"
                @dblclick="setMiddleCoverComponent('ActivityGroup')"
              >
                <ActivityGroup></ActivityGroup>
              </div>
            </div>
          </div>
        </div>
        <!-- 4-3 -->
      </div>
      <!-- 第四列 -->
      <!-- 第五列 -->
      <div class="layout-view wd50 ml10">
        <!-- 5-1 -->
        <div class="layout-view" @dblclick="setMiddleCoverComponent('FunctionRoom')">
          <FunctionRoom></FunctionRoom>
        </div>
        <!-- 5-1 -->
        <!-- 5-2 -->
        <div class="layout-view" @dblclick="setMiddleCoverComponent('ElderDiet')">
          <ElderDiet></ElderDiet>
        </div>
        <!-- 5-2 -->
        <!-- 5-3 -->
        <div class="layout-view" @dblclick="setMiddleCoverComponent('HappyStation')">
          <HappyStation></HappyStation>
        </div>
        <!-- 5-3 -->
      </div>
      <!-- 第五列 -->
    </div>
    <!-- 主窗体右侧第二屏 -->
  </div>
  <!-- 社区幸福院主窗体内容 -->
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import Top from "@/views/common/Top.vue";

import CommunityHappinessOverview from "@/components/com/CommunityHappinessOverview.vue";

import CommunityHappinessOperationRanking from "@/components/com/CommunityHappinessOperationRanking.vue";
import CommunityHappinessOperationRankingCommunityElderNumber from "@/components/com/CommunityHappinessOperationRankingCommunityElderNumber.vue";
import CommunityHappinessOperationRankingReleaseActivityNumber from "@/components/com/CommunityHappinessOperationRankingReleaseActivityNumber.vue";
import CommunityHappinessOperationRankingActivityParticipantsNumber from "@/components/com/CommunityHappinessOperationRankingActivityParticipantsNumber.vue";
import CommunityHappinessOperationRankingEmployeesNumber from "@/components/com/CommunityHappinessOperationRankingEmployeesNumber.vue";

import CommunityHappinessAnnualRanking from "@/components/com/CommunityHappinessAnnualRanking.vue";
import CommunityHappinessAnnualRankingTable from "@/components/com/CommunityHappinessAnnualRankingTable.vue";
import CommunityHappinessAnnualRankingChart from "@/components/com/CommunityHappinessAnnualRankingChart.vue";

import CommunityHappinessMap from "@/components/com/CommunityHappinessMap.vue";

import CommunityHappinessPictureIntroduction from "@/components/com/CommunityHappinessPictureIntroduction.vue";

import CommunityElder from "@/components/com/CommunityElder.vue";
import CommunityElderByAge from "@/components/com/CommunityElderByAge.vue";
import CommunityElderByGender from "@/components/com/CommunityElderByGender.vue";
import CommunityElderByAbility from "@/components/com/CommunityElderByAbility.vue";
import CommunityElderByInterval from "@/components/com/CommunityElderByInterval.vue";

import CommunityHappinessIntroduce from "@/components/com/CommunityHappinessIntroduce.vue";
import CommunityEmployeesIntroduce from "@/components/com/CommunityEmployeesIntroduce.vue";

import EmployeesQualification from "@/components/com/EmployeesQualification.vue";

import VolunteerParticipation from "@/components/com/VolunteerParticipation.vue";

import PreventiveTreatment from "@/components/com/PreventiveTreatment.vue";

import ElderExperiencing from "@/components/com/ElderExperiencing.vue";
import ElderHealth from "@/components/com/ElderHealth.vue";

import ChronicDiseaseTeam from "@/components/com/ChronicDiseaseTeam.vue";

import GovernanceConstructionSharing from "@/components/com/GovernanceConstructionSharing.vue";

import YearsRanking from "@/components/com/YearsRanking.vue";

import ActivityAnalysis from "@/components/com/ActivityAnalysis.vue";
import ActivityFrequencyGrowthTrend from "@/components/com/ActivityFrequencyGrowthTrend.vue";
import ActivityParticipatingElderGrowthTrend from "@/components/com/ActivityParticipatingElderGrowthTrend.vue";

import ActivityInformation from "@/components/com/ActivityInformation.vue";
import ActivityGroup from "@/components/com/ActivityGroup.vue";

import FunctionRoom from "@/components/com/FunctionRoom.vue";
import ElderDiet from "@/components/com/ElderDiet.vue";
import HappyStation from "@/components/com/HappyStation.vue";

import OverviewBase from "@/views/mixin/OverviewBase.vue";

export default {
  components: {
    Title,
    Nav,
    Top,

    CommunityHappinessOverview,

    CommunityHappinessOperationRanking,
    CommunityHappinessOperationRankingCommunityElderNumber,
    CommunityHappinessOperationRankingReleaseActivityNumber,
    CommunityHappinessOperationRankingActivityParticipantsNumber,
    CommunityHappinessOperationRankingEmployeesNumber,

    CommunityHappinessAnnualRanking,
    CommunityHappinessAnnualRankingTable,
    CommunityHappinessAnnualRankingChart,

    CommunityHappinessMap,

    CommunityHappinessPictureIntroduction,

    CommunityElder,
    CommunityElderByAge,
    CommunityElderByGender,
    CommunityElderByAbility,
    CommunityElderByInterval,

    CommunityHappinessIntroduce,
    CommunityEmployeesIntroduce,

    EmployeesQualification,

    VolunteerParticipation,

    PreventiveTreatment,

    ElderExperiencing,
    ElderHealth,

    ChronicDiseaseTeam,

    GovernanceConstructionSharing,
    YearsRanking,

    ActivityAnalysis,
    ActivityFrequencyGrowthTrend,
    ActivityParticipatingElderGrowthTrend,

    ActivityInformation,
    ActivityGroup,

    FunctionRoom,
    ElderDiet,
    HappyStation,
  },
  mixins: [OverviewBase],
};
</script>

<style lang="less" scoped>
.layout-main.happiness-com {
  .ndpbqk {
    height: 990px !important;
  }
  .lnpbpm {
    height: 540px !important;
    // 现场会修改2会影响原来的
    // height: 15.3333% !important;
  }
  .hdqkfx {
    height: 1605px !important;
    // 现场会修改2会影响原来的
    // height: 51% !important;
    .top {
      height: 520px;
      // 现场会修改2会影响原来的
      // height: 31% !important;
    }
    .bot {
      height: 1030px;
      // 现场会修改2会影响原来的
      // height: 69% !important;
    }
  }
}
</style>
