<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  mounted() {
    // this.option = {
    //   series: [
    //     {
    //       radius: ["50%", "80%"],
    //       type: "pie",
    //       label: {
    //         normal: {
    //           formatter: "{c|{c}}\n{hr|}\n{d|{d}%}",
    //           rich: {
    //             b: { align: "left", color: "#12EABE", fontSize: 20, padding: 4 },
    //             c: { align: "left", color: "#fff", fontSize: 20, padding: 4 },
    //             d: { align: "left", color: "#fff", fontSize: 20, padding: 4 },
    //             hr: { borderColor: "#12EABE", borderWidth: 2, height: 0, width: "100%" },
    //           },
    //         },
    //       },
    //     },
    //     {
    //       itemStyle: { normal: { color: "rgba(250,250,250,0.5)" } },
    //       label: { show: false },
    //       type: "pie",
    //       radius: ["76%", "80%"],
    //     },
    //   ],
    //   tooltip: { formatter: "{c}", textStyle: { textStyle: { fontSize: 30 } } },
    //   color: [
    //     {
    //       colorStops: [
    //         { color: "rgba(19, 116, 255, 1)", offset: 1 },
    //         { color: "rgba(86, 36, 255, 1)", offset: 0 },
    //       ],
    //       globalCoord: false,
    //       type: "linear",
    //       x: 0,
    //       x2: 0,
    //       y: 0,
    //       y2: 1,
    //     },
    //     {
    //       colorStops: [
    //         { color: "rgba(190, 227, 0, 1)", offset: 1 },
    //         { color: "rgba(32, 179, 0, 1)", offset: 0 },
    //       ],
    //       globalCoord: false,
    //       type: "linear",
    //       x: 0,
    //       x2: 0,
    //       y: 0,
    //       y2: 1,
    //     },
    //     {
    //       colorStops: [
    //         { color: "rgba(255, 132, 0, 1)", offset: 1 },
    //         { color: "rgba(255, 209, 44, 1)", offset: 0 },
    //       ],
    //       globalCoord: false,
    //       type: "linear",
    //       x: 0,
    //       x2: 0,
    //       y: 0,
    //       y2: 1,
    //     },
    //     {
    //       colorStops: [
    //         { color: "rgba(253, 81, 76, 1)", offset: 1 },
    //         { color: "rgba(253, 68, 168, 1)", offset: 0 },
    //       ],
    //       globalCoord: false,
    //       type: "linear",
    //       x: 0,
    //       x2: 0,
    //       y: 0,
    //       y2: 1,
    //     },
    //     {
    //       colorStops: [
    //         { color: "rgba(0, 78, 255, 1)", offset: 1 },
    //         { color: "rgba(45, 198, 248, 1)", offset: 0 },
    //       ],
    //       globalCoord: false,
    //       type: "linear",
    //       x: 0,
    //       x2: 0,
    //       y: 0,
    //       y2: 1,
    //     },
    //   ],
    //   legend: { show: false },
    // };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/nh/getElderNurseNumList`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.level, item.number]);
            });

          // 更新配置
          this.option = {
            series: [
              {
                type: "bar",
                name: "数量",
                barMaxWidth: "20%",
                showItemStyle: true,
                label: {
                  show: true,
                  position: "top",
                },
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "rgba(0, 97, 206, 1)" },
                      { offset: 0, color: "rgba(45, 198, 248, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
              },
            ],
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {
              axisLabel: { interval: 0 },
              axisLine: { lineStyle: { color: "white" } },
              type: "category",
            },
            yAxis: {
              axisLine: { lineStyle: { color: "white" } },
              boundaryGap: [0, 0.01],
              type: "value",
            },
            dataset: {
              source: [
                ["level", "number"],
                // ["0-1年", 1495],
                // ["10年以上", 2],
                // ["5-10年", 1],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
