<template>
  <fragment>
    <Select v-if="false"></Select>
    <Title title="年度评比情况排名前10家">
      <template v-slot:righter>
        <Select
          :default="current_year"
          :callBack="set_current_year"
          :dataArray="yearSelecter"
        ></Select>
      </template>
    </Title>
    <div class="layout-view withoutTitle pb0 fdr">
      <div class="layout-view wd50 mr10">
        <CommunityHappinessAnnualRankingTable
          :set_current_xfy="set_current_xfy"
          :current_year="current_year"
          ref="CommunityHappinessAnnualRankingTable"
        ></CommunityHappinessAnnualRankingTable>
      </div>
      <div class="layout-view wd50 ml10">
        <CommunityHappinessAnnualRankingChart
          :current_fly="current_fly"
          :current_year="current_year"
          ref="CommunityHappinessAnnualRankingChart"
        ></CommunityHappinessAnnualRankingChart>
      </div>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Select from "@/components/common/Select.vue";
import CommunityHappinessAnnualRankingTable from "@/components/com/CommunityHappinessAnnualRankingTable.vue";
import CommunityHappinessAnnualRankingChart from "@/components/com/CommunityHappinessAnnualRankingChart.vue";
export default {
  components: {
    Title,
    Select,
    CommunityHappinessAnnualRankingTable,
    CommunityHappinessAnnualRankingChart,
  },
  data() {
    return {
      current_fly: {},
      current_year: new Date().getFullYear(),
    };
  },
  computed: {
    yearSelecter() {
      return this.$store.state.lastFourYears;
    },
  },
  methods: {
    // 设置当前幸福院
    set_current_xfy(current_fly) {
      this.current_fly = current_fly;
      this.$nextTick(() => {
        this.$refs.CommunityHappinessAnnualRankingChart.get_component_data();
      });
    },
    // 设置当前年份
    set_current_year(current_year) {
      this.current_year = current_year;
      this.$nextTick(() => {
        this.$refs.CommunityHappinessAnnualRankingTable.get_component_data();
        this.$refs.CommunityHappinessAnnualRankingChart.get_component_data();
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
