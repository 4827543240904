<template>
  <fragment>
    <Title title="居家服务类型分析">
      <template v-slot:righter>
        <Select :callBack="set_current_year" :dataArray="yearSelecter"></Select>
      </template>
    </Title>
    <Nav :dataArray="components" :callBack="callBack"></Nav>
    <div class="layout-view withoutTitleAndNav pb0">
      <component
        :current_year="current_year"
        :is="currentComponent"
        ref="child_component"
      ></component>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Select from "@/components/common/Select.vue";
import Nav from "@/components/common/Nav.vue";
import LayoutBase from "@/views/mixin/LayoutBase.vue";

import HomeServiceTypeServiceElderNumber from "@/components/home/HomeServiceTypeServiceElderNumber.vue";
import HomeServiceTypeServiceOrderNumber from "@/components/home/HomeServiceTypeServiceOrderNumber.vue";
import HomeServiceTypeEmployeesNumber from "@/components/home/HomeServiceTypeEmployeesNumber.vue";
import HomeServiceTypeServiceTotalFee from "@/components/home/HomeServiceTypeServiceTotalFee.vue";
export default {
  components: {
    Title,
    Select,
    Nav,
    HomeServiceTypeServiceElderNumber,
    HomeServiceTypeServiceOrderNumber,
    HomeServiceTypeEmployeesNumber,
    HomeServiceTypeServiceTotalFee,
  },
  mixins: [LayoutBase],
  data() {
    return {
      current_year: new Date().getFullYear(),
      components: [
        {
          key: "HomeServiceTypeServiceElderNumber",
          title: "年末服务长者总数",
        },
        {
          key: "HomeServiceTypeServiceOrderNumber",
          title: "年度服务订单总数",
        },
        {
          key: "HomeServiceTypeEmployeesNumber",
          title: "年末从业人数总数",
        },
        {
          key: "HomeServiceTypeServiceTotalFee",
          title: "年度服务费用总数",
        },
      ],
    };
  },
  computed: {
    yearSelecter() {
      return this.$store.state.lastFourYears;
    },
  },
  methods: {
    // 设置当前年份
    set_current_year(current_year) {
      this.current_year = current_year;
      this.$nextTick(() => {
        this.$refs.child_component.get_component_data();
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
