<template>
  <fragment>
    <Title title="服务统计"></Title>
    <div class="layout-view withoutTitle pb0">
      <div class="components-layout fuwutongji">
        <div class="datas">
          <div class="main">
            <div class="top">
              <div class="section ljfwdd">累计服务订单<br />{{ data.orderNum }}单</div>
              <div class="section ljfwzz">累计服务长者<br />{{ data.elderNum }}人</div>
              <div class="section myl">满意率<br />{{ data.satisfaction_Rate }}</div>
            </div>
            <div class="bot">
              <div class="section ljfwfy">
                <p>累计服务费用</p>
                <p>{{ data.amount }} 元</p>
              </div>
            </div>
          </div>
        </div>
        <div class="charts">
          <EchartsLayout :option="option"></EchartsLayout>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import Title from "@/components/common/Title.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    Title,
    EchartsLayout,
  },
  data() {
    return {
      data: {
        orderNum: 0,
        elderNum: 0,
        satisfaction_Rate: 0,
        amount: 0,
      },
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getHomeAllView`,
        success: (response) => {
          this.data = {
            orderNum: response.data.data.orderNum || 0,
            elderNum: response.data.data.elderNum || 0,
            satisfaction_Rate: response.data.data.satisfaction_Rate || "%",
            amount: response.data.data.amount || 0,
          };
        },
      });
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/home/getAmountByTypeAndMonth`,
        success: (response) => {
          let monthArr = [];
          for (let i = 1; i <= 12; i++) {
            monthArr.push([`${i}`, 0, 0, 0]);
          }
          // 自费
          response.data &&
            response.data.data &&
            response.data.data.ownList &&
            response.data.data.ownList.length &&
            response.data.data.ownList.map &&
            response.data.data.ownList.map((item) => {
              if (!isNaN(item.month) && item.month > 0 && item.month < 13) {
                monthArr[item.month - 1][3] = item.amount || 0;
              }
            });
          // 补贴
          response.data &&
            response.data.data &&
            response.data.data.subidyList &&
            response.data.data.subidyList.length &&
            response.data.data.subidyList.map &&
            response.data.data.subidyList.map((item) => {
              if (!isNaN(item.month) && item.month > 0 && item.month < 13) {
                monthArr[item.month - 1][1] = item.amount || 0;
              }
            });
          this.option = {
            grid: { containLabel: true },
            // grid: { left: 150 },
            series: [
              {
                type: "bar",
                stack: "背景",
                name: "补贴合计",
                itemStyle: { color: "RGBA(252, 83, 76, 1)", borderRadius: 50 },
                barWidth: 40,
                z: 5,
              },
              {
                type: "bar",
                name: "慈善基金合计",
                stack: "背景",
                itemStyle: { color: "RGBA(156, 218, 1, 1)" },
                barWidth: 40,
                z: 4,
              },
              {
                type: "bar",
                stack: "背景",
                name: "自费合计",
                itemStyle: {
                  color: "RGBA(255, 177, 27, 1)",
                  borderRadius: [50, 50, 0, 0],
                },
                barWidth: 40,
                z: 3,
              },
            ],
            title: {
              text: "月服务费用 单位：元",
              textStyle: {
                fontSize: 30,
              },
            },
            tooltip: { textStyle: { fontSize: 30 }, trigger: "axis" },
            xAxis: { type: "category" },
            yAxis: {},
            legend: {
              right: "4%",
              show: true,
              // height: "40%",
              // width: "100%",
              // itemWidth: 50,
              // itemHeight: 50,
            },
            dataset: {
              source: [
                ["月份", "补贴合计", "慈善基金合计", "自费合计"],
                // ["1", 0, 0, 0],
                // ["2", 0, 0, 0],
                // ["3", 0, 0, 0],
                // ["4", 0, 0, 0],
                // ["5", 0, 0, 0],
                // ["6", 0, 0, 0],
                // ["7", 500, 0, 0],
                // ["8", 0, 0, 0],
                // ["9", 0, 0, 0],
                // ["10", 0, 0, 0],
                // ["11", 0, 0, 0],
                // ["12", 0, 0, 0],
                ...monthArr.map((item) => {
                  return [`${item[0]}月`, item[1], item[2], item[3]];
                }),
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.fuwutongji {
  display: flex;
  width: 100%;
  height: 100%;
  .datas {
    width: 40%;
    height: 100%;
    .main {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      .top {
        width: 80%;
        height: 0;
        padding-top: calc(~"93.3121% * 0.8");
        position: relative;
        background: url(/images/overview_backgroundImg1.png) no-repeat center;
        background-size: contain;
        margin-bottom: 20px;
      }
      .bot {
        width: 80%;
        height: 0;
        padding-top: calc(~"46.7532% * 0.8");
        position: relative;
        background: url(/images/server_background.png) no-repeat center;
        background-size: contain;
      }
      .section {
        position: absolute;
        color: #fff;
        font-size: 20%;
        z-index: 2;
        width: 45.3%;
        height: 48.5%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .section.ljfwdd {
        left: 27.3%;
        top: 0.3%;
      }
      .section.ljfwzz {
        bottom: 0.3%;
        left: 0.3%;
      }
      .section.myl {
        bottom: 0.3%;
        right: 0.3%;
      }
      .section.ljfwfy {
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 2% 0 0;
        left: 0;
        top: 0;
        flex-direction: column;
        p {
          height: 49%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .charts {
    height: 100%;
    width: 60%;
    flex-grow: 1;
    .echarts-layout {
      padding: 0.1rem 0;
      width: 100%;
    }
  }
}
</style>
