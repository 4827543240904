<template>
  <fragment>
    <Title title="居家服务全流程"></Title>
    <div class="layout-view withoutTitle jujiafuwuquanliucheng">
      <div class="components-layout">
        <img src="/images/service_process.png" />
      </div>
    </div>
  </fragment>
</template>

<script>
import Title from "@/components/common/Title.vue";
export default {
  components: { Title },
  data() {
    return {
      option: null,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.jujiafuwuquanliucheng {
  padding-bottom: 0 !important;
  margin-top: 30px !important;
  .components-layout {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    img {
      .setImg(contain);
    }
  }
}
</style>
