<template>
  <!-- 南海区总览主窗体内容 -->
  <div class="layout-main">
    <!-- 主窗体左侧 -->
    <div class="layout-main-left">
      <div class="layout-view" @dblclick="setMiddleCoverComponent('NanHaiOverview')">
        <NanHaiOverview></NanHaiOverview>
      </div>
      <div
        class="layout-view"
        @dblclick="setMiddleCoverComponent('EachTownStreetConstruction')"
      >
        <EachTownStreetConstruction></EachTownStreetConstruction>
      </div>
      <div
        class="layout-view"
        @dblclick="setMiddleCoverComponent('EachTownStreetConstructionBottom')"
      >
        <EachTownStreetConstructionBottom></EachTownStreetConstructionBottom>
      </div>
    </div>
    <!-- 主窗体左侧 -->

    <!-- 主窗体中部 -->
    <div class="layout-main-middle">
      <NanHaiMap></NanHaiMap>
      <div class="layout-view abs" v-if="middleCoverComponent">
        <span class="close" @click="closeCoverComponent"></span>
        <component :is="middleCoverComponent"></component>
      </div>
    </div>
    <!-- 主窗体中部 -->

    <!-- 主窗体右侧第一屏 -->
    <div v-show="currentRightPage == 1" class="layout-main-right">
      <!-- 第四列 -->
      <div class="layout-view bd wd50 pd20 br20 mr10">
        <!-- 4-1 -->
        <div class="layout-view">
          <Title title="平台管理长者综合情况分析"></Title>
          <div
            class="layout-view withoutTitle"
            @dblclick="setMiddleCoverComponent('PlatformElderIncreaseTendency')"
          >
            <PlatformElderIncreaseTendency></PlatformElderIncreaseTendency>
          </div>
        </div>
        <!-- 4-1 -->
        <!-- 4-2 -->
        <div
          class="layout-view"
          @dblclick="setMiddleCoverComponent('PlatformElderByAge')"
        >
          <PlatformElderByAge></PlatformElderByAge>
        </div>
        <!-- 4-2 -->
        <!-- 4-3 -->
        <div
          class="layout-view"
          @dblclick="setMiddleCoverComponent('PlatformElderByAssessment')"
        >
          <PlatformElderByAssessment></PlatformElderByAssessment>
        </div>
        <!-- 4-3 -->
      </div>
      <!-- 第四列 -->
      <!-- 第五列 -->
      <div class="layout-view bd wd50 pd20 br20 ml10">
        <!-- 5-1 -->
        <div class="layout-view">
          <Title title="从业人员综合情况分析"></Title>
          <div
            class="layout-view withoutTitle"
            @dblclick="setMiddleCoverComponent('EmployeesIncreaseTendency')"
          >
            <EmployeesIncreaseTendency></EmployeesIncreaseTendency>
          </div>
        </div>
        <!-- 5-1 -->
        <!-- 5-2 -->
        <div class="layout-view">
          <div class="layout-view fdr">
            <div class="layout-view wd50 mr10">
              <div
                class="layout-view"
                @dblclick="setMiddleCoverComponent('EmployeesByAge')"
              >
                <EmployeesByAge></EmployeesByAge>
              </div>
            </div>
            <div class="layout-view wd50 ml10">
              <div
                class="layout-view"
                @dblclick="setMiddleCoverComponent('EmployeesByGender')"
              >
                <EmployeesByGender></EmployeesByGender>
              </div>
            </div>
          </div>
        </div>
        <!-- 5-2 -->
        <!-- 5-3 -->
        <div class="layout-view">
          <div class="layout-view fdr">
            <div class="layout-view wd50 mr10">
              <div
                class="layout-view"
                @dblclick="setMiddleCoverComponent('EmployeesByWorkingYears')"
              >
                <EmployeesByWorkingYears></EmployeesByWorkingYears>
              </div>
            </div>
            <div class="layout-view wd50 ml10">
              <div
                class="layout-view"
                @dblclick="setMiddleCoverComponent('EmployeesByEducation')"
              >
                <EmployeesByEducation></EmployeesByEducation>
              </div>
            </div>
          </div>
        </div>
        <!-- 5-3 -->
      </div>
      <!-- 第五列 -->
    </div>
    <!-- 主窗体右侧第一屏 -->

    <!-- 主窗体右侧第二屏 -->
    <div v-if="currentRightPage == 2" class="layout-main-right">
      <NanHaiPensionDevelopmentProcess></NanHaiPensionDevelopmentProcess>
    </div>
    <!-- 主窗体右侧第二屏 -->

    <!-- 主窗体右侧第三屏 -->
    <div v-if="currentRightPage == 3" class="layout-main-right">
      <NanHaiPensionServiceSystem></NanHaiPensionServiceSystem>
    </div>
    <!-- 主窗体右侧第三屏 -->

    <!-- 主窗体右侧第四屏 -->
    <div v-if="currentRightPage == 4" class="layout-main-right">
      <NanHaiGovernmentServiceDataManagement></NanHaiGovernmentServiceDataManagement>
    </div>
    <!-- 主窗体右侧第四屏 -->

    <!-- 主窗体右侧第四屏 -->
    <div v-if="currentRightPage == 5" class="layout-main-right">
      <NanHaiXiaoChengXu></NanHaiXiaoChengXu>
    </div>
    <!-- 主窗体右侧第四屏 -->

    <!-- 主窗体右侧第四屏 -->
    <div v-if="currentRightPage == 6" class="layout-main-right">
      <NanHaiShiDianGongZuoTongZhi></NanHaiShiDianGongZuoTongZhi>
    </div>
    <!-- 主窗体右侧第四屏 -->

    <!-- 主窗体右侧第四屏 -->
    <div v-if="currentRightPage == 7" class="layout-main-right">
      <NanHaiBiaoZhunHuaJianShe></NanHaiBiaoZhunHuaJianShe>
    </div>
    <!-- 主窗体右侧第四屏 -->
  </div>
</template>

<script>
import Title from "@/components/common/Title.vue";
import Nav from "@/components/common/Nav.vue";
import NanHaiOverview from "@/components/nh/NanHaiOverview.vue";

import EachTownStreetConstruction from "@/components/nh/EachTownStreetConstruction.vue";
import EachTownStreetConstructionByWelfareCentreNumber from "@/components/nh/EachTownStreetConstructionByWelfareCentreNumber.vue";
import EachTownStreetConstructionByLocalElderNumber from "@/components/nh/EachTownStreetConstructionByLocalElderNumber.vue";

import EachTownStreetConstructionBottom from "@/components/nh/EachTownStreetConstructionBottom.vue";
import InstitutionalEndowment from "@/components/nh/InstitutionalEndowment.vue";
import CommunityEndowment from "@/components/nh/CommunityEndowment.vue";
import HomeEndowment from "@/components/nh/HomeEndowment.vue";

import NanHaiMap from "@/components/nh/NanHaiMap.vue";

import PlatformElderIncreaseTendency from "@/components/nh/PlatformElderIncreaseTendency.vue";
import PlatformElderByAge from "@/components/nh/PlatformElderByAge.vue";
import PlatformElderByAssessment from "@/components/nh/PlatformElderByAssessment.vue";
import PlatformElderByOverall from "@/components/nh/PlatformElderByOverall.vue";
import PlatformElderByAssessmentByAge from "@/components/nh/PlatformElderByAssessmentByAge.vue";
import PlatformElderByAssessmentAbilityAssessment from "@/components/nh/PlatformElderByAssessmentAbilityAssessment.vue";

import EmployeesIncreaseTendency from "@/components/nh/EmployeesIncreaseTendency.vue";
import EmployeesByAge from "@/components/nh/EmployeesByAge.vue";
import EmployeesByGender from "@/components/nh/EmployeesByGender.vue";
import EmployeesByWorkingYears from "@/components/nh/EmployeesByWorkingYears.vue";
import EmployeesByEducation from "@/components/nh/EmployeesByEducation.vue";

import NanHaiPensionDevelopmentProcess from "@/components/nh/NanHaiPensionDevelopmentProcess.vue";
import NanHaiPensionServiceSystem from "@/components/nh/NanHaiPensionServiceSystem.vue";

import NanHaiGovernmentServiceDataManagement from "@/components/nh/NanHaiGovernmentServiceDataManagement.vue";
import NanHaiXiaoChengXu from "@/components/nh/NanHaiXiaoChengXu.vue";
import NanHaiShiDianGongZuoTongZhi from "@/components/nh/NanHaiShiDianGongZuoTongZhi.vue";
import NanHaiBiaoZhunHuaJianShe from "@/components/nh/NanHaiBiaoZhunHuaJianShe.vue";

import OverviewBase from "@/views/mixin/OverviewBase.vue";

export default {
  components: {
    Title,
    Nav,
    NanHaiOverview,

    EachTownStreetConstruction,
    EachTownStreetConstructionByWelfareCentreNumber,
    EachTownStreetConstructionByLocalElderNumber,

    EachTownStreetConstructionBottom,
    InstitutionalEndowment,
    CommunityEndowment,
    HomeEndowment,

    NanHaiMap,

    PlatformElderIncreaseTendency,
    PlatformElderByAge,
    PlatformElderByAssessment,
    PlatformElderByOverall,
    PlatformElderByAssessmentByAge,
    PlatformElderByAssessmentAbilityAssessment,

    EmployeesIncreaseTendency,
    EmployeesByAge,
    EmployeesByGender,
    EmployeesByWorkingYears,
    EmployeesByEducation,

    NanHaiPensionDevelopmentProcess,
    NanHaiPensionServiceSystem,
    NanHaiGovernmentServiceDataManagement,
    NanHaiXiaoChengXu,
    NanHaiShiDianGongZuoTongZhi,
    NanHaiBiaoZhunHuaJianShe,
  },
  mixins: [OverviewBase],
};
</script>

<style lang="less" scoped></style>
