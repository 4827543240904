<template>
  <div class="components-layout jigoujieshao">
    <div class="main">
      <div class="organization-info">
        <!-- <p>{{ data.orgName }}</p> -->
        <p v-if="data.starLevel">建设标准：{{ orgStarLevel }}</p>
        <p v-if="data.establishedDate">成立时间：{{ data.establishedDate }}</p>
        <p v-if="data.orgNature">公办民办：{{ data.orgNature }}</p>
        <p v-if="data.orgPhone">联系电话：{{ data.orgPhone }}</p>
        <p v-if="data.orgAddress">详细地址：{{ data.orgAddress }}</p>
      </div>
      <div class="organization-description">
        <div :id="`events-layout-${scrollId}`">
          <div
            :id="scrollId"
            class="description html-css"
            v-html="data.orgIntroduce"
          ></div>
        </div>
      </div>
    </div>
    <EmptyData v-if="isEmpty" />
  </div>
</template>

<script>
import { createUniqueKey } from "@/utils/index.js";
import EmptyData from "@/components/common/EmptyData.vue";
import ComponentBase from "@/components/mixin/ComponentBase.vue";

export default {
  components: {
    EmptyData,
  },
  mixins: [ComponentBase],
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      scrollId: createUniqueKey(12),
      autoScrollObject: null,
    };
  },
  computed: {
    isEmpty() {
      return Object.keys(this.data).length == 0;
    },
    orgStarLevel() {
      const star_level_list = { 1: "一", 2: "二", 3: "三", 4: "四", 5: "五" };
      return this.data.starLevel && star_level_list[this.data.starLevel]
        ? `${star_level_list[this.data.starLevel]}星级`
        : this.data.starLevel;
    },
  },
};
</script>

<style lang="less" scoped>
.jigoujieshao {
  flex-grow: 1;
  .main {
    display: flex;
    flex-direction: column;
    border-radius: @mainBorderRadius;
    padding: 0.1rem 0.2rem;
    background: @mainSessionBackGroundColor;
    overflow: hidden;
    width: 100%;
    height: 100%;
    .organization-info {
      padding-bottom: 0.1rem;
      p {
        font-weight: 700;
        font-size: 0.2rem;
        color: #fff;
        line-height: @mainLineHeight;
        // &:first-child {
        //   font-size: @fontSizeLarge;
        // }
      }
    }
    .organization-description {
      flex-grow: 1;
      height: 100%;
      overflow: hidden;
      .description {
        overflow-y: scroll;
        // pointer-events: none;
        height: 100%;
        font-size: 0.2rem;
      }
    }
  }
}
</style>
