<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  props: {
    current_year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/com/getStaffNumTop?year=${this.current_year}`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push({ 从业人员总数: item.staffNum, 机构名: item.orgName });
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            series: [
              {
                type: "bar",
                encode: { x: "从业人员总数", y: "机构名" },
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "#FFCE2B" },
                      { offset: 0, color: "#FF8500" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 20,
                },
                barMaxWidth: "20%",
              },
            ],
            title: { text: "单位：人" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {},
            yAxis: { type: "category", inverse: true },
            dataset: {
              source: [
                // { 从业人员总数: 798, 机构名: "翠颐社区幸福院" },
                // { 从业人员总数: 766, 机构名: "桂南社区幸福院" },
                // { 从业人员总数: 721, 机构名: "联星社区幸福院" },
                // { 从业人员总数: 648, 机构名: "流潮社区幸福院" },
                // { 从业人员总数: 647, 机构名: "永安社区幸福院" },
                // { 从业人员总数: 606, 机构名: "沙口社区幸福院" },
                // { 从业人员总数: 508, 机构名: "狮南村社区幸福院" },
                // { 从业人员总数: 481, 机构名: "大步社区幸福院" },
                // { 从业人员总数: 411, 机构名: "联滘社区幸福院" },
                // { 从业人员总数: 408, 机构名: "花苑社区幸福院" },
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
