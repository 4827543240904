<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/nh/getBedNumByTown`,
        success: (response) => {
          let arr = [],
            temp = {};
          response.data &&
            response.data.data &&
            response.data.data.bed &&
            response.data.data.bed.map &&
            response.data.data.bed.map((item) => {
              if (temp[item.divisionName] === undefined) {
                temp[item.divisionName] = arr.length;
                arr.push([item.divisionName, 0, 0]);
              }
              arr[temp[item.divisionName]][1] = item.allBedCount;
            });
          response.data &&
            response.data.data &&
            response.data.data.elder &&
            response.data.data.elder.map &&
            response.data.data.elder.map((item) => {
              if (temp[item.divisionName] === undefined) {
                temp[item.divisionName] = arr.length;
                arr.push([item.divisionName, 0, 0]);
              }
              arr[temp[item.divisionName]][2] = item.residentNum;
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },

            series: [
              {
                name: "床位数",
                type: "bar",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 0, color: "RGBA(191, 226, 9, 1)" },
                      { offset: 1, color: "RGBA(42, 181, 0, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
              {
                name: "入住长者数",
                type: "bar",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 1, color: "RGBA(255, 137, 4, 1)" },
                      { offset: 0, color: "RGBA(255, 208, 42, 1)" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                markPoint: {
                  data: [
                    { type: "max", name: "最大值" },
                    { type: "min", name: "最小值" },
                  ],
                },
              },
            ],
            title: { text: "单位：人" },
            tooltip: {},
            xAxis: { type: "category" },
            yAxis: {},
            legend: { show: true },
            dataset: {
              source: [
                ["镇街", "床位数", "入住长者数"],
                // ["桂城街道", 300, 243],
                // ["九江镇", 310, 139],
                // ["西樵镇", 454, 312],
                // ["丹灶镇", 71, 55],
                // ["狮山镇", 2079, 1641],
                // ["大沥镇", 2216, 1245],
                // ["里水镇", 259, 148],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
