<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  props: {
    current_year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "post",
        url: `/home/getOperationTopByOrderWithType`,
        param: {
          year: this.current_year,
        },
        success: (response) => {
          let typeArr = [],
            cishanArr = [],
            ownArr = [],
            subsidyArr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              typeArr.push(item.serviceTypeChildren);
              cishanArr.push(0);
              ownArr.push(item.ownNum || 0);
              subsidyArr.push(item.subsidyNum || 0);
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            // grid: { left: 150 },
            series: [
              {
                type: "bar",
                stack: "区域",
                seriesLayoutBy: "row",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{ offset: 1, color: "#16B9FF" }],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                barWidth: 80,
              },
              {
                type: "bar",
                stack: "区域",
                seriesLayoutBy: "row",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{ offset: 1, color: "#FD415F" }],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                barWidth: 80,
              },
              {
                type: "bar",
                stack: "区域",
                seriesLayoutBy: "row",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{ offset: 1, color: "#A5E300" }],
                    globalCoord: false,
                  },
                  borderRadius: 50,
                },
                barWidth: 80,
              },
            ],
            title: { text: "单位：单" },
            tooltip: { trigger: "axis" },
            xAxis: { type: "category" },
            yAxis: {},
            legend: {
              show: true,
              height: "40%",
              width: "100%",
              itemWidth: 50,
              itemHeight: 50,
            },
            dataset: {
              source: [
                [
                  "name",
                  // "养生保健",
                  // "陪同就医",
                  // "日托服务",
                  // "普通谈心",
                  // "陪同购物",
                  // "居家清洁",
                  ...typeArr,
                ],
                // ["按慈善受捐情况", 0, 0, 0, 0, 0, 0, 0],
                // ["按自费情况", 0, 0, 0, 0, 0, 0, 0],
                // ["按补贴情况", 5960, 693, 5813, 5837, 0, 6677],
                ["按慈善受捐情况", ...cishanArr],
                ["按自费情况", ...ownArr],
                ["按补贴情况", ...subsidyArr],
              ],
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
