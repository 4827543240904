<template>
  <EchartsLayout :option="option"></EchartsLayout>
</template>

<script>
import { getCacheOrRequest } from "@/utils/index.js";
import ComponentBase from "@/components/mixin/ComponentBase.vue";
import EchartsLayout from "@/components/common/EchartsLayout.vue";

export default {
  mixins: [ComponentBase],
  props: {
    current_year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  components: {
    EchartsLayout,
  },
  data() {
    return {
      option: null,
    };
  },
  methods: {
    get_component_data() {
      getCacheOrRequest.call(this, {
        method: "get",
        url: `/com/getElderTop?year=${this.current_year}`,
        success: (response) => {
          let arr = [];
          response.data &&
            response.data.data &&
            response.data.data.map &&
            response.data.data.map((item) => {
              arr.push([item.orgName, item.elderNum || 0]);
            });

          // 更新配置
          this.option = {
            grid: { containLabel: true },
            series: [
              {
                type: "bar",
                showItemStyle: true,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 1, color: "#FFCE2B" },
                      { offset: 0, color: "#FF8500" },
                    ],
                    globalCoord: false,
                  },
                  borderRadius: 20,
                },
                barMaxWidth: "20%",
              },
            ],
            title: { text: "单位：人" },
            tooltip: { textStyle: { fontSize: 30 } },
            xAxis: {},
            yAxis: { type: "category", inverse: true },
            dataset: {
              source: [
                ["name", "社区长者总数"],
                // ["儒林社区幸福院", 2715],
                // ["新兴社区幸福院", 2661],
                // ["直街社区幸福院", 2358],
                // ["驿园社区幸福院", 2170],
                // ["石石肯社区幸福院", 2079],
                // ["夏西社区幸福院", 1999],
                // ["太平社区幸福院", 1939],
                // ["平地社区幸福院", 1904],
                // ["雅瑶社区幸福院", 1901],
                // ["横江社区幸福院", 1881],
                ...arr,
              ],
            },
          };
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
